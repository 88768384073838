@charset "UTF-8";
/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #FFF;
    --gray: #575A7B;
    --gray-dark: #342E6C;
    --primary: #6658EA;
    --secondary: #575A7B;
    --success: #2CD4A2;
    --info: #17a2b8;
    --warning: #F5C070;
    --danger: #F9655B;
    --light: #F3F8FF;
    --dark: #1B1642;
    --primary-dark: #564CAF;
    --primary-light: #687DED;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "DM Sans", sans-serif;
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

*,
::after,
::before {
    box-sizing: border-box
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(27, 22, 66, 0)
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block
}

body {
    margin: 0;
    font-family: "DM Sans", sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.6;
    color: #1b1642;
    text-align: left;
    background-color: #fff
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem
}

p {
    margin-top: 0;
    margin-bottom: 2rem
}

abbr[data-original-title],
abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 500
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b,
strong {
    font-weight: 700
}

small {
    font-size: 80%
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: #6658ea;
    text-decoration: none;
    background-color: transparent
}

a:hover {
    color: #5546e8;
    text-decoration: none
}

a:not([href]) {
    color: inherit;
    text-decoration: none
}

a:not([href]):hover {
    color: inherit;
    text-decoration: none
}

code,
kbd,
pre,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar
}

figure {
    margin: 0 0 1rem
}

img {
    vertical-align: middle;
    border-style: none
}

svg {
    overflow: hidden;
    vertical-align: middle
}

table {
    border-collapse: collapse
}

caption {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #575a7b;
    text-align: left;
    caption-side: bottom
}

th {
    text-align: inherit
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button {
    border-radius: 0
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

[role=button] {
    cursor: pointer
}

select {
    word-wrap: normal
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

textarea {
    overflow: auto;
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal
}

progress {
    vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

summary {
    display: list-item;
    cursor: pointer
}

template {
    display: none
}

[hidden] {
    display: none !important
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: .5rem;
    font-family: "DM Serif Display", serif;
    font-weight: 500;
    line-height: 1.2
}

.h1,
h1 {
    font-size: 2.25rem
}

.h2,
h2 {
    font-size: 1.75rem
}

.h3,
h3 {
    font-size: 1.25rem
}

.h4,
h4 {
    font-size: 1.125rem
}

.h5,
h5 {
    font-size: 1rem
}

.h6,
h6 {
    font-size: .875rem
}

.lead {
    font-size: 1.375rem;
    font-weight: 400
}

.display-1 {
    font-size: 5.125rem;
    font-weight: 500;
    line-height: 1.08
}

.display-2 {
    font-size: 4.5rem;
    font-weight: 500;
    line-height: 1.08
}

.display-3 {
    font-size: 4rem;
    font-weight: 500;
    line-height: 1.08
}

.display-4 {
    font-size: 3rem;
    font-weight: 500;
    line-height: 1.08
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid #eaf2fe
}

.small,
small {
    font-size: 88%;
    font-weight: 400
}

.mark,
mark {
    padding: .2em;
    background-color: #fcf8e3
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: 90%;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.40625rem
}

.blockquote-footer {
    display: block;
    font-size: 88%;
    color: #575a7b
}

.blockquote-footer::before {
    content: "\2014\00A0"
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dce0f3;
    border-radius: 1rem;
    box-shadow: 0 1px 2px rgba(27, 22, 66, .075);
    max-width: 100%;
    height: auto
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: 90%;
    color: #575a7b
}

code {
    font-size: 87.5%;
    color: #e83e8c;
    word-wrap: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: .2rem .4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #2b265a;
    border-radius: .5rem;
    box-shadow: inset 0 -.1rem 0 rgba(27, 22, 66, .25)
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 500;
    box-shadow: none
}

pre {
    display: block;
    font-size: 87.5%;
    color: #2b265a
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll
}

.container {
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width:576px) {
    .container {
        max-width: 540px
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px
    }
}

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width:576px) {

    .container,
    .container-sm {
        max-width: 540px
    }
}

@media (min-width:768px) {

    .container,
    .container-md,
    .container-sm {
        max-width: 720px
    }
}

@media (min-width:992px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 960px
    }
}

@media (min-width:1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1140px
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -16px;
    margin-left: -16px
}

.no-gutters {
    margin-right: 0;
    margin-left: 0
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 16px;
    padding-left: 16px
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%
}

.row-cols-1>* {
    flex: 0 0 100%;
    max-width: 100%
}

.row-cols-2>* {
    flex: 0 0 50%;
    max-width: 50%
}

.row-cols-3>* {
    flex: 0 0 33.33333%;
    max-width: 33.33333%
}

.row-cols-4>* {
    flex: 0 0 25%;
    max-width: 25%
}

.row-cols-5>* {
    flex: 0 0 20%;
    max-width: 20%
}

.row-cols-6>* {
    flex: 0 0 16.66667%;
    max-width: 16.66667%
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
}

.col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%
}

.col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%
}

.col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%
}

.col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%
}

.col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%
}

.order-first {
    order: -1
}

.order-last {
    order: 13
}

.order-0 {
    order: 0
}

.order-1 {
    order: 1
}

.order-2 {
    order: 2
}

.order-3 {
    order: 3
}

.order-4 {
    order: 4
}

.order-5 {
    order: 5
}

.order-6 {
    order: 6
}

.order-7 {
    order: 7
}

.order-8 {
    order: 8
}

.order-9 {
    order: 9
}

.order-10 {
    order: 10
}

.order-11 {
    order: 11
}

.order-12 {
    order: 12
}

.offset-1 {
    margin-left: 8.33333%
}

.offset-2 {
    margin-left: 16.66667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333%
}

.offset-5 {
    margin-left: 41.66667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333%
}

.offset-8 {
    margin-left: 66.66667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333%
}

.offset-11 {
    margin-left: 91.66667%
}

@media (min-width:576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        min-width: 0;
        max-width: 100%
    }

    .row-cols-sm-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-sm-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-sm-3>* {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-sm-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-sm-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-sm-6>* {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-sm-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-sm-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-sm-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-sm-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-sm-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-sm-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-sm-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-sm-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-sm-first {
        order: -1
    }

    .order-sm-last {
        order: 13
    }

    .order-sm-0 {
        order: 0
    }

    .order-sm-1 {
        order: 1
    }

    .order-sm-2 {
        order: 2
    }

    .order-sm-3 {
        order: 3
    }

    .order-sm-4 {
        order: 4
    }

    .order-sm-5 {
        order: 5
    }

    .order-sm-6 {
        order: 6
    }

    .order-sm-7 {
        order: 7
    }

    .order-sm-8 {
        order: 8
    }

    .order-sm-9 {
        order: 9
    }

    .order-sm-10 {
        order: 10
    }

    .order-sm-11 {
        order: 11
    }

    .order-sm-12 {
        order: 12
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333%
    }

    .offset-sm-2 {
        margin-left: 16.66667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333%
    }

    .offset-sm-5 {
        margin-left: 41.66667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333%
    }

    .offset-sm-8 {
        margin-left: 66.66667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333%
    }

    .offset-sm-11 {
        margin-left: 91.66667%
    }
}

@media (min-width:768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        min-width: 0;
        max-width: 100%
    }

    .row-cols-md-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-md-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-md-3>* {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-md-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-md-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-md-6>* {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-md-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-md-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-md-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-md-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-md-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-md-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-md-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-md-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-md-first {
        order: -1
    }

    .order-md-last {
        order: 13
    }

    .order-md-0 {
        order: 0
    }

    .order-md-1 {
        order: 1
    }

    .order-md-2 {
        order: 2
    }

    .order-md-3 {
        order: 3
    }

    .order-md-4 {
        order: 4
    }

    .order-md-5 {
        order: 5
    }

    .order-md-6 {
        order: 6
    }

    .order-md-7 {
        order: 7
    }

    .order-md-8 {
        order: 8
    }

    .order-md-9 {
        order: 9
    }

    .order-md-10 {
        order: 10
    }

    .order-md-11 {
        order: 11
    }

    .order-md-12 {
        order: 12
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333%
    }

    .offset-md-2 {
        margin-left: 16.66667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333%
    }

    .offset-md-5 {
        margin-left: 41.66667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333%
    }

    .offset-md-8 {
        margin-left: 66.66667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333%
    }

    .offset-md-11 {
        margin-left: 91.66667%
    }
}

@media (min-width:992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        min-width: 0;
        max-width: 100%
    }

    .row-cols-lg-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-lg-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-lg-3>* {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-lg-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-lg-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-lg-6>* {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-lg-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-lg-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-lg-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-lg-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-lg-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-lg-first {
        order: -1
    }

    .order-lg-last {
        order: 13
    }

    .order-lg-0 {
        order: 0
    }

    .order-lg-1 {
        order: 1
    }

    .order-lg-2 {
        order: 2
    }

    .order-lg-3 {
        order: 3
    }

    .order-lg-4 {
        order: 4
    }

    .order-lg-5 {
        order: 5
    }

    .order-lg-6 {
        order: 6
    }

    .order-lg-7 {
        order: 7
    }

    .order-lg-8 {
        order: 8
    }

    .order-lg-9 {
        order: 9
    }

    .order-lg-10 {
        order: 10
    }

    .order-lg-11 {
        order: 11
    }

    .order-lg-12 {
        order: 12
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333%
    }

    .offset-lg-2 {
        margin-left: 16.66667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333%
    }

    .offset-lg-5 {
        margin-left: 41.66667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333%
    }

    .offset-lg-8 {
        margin-left: 66.66667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333%
    }

    .offset-lg-11 {
        margin-left: 91.66667%
    }
}

@media (min-width:1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        min-width: 0;
        max-width: 100%
    }

    .row-cols-xl-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-xl-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-xl-3>* {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-xl-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-xl-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-xl-6>* {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-xl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-xl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-xl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-xl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-xl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-xl-first {
        order: -1
    }

    .order-xl-last {
        order: 13
    }

    .order-xl-0 {
        order: 0
    }

    .order-xl-1 {
        order: 1
    }

    .order-xl-2 {
        order: 2
    }

    .order-xl-3 {
        order: 3
    }

    .order-xl-4 {
        order: 4
    }

    .order-xl-5 {
        order: 5
    }

    .order-xl-6 {
        order: 6
    }

    .order-xl-7 {
        order: 7
    }

    .order-xl-8 {
        order: 8
    }

    .order-xl-9 {
        order: 9
    }

    .order-xl-10 {
        order: 10
    }

    .order-xl-11 {
        order: 11
    }

    .order-xl-12 {
        order: 12
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333%
    }

    .offset-xl-2 {
        margin-left: 16.66667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333%
    }

    .offset-xl-5 {
        margin-left: 41.66667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333%
    }

    .offset-xl-8 {
        margin-left: 66.66667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333%
    }

    .offset-xl-11 {
        margin-left: 91.66667%
    }
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #1b1642
}

.table td,
.table th {
    padding: 1.25rem;
    vertical-align: top;
    border-top: 1px solid #eaf2fe
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #eaf2fe
}

.table tbody+tbody {
    border-top: 2px solid #eaf2fe
}

.table-sm td,
.table-sm th {
    padding: .3rem
}

.table-bordered {
    border: 1px solid #eaf2fe
}

.table-bordered td,
.table-bordered th {
    border: 1px solid #eaf2fe
}

.table-bordered thead td,
.table-bordered thead th {
    border-bottom-width: 2px
}

.table-borderless tbody+tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
    border: 0
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f3f8ff
}

.table-hover tbody tr:hover {
    color: #1b1642;
    background-color: #f3f8ff
}

.table-primary,
.table-primary>td,
.table-primary>th {
    background-color: #d4d0f9
}

.table-primary tbody+tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
    border-color: #afa8f4
}

.table-hover .table-primary:hover {
    background-color: #bfb9f6
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #bfb9f6
}

.table-secondary,
.table-secondary>td,
.table-secondary>th {
    background-color: #d0d1da
}

.table-secondary tbody+tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
    border-color: #a8a9ba
}

.table-hover .table-secondary:hover {
    background-color: #c2c3cf
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #c2c3cf
}

.table-success,
.table-success>td,
.table-success>th {
    background-color: #c4f3e5
}

.table-success tbody+tbody,
.table-success td,
.table-success th,
.table-success thead th {
    border-color: #91e9cf
}

.table-hover .table-success:hover {
    background-color: #afefdc
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #afefdc
}

.table-info,
.table-info>td,
.table-info>th {
    background-color: #bee5eb
}

.table-info tbody+tbody,
.table-info td,
.table-info th,
.table-info thead th {
    border-color: #86cfda
}

.table-hover .table-info:hover {
    background-color: #abdde5
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #abdde5
}

.table-warning,
.table-warning>td,
.table-warning>th {
    background-color: #fcedd7
}

.table-warning tbody+tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
    border-color: #fadeb5
}

.table-hover .table-warning:hover {
    background-color: #fae2bf
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #fae2bf
}

.table-danger,
.table-danger>td,
.table-danger>th {
    background-color: #fdd4d1
}

.table-danger tbody+tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
    border-color: #fcafaa
}

.table-hover .table-danger:hover {
    background-color: #fcbdb9
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #fcbdb9
}

.table-light,
.table-light>td,
.table-light>th {
    background-color: #fcfdff
}

.table-light tbody+tbody,
.table-light td,
.table-light th,
.table-light thead th {
    border-color: #f9fbff
}

.table-hover .table-light:hover {
    background-color: #e3ecff
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #e3ecff
}

.table-dark,
.table-dark>td,
.table-dark>th {
    background-color: #bfbeca
}

.table-dark tbody+tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #88869d
}

.table-hover .table-dark:hover {
    background-color: #b1b0bf
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #b1b0bf
}

.table-primary-dark,
.table-primary-dark>td,
.table-primary-dark>th {
    background-color: #d0cde9
}

.table-primary-dark tbody+tbody,
.table-primary-dark td,
.table-primary-dark th,
.table-primary-dark thead th {
    border-color: #a7a2d5
}

.table-hover .table-primary-dark:hover {
    background-color: #bfbbe1
}

.table-hover .table-primary-dark:hover>td,
.table-hover .table-primary-dark:hover>th {
    background-color: #bfbbe1
}

.table-primary-light,
.table-primary-light>td,
.table-primary-light>th {
    background-color: #d5dbfa
}

.table-primary-light tbody+tbody,
.table-primary-light td,
.table-primary-light th,
.table-primary-light thead th {
    border-color: #b0bbf6
}

.table-hover .table-primary-light:hover {
    background-color: #bec7f7
}

.table-hover .table-primary-light:hover>td,
.table-hover .table-primary-light:hover>th {
    background-color: #bec7f7
}

.table-active,
.table-active>td,
.table-active>th {
    background-color: #f3f8ff
}

.table-hover .table-active:hover {
    background-color: #dae9ff
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: #dae9ff
}

.table .thead-dark th {
    color: #fff;
    background-color: #1b1642;
    border-color: #27205f
}

.table .thead-light th {
    color: #1b1642;
    background-color: #eaf2fe;
    border-color: #eaf2fe
}

.table-dark {
    color: #fff;
    background-color: #1b1642
}

.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #27205f
}

.table-dark.table-bordered {
    border: 0
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, .05)
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, .075)
}

@media (max-width:575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-sm>.table-bordered {
        border: 0
    }
}

@media (max-width:767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-md>.table-bordered {
        border: 0
    }
}

@media (max-width:991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-lg>.table-bordered {
        border: 0
    }
}

@media (max-width:1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-xl>.table-bordered {
        border: 0
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

.table-responsive>.table-bordered {
    border: 0
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.6em + 1.5rem + 2px);
    padding: .75rem 1.25rem;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.6;
    color: #1b1642;
    background-color: #f3f8ff;
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: .5rem .5rem .5rem .5rem;
    box-shadow: none;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control {
        transition: none
    }
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0
}

.form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #1b1642
}

.form-control:focus {
    color: #1b1642;
    background-color: #f3f8ff;
    border-color: transparent;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(102, 88, 234, .25)
}

.form-control::-moz-placeholder {
    color: #575a7b;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #575a7b;
    opacity: 1
}

.form-control::placeholder {
    color: #575a7b;
    opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #eaf2fe;
    opacity: 1
}

input[type=date].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control,
input[type=time].form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

select.form-control:focus::-ms-value {
    color: #1b1642;
    background-color: #f3f8ff
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%
}

.col-form-label {
    padding-top: calc(.75rem + 1px);
    padding-bottom: calc(.75rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.6
}

.col-form-label-lg {
    padding-top: calc(1rem + 1px);
    padding-bottom: calc(1rem + 1px);
    font-size: 1.125rem;
    line-height: 1.6
}

.col-form-label-sm {
    padding-top: calc(.5rem + 1px);
    padding-bottom: calc(.5rem + 1px);
    font-size: .875rem;
    line-height: 1.6
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: .75rem 0;
    margin-bottom: 0;
    font-size: 1.125rem;
    line-height: 1.6;
    color: #1b1642;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    height: calc(1.6em + 1rem + 2px);
    padding: .5rem 1rem;
    font-size: .875rem;
    line-height: 1.6;
    border-radius: .5rem 0 .5rem 0
}

.form-control-lg {
    height: calc(1.6em + 2rem + 2px);
    padding: 1rem 1.5rem;
    font-size: 1.125rem;
    line-height: 1.6;
    border-radius: .5rem 0 .5rem 0
}

select.form-control[multiple],
select.form-control[size] {
    height: auto
}

textarea.form-control {
    height: auto
}

.form-group {
    margin-bottom: 1.5rem
}

.form-text {
    display: block;
    margin-top: .25rem
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px
}

.form-row>.col,
.form-row>[class*=col-] {
    padding-right: 5px;
    padding-left: 5px
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem
}

.form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    color: #575a7b
}

.form-check-label {
    margin-bottom: 0
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 1rem;
    color: #2cd4a2
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem 1rem;
    margin-top: .1rem;
    font-size: 1rem;
    line-height: 1.6;
    color: #fff;
    background-color: #2cd4a2;
    border-radius: .375rem
}

.is-valid~.valid-feedback,
.is-valid~.valid-tooltip,
.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip {
    display: block
}

.form-control.is-valid,
.was-validated .form-control:valid {
    border-color: #2cd4a2;
    padding-right: calc(1.6em + 1.5rem);
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999' stroke='%232CD4A2' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M22 4L12 14.01L9 11.01' stroke='%232CD4A2' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.4em + .375rem) center;
    background-size: calc(.8em + .75rem) calc(.8em + .75rem)
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
    border-color: #2cd4a2;
    box-shadow: 0 0 0 0 rgba(44, 212, 162, .25)
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    padding-right: calc(1.6em + 1.5rem);
    background-position: top calc(.4em + .375rem) right calc(.4em + .375rem)
}

.custom-select.is-valid,
.was-validated .custom-select:valid {
    border-color: #2cd4a2;
    padding-right: calc(.75em + 3.375rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 8'%3e%3cpath fill='none' stroke='%238D9AAE' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' d='M1 1L7 7L13 1'/%3e%3c/svg%3e") no-repeat right 1.25rem center/14px 8px, url("data:image/svg+xml,%3csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999' stroke='%232CD4A2' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M22 4L12 14.01L9 11.01' stroke='%232CD4A2' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e") #f3f8ff no-repeat center right 2.25rem/calc(.8em + .75rem) calc(.8em + .75rem)
}

.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
    border-color: #2cd4a2;
    box-shadow: 0 0 0 0 rgba(44, 212, 162, .25)
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: #2cd4a2
}

.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip,
.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip {
    display: block
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
    color: #2cd4a2
}

.custom-control-input.is-valid~.custom-control-label::before,
.was-validated .custom-control-input:valid~.custom-control-label::before {
    border-color: #2cd4a2
}

.custom-control-input.is-valid:checked~.custom-control-label::before,
.was-validated .custom-control-input:valid:checked~.custom-control-label::before {
    border-color: #56ddb5;
    background-color: #56ddb5
}

.custom-control-input.is-valid:focus~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0 rgba(44, 212, 162, .25)
}

.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before {
    border-color: #2cd4a2
}

.custom-file-input.is-valid~.custom-file-label,
.was-validated .custom-file-input:valid~.custom-file-label {
    border-color: #2cd4a2
}

.custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .custom-file-input:valid:focus~.custom-file-label {
    border-color: #2cd4a2;
    box-shadow: 0 0 0 0 rgba(44, 212, 162, .25)
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 1rem;
    color: #f9655b
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem 1rem;
    margin-top: .1rem;
    font-size: 1rem;
    line-height: 1.6;
    color: #fff;
    background-color: #f9655b;
    border-radius: .375rem
}

.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip,
.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip {
    display: block
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: #f9655b;
    padding-right: calc(1.6em + 1.5rem);
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' stroke='%23F9655B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M12 8V12' stroke='%23F9655B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M12 16H12.01' stroke='%23F9655B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.4em + .375rem) center;
    background-size: calc(.8em + .75rem) calc(.8em + .75rem)
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: #f9655b;
    box-shadow: 0 0 0 0 rgba(249, 101, 91, .25)
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    padding-right: calc(1.6em + 1.5rem);
    background-position: top calc(.4em + .375rem) right calc(.4em + .375rem)
}

.custom-select.is-invalid,
.was-validated .custom-select:invalid {
    border-color: #f9655b;
    padding-right: calc(.75em + 3.375rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 8'%3e%3cpath fill='none' stroke='%238D9AAE' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' d='M1 1L7 7L13 1'/%3e%3c/svg%3e") no-repeat right 1.25rem center/14px 8px, url("data:image/svg+xml,%3csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' stroke='%23F9655B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M12 8V12' stroke='%23F9655B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M12 16H12.01' stroke='%23F9655B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e") #f3f8ff no-repeat center right 2.25rem/calc(.8em + .75rem) calc(.8em + .75rem)
}

.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
    border-color: #f9655b;
    box-shadow: 0 0 0 0 rgba(249, 101, 91, .25)
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
    color: #f9655b
}

.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip,
.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip {
    display: block
}

.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label {
    color: #f9655b
}

.custom-control-input.is-invalid~.custom-control-label::before,
.was-validated .custom-control-input:invalid~.custom-control-label::before {
    border-color: #f9655b
}

.custom-control-input.is-invalid:checked~.custom-control-label::before,
.was-validated .custom-control-input:invalid:checked~.custom-control-label::before {
    border-color: #fb938c;
    background-color: #fb938c
}

.custom-control-input.is-invalid:focus~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0 rgba(249, 101, 91, .25)
}

.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before {
    border-color: #f9655b
}

.custom-file-input.is-invalid~.custom-file-label,
.was-validated .custom-file-input:invalid~.custom-file-label {
    border-color: #f9655b
}

.custom-file-input.is-invalid:focus~.custom-file-label,
.was-validated .custom-file-input:invalid:focus~.custom-file-label {
    border-color: #f9655b;
    box-shadow: 0 0 0 0 rgba(249, 101, 91, .25)
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center
}

.form-inline .form-check {
    width: 100%
}

@media (min-width:576px) {
    .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0
    }

    .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }

    .form-inline .form-control-plaintext {
        display: inline-block
    }

    .form-inline .custom-select,
    .form-inline .input-group {
        width: auto
    }

    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0
    }

    .form-inline .form-check-input {
        position: relative;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: .25rem;
        margin-left: 0
    }

    .form-inline .custom-control {
        align-items: center;
        justify-content: center
    }

    .form-inline .custom-control-label {
        margin-bottom: 0
    }
}

.btn {
    display: inline-block;
    font-weight: 500;
    color: #1b1642;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .75rem 1.75rem;
    font-size: 1.125rem;
    line-height: 1.6;
    border-radius: .5rem .5rem .5rem .5rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    color: #1b1642;
    text-decoration: none
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(102, 88, 234, .25)
}

.btn.disabled,
.btn:disabled {
    opacity: .65;
    box-shadow: none
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer
}

.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06)
}

.btn:not(:disabled):not(.disabled).active:focus,
.btn:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0 rgba(102, 88, 234, .25), 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06)
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none
}

.btn-primary {
    color: #fff;
    background-color: #FF7272;
    border-color: #FF7272;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06)
}

.btn-primary:hover {
    color: #fff;
    background-color: #c43d3d;
    border-color: #c72f2f
}

.btn-primary.focus,
.btn-primary:focus {
    color: #fff;
    background-color: #c43d3d;
    border-color: #c72f2f;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(125, 113, 237, .5)
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #6658ea;
    border-color: #6658ea
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #c43d3d;
    border-color: #c43d3d
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(125, 113, 237, .5)
}

.btn-secondary {
    color: #CA8787;
    background-color: #FFF1F1;
    border-color: #FFF1F1;
    /*     -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06);
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06) */
}

.btn-secondary:hover {
    color: #ac6161;
    background-color: #f1d8d8;
    border-color: #f1d8d8
}

.btn-secondary.focus,
.btn-secondary:focus {
    color: #fff;
    background-color: #474a65;
    border-color: #42445d;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(112, 115, 143, .5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background-color: #575a7b;
    border-color: #575a7b
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #42445d;
    border-color: #3d3f56
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(112, 115, 143, .5)
}

.btn-success {
    color: #fff;
    background-color: #2cd4a2;
    border-color: #2cd4a2;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06)
}

.btn-success:hover {
    color: #fff;
    background-color: #25b58a;
    border-color: #23aa82
}

.btn-success.focus,
.btn-success:focus {
    color: #fff;
    background-color: #25b58a;
    border-color: #23aa82;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(76, 218, 176, .5)
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #2cd4a2;
    border-color: #2cd4a2
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #23aa82;
    border-color: #21a07a
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(76, 218, 176, .5)
}

.btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06)
}

.btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b
}

.btn-info.focus,
.btn-info:focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(58, 176, 195, .5)
}

.btn-info.disabled,
.btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(58, 176, 195, .5)
}

.btn-warning {
    color: #fff;
    background-color: #f5c070;
    border-color: #f5c070;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06)
}

.btn-warning:hover {
    color: #fff;
    background-color: #f3b04c;
    border-color: #f2ab40
}

.btn-warning.focus,
.btn-warning:focus {
    color: #fff;
    background-color: #f3b04c;
    border-color: #f2ab40;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(247, 201, 133, .5)
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #fff;
    background-color: #f5c070;
    border-color: #f5c070
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #f2ab40;
    border-color: #f1a634
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(247, 201, 133, .5)
}

.btn-danger {
    color: #fff;
    background-color: #f9655b;
    border-color: #f9655b;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06)
}

.btn-danger:hover {
    color: #fff;
    background-color: #f84236;
    border-color: #f7372a
}

.btn-danger.focus,
.btn-danger:focus {
    color: #fff;
    background-color: #f84236;
    border-color: #f7372a;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(250, 124, 116, .5)
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #f9655b;
    border-color: #f9655b
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #f7372a;
    border-color: #f72b1e
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(250, 124, 116, .5)
}

.btn-light {
    color: #1b1642;
    background-color: #f3f8ff;
    border-color: #f3f8ff;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06)
}

.btn-light:hover {
    color: #1b1642;
    background-color: #cde2ff;
    border-color: #c0daff
}

.btn-light.focus,
.btn-light:focus {
    color: #1b1642;
    background-color: #cde2ff;
    border-color: #c0daff;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(211, 214, 227, .5)
}

.btn-light.disabled,
.btn-light:disabled {
    color: #1b1642;
    background-color: #f3f8ff;
    border-color: #f3f8ff
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
    color: #1b1642;
    background-color: #c0daff;
    border-color: #b3d3ff
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(211, 214, 227, .5)
}

.btn-dark {
    color: #fff;
    background-color: #1b1642;
    border-color: #1b1642;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06)
}

.btn-dark:hover {
    color: #fff;
    background-color: #0f0c25;
    border-color: #0b091c
}

.btn-dark.focus,
.btn-dark:focus {
    color: #fff;
    background-color: #0f0c25;
    border-color: #0b091c;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(61, 57, 94, .5)
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #1b1642;
    border-color: #1b1642
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #0b091c;
    border-color: #070612
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(61, 57, 94, .5)
}

.btn-primary-dark {
    color: #fff;
    background-color: #564caf;
    border-color: #564caf;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06)
}

.btn-primary-dark:hover {
    color: #fff;
    background-color: #494094;
    border-color: #453d8b
}

.btn-primary-dark.focus,
.btn-primary-dark:focus {
    color: #fff;
    background-color: #494094;
    border-color: #453d8b;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(111, 103, 187, .5)
}

.btn-primary-dark.disabled,
.btn-primary-dark:disabled {
    color: #fff;
    background-color: #564caf;
    border-color: #564caf
}

.btn-primary-dark:not(:disabled):not(.disabled).active,
.btn-primary-dark:not(:disabled):not(.disabled):active,
.show>.btn-primary-dark.dropdown-toggle {
    color: #fff;
    background-color: #453d8b;
    border-color: #403983
}

.btn-primary-dark:not(:disabled):not(.disabled).active:focus,
.btn-primary-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary-dark.dropdown-toggle:focus {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(111, 103, 187, .5)
}

.btn-primary-light {
    color: #fff;
    background-color: #687ded;
    border-color: #687ded;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06)
}

.btn-primary-light:hover {
    color: #fff;
    background-color: #4660e9;
    border-color: #3a56e8
}

.btn-primary-light.focus,
.btn-primary-light:focus {
    color: #fff;
    background-color: #4660e9;
    border-color: #3a56e8;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(127, 145, 240, .5)
}

.btn-primary-light.disabled,
.btn-primary-light:disabled {
    color: #fff;
    background-color: #687ded;
    border-color: #687ded
}

.btn-primary-light:not(:disabled):not(.disabled).active,
.btn-primary-light:not(:disabled):not(.disabled):active,
.show>.btn-primary-light.dropdown-toggle {
    color: #fff;
    background-color: #3a56e8;
    border-color: #2f4ce6
}

.btn-primary-light:not(:disabled):not(.disabled).active:focus,
.btn-primary-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary-light.dropdown-toggle:focus {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(127, 145, 240, .5)
}

.btn-outline-primary {
    color: #6658ea;
    border-color: #6658ea
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #6658ea;
    border-color: #6658ea
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 0 rgba(102, 88, 234, .5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #6658ea;
    background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #6658ea;
    border-color: #6658ea
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(102, 88, 234, .5)
}

.btn-outline-secondary {
    color: #575a7b;
    border-color: #575a7b
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #575a7b;
    border-color: #575a7b
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 0 rgba(87, 90, 123, .5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #575a7b;
    background-color: transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #575a7b;
    border-color: #575a7b
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(87, 90, 123, .5)
}

.btn-outline-success {
    color: #2cd4a2;
    border-color: #2cd4a2
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #2cd4a2;
    border-color: #2cd4a2
}

.btn-outline-success.focus,
.btn-outline-success:focus {
    box-shadow: 0 0 0 0 rgba(44, 212, 162, .5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #2cd4a2;
    background-color: transparent
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #2cd4a2;
    border-color: #2cd4a2
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(44, 212, 162, .5)
}

.btn-outline-info {
    color: #17a2b8;
    border-color: #17a2b8
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-outline-info.focus,
.btn-outline-info:focus {
    box-shadow: 0 0 0 0 rgba(23, 162, 184, .5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(23, 162, 184, .5)
}

.btn-outline-warning {
    color: #f5c070;
    border-color: #f5c070
}

.btn-outline-warning:hover {
    color: #fff;
    background-color: #f5c070;
    border-color: #f5c070
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
    box-shadow: 0 0 0 0 rgba(245, 192, 112, .5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #f5c070;
    background-color: transparent
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #f5c070;
    border-color: #f5c070
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(245, 192, 112, .5)
}

.btn-outline-danger {
    color: #f9655b;
    border-color: #f9655b
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #f9655b;
    border-color: #f9655b
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
    box-shadow: 0 0 0 0 rgba(249, 101, 91, .5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #f9655b;
    background-color: transparent
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f9655b;
    border-color: #f9655b
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(249, 101, 91, .5)
}

.btn-outline-light {
    color: #f3f8ff;
    border-color: #f3f8ff
}

.btn-outline-light:hover {
    color: #1b1642;
    background-color: #f3f8ff;
    border-color: #f3f8ff
}

.btn-outline-light.focus,
.btn-outline-light:focus {
    box-shadow: 0 0 0 0 rgba(243, 248, 255, .5)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f3f8ff;
    background-color: transparent
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show>.btn-outline-light.dropdown-toggle {
    color: #1b1642;
    background-color: #f3f8ff;
    border-color: #f3f8ff
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(243, 248, 255, .5)
}

.btn-outline-dark {
    color: #1b1642;
    border-color: #1b1642
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #1b1642;
    border-color: #1b1642
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
    box-shadow: 0 0 0 0 rgba(27, 22, 66, .5)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #1b1642;
    background-color: transparent
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #1b1642;
    border-color: #1b1642
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(27, 22, 66, .5)
}

.btn-outline-primary-dark {
    color: #564caf;
    border-color: #564caf
}

.btn-outline-primary-dark:hover {
    color: #fff;
    background-color: #564caf;
    border-color: #564caf
}

.btn-outline-primary-dark.focus,
.btn-outline-primary-dark:focus {
    box-shadow: 0 0 0 0 rgba(86, 76, 175, .5)
}

.btn-outline-primary-dark.disabled,
.btn-outline-primary-dark:disabled {
    color: #564caf;
    background-color: transparent
}

.btn-outline-primary-dark:not(:disabled):not(.disabled).active,
.btn-outline-primary-dark:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary-dark.dropdown-toggle {
    color: #fff;
    background-color: #564caf;
    border-color: #564caf
}

.btn-outline-primary-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary-dark.dropdown-toggle:focus {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(86, 76, 175, .5)
}

.btn-outline-primary-light {
    color: #687ded;
    border-color: #687ded
}

.btn-outline-primary-light:hover {
    color: #fff;
    background-color: #687ded;
    border-color: #687ded
}

.btn-outline-primary-light.focus,
.btn-outline-primary-light:focus {
    box-shadow: 0 0 0 0 rgba(104, 125, 237, .5)
}

.btn-outline-primary-light.disabled,
.btn-outline-primary-light:disabled {
    color: #687ded;
    background-color: transparent
}

.btn-outline-primary-light:not(:disabled):not(.disabled).active,
.btn-outline-primary-light:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary-light.dropdown-toggle {
    color: #fff;
    background-color: #687ded;
    border-color: #687ded
}

.btn-outline-primary-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary-light.dropdown-toggle:focus {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(104, 125, 237, .5)
}

.btn-link {
    font-weight: 400;
    color: #6658ea;
    text-decoration: none
}

.btn-link:hover {
    color: #5546e8;
    text-decoration: none
}

.btn-link.focus,
.btn-link:focus {
    text-decoration: none
}

.btn-link.disabled,
.btn-link:disabled {
    color: #575a7b;
    pointer-events: none
}

.btn-group-lg>.btn,
.btn-lg {
    padding: 1rem 2.25rem;
    font-size: 1.125rem;
    line-height: 1.6;
    border-radius: .5rem .5rem .5rem .5rem
}

.btn-group-sm>.btn,
.btn-sm {
    padding: .5rem 1rem;
    font-size: .875rem;
    line-height: 1.6;
    border-radius: .5rem .5rem .5rem .5rem
}

.btn-block {
    display: block;
    width: 100%
}

.btn-block+.btn-block {
    margin-top: .5rem
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
    width: 100%
}

.fade {
    transition: opacity .15s linear
}

@media (prefers-reduced-motion:reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing {
        transition: none
    }
}

.dropdown,
.dropleft,
.dropright,
.dropup {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 16rem;
    padding: 2rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #1b1642;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(27, 22, 66, .15);
    border-radius: 1rem 0 1rem 0;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06)
}

.dropdown-menu-left {
    right: auto;
    left: 0
}

.dropdown-menu-right {
    right: 0;
    left: auto
}

@media (min-width:576px) {
    .dropdown-menu-sm-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-sm-right {
        right: 0;
        left: auto
    }
}

@media (min-width:768px) {
    .dropdown-menu-md-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-md-right {
        right: 0;
        left: auto
    }
}

@media (min-width:992px) {
    .dropdown-menu-lg-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-lg-right {
        right: 0;
        left: auto
    }
}

@media (min-width:1200px) {
    .dropdown-menu-xl-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-xl-right {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: .125rem
}

.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: .125rem
}

.dropright .dropdown-toggle::after {
    vertical-align: 0
}

.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: .125rem
}

.dropleft .dropdown-toggle::before {
    vertical-align: 0
}

.dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=top] {
    right: auto;
    bottom: auto
}

.dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid #eaf2fe
}

.dropdown-item,
.dropdown-link {
    display: block;
    width: 100%;
    padding: 0 0;
    clear: both;
    font-weight: 400;
    color: #1b1642;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:focus,
.dropdown-item:hover,
.dropdown-link:focus,
.dropdown-link:hover {
    color: #6658ea;
    text-decoration: none;
    background-color: transparent
}

.active.dropdown-link,
.dropdown-item.active,
.dropdown-item:active,
.dropdown-link:active {
    color: #6658ea;
    text-decoration: none;
    background-color: transparent
}

.disabled.dropdown-link,
.dropdown-item.disabled,
.dropdown-item:disabled,
.dropdown-link:disabled {
    color: #575a7b;
    pointer-events: none;
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: 2rem 0;
    margin-bottom: 0;
    font-size: 1rem;
    color: #6658ea;
    white-space: nowrap
}

.dropdown-item-text {
    display: block;
    padding: 0 0;
    color: #1b1642
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle
}

.btn-group-vertical>.btn,
.btn-group>.btn {
    position: relative;
    flex: 1 1 auto
}

.btn-group-vertical>.btn:hover,
.btn-group>.btn:hover {
    z-index: 1
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus {
    z-index: 1
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child) {
    margin-left: -1px
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: 1.3125rem;
    padding-left: 1.3125rem
}

.dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
    margin-left: 0
}

.dropleft .dropdown-toggle-split::before {
    margin-right: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
    padding-right: 1.6875rem;
    padding-left: 1.6875rem
}

.btn-group.show .dropdown-toggle {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06)
}

.btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn-group:not(:first-child),
.btn-group-vertical>.btn:not(:first-child) {
    margin-top: -1px
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
    margin-bottom: 0
}

.btn-group-toggle>.btn input[type=checkbox],
.btn-group-toggle>.btn input[type=radio],
.btn-group-toggle>.btn-group>.btn input[type=checkbox],
.btn-group-toggle>.btn-group>.btn input[type=radio] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
}

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control,
.input-group>.form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0
}

.input-group>.custom-file+.custom-file,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.form-control,
.input-group>.custom-select+.custom-file,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.form-control,
.input-group>.form-control+.custom-file,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.form-control,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.form-control {
    margin-left: -1px
}

.input-group>.custom-file .custom-file-input:focus~.custom-file-label,
.input-group>.custom-select:focus,
.input-group>.form-control:focus {
    z-index: 3
}

.input-group>.custom-file .custom-file-input:focus {
    z-index: 4
}

.input-group>.custom-select:not(:last-child),
.input-group>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group>.custom-file {
    display: flex;
    align-items: center
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group-append,
.input-group-prepend {
    display: flex
}

.input-group-append .btn,
.input-group-prepend .btn {
    position: relative;
    z-index: 2
}

.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
    z-index: 3
}

.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.btn,
.input-group-append .input-group-text+.input-group-text,
.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-prepend .input-group-text+.input-group-text {
    margin-left: -1px
}

.input-group-prepend {
    margin-right: -1px
}

.input-group-append {
    margin-left: -1px
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.6;
    color: #8d9aae;
    text-align: center;
    white-space: nowrap;
    background-color: #f3f8ff;
    border: 1px solid transparent;
    border-radius: .5rem 0 .5rem 0
}

.input-group-text input[type=checkbox],
.input-group-text input[type=radio] {
    margin-top: 0
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control:not(textarea) {
    height: calc(1.6em + 2rem + 2px)
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control,
.input-group-lg>.input-group-append>.btn,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-prepend>.input-group-text {
    padding: 1rem 1.5rem;
    font-size: 1.125rem;
    line-height: 1.6;
    border-radius: .5rem 0 .5rem 0
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control:not(textarea) {
    height: calc(1.6em + 1rem + 2px)
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
    padding: .5rem 1rem;
    font-size: .875rem;
    line-height: 1.6;
    border-radius: .5rem 0 .5rem 0
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
    padding-right: 2.25rem
}

.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.8rem;
    padding-left: 1.875rem
}

.custom-control-inline {
    display: inline-flex;
    margin-right: 1rem
}

.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1.125rem;
    height: 1.4625rem;
    opacity: 0
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #6658ea;
    background-color: #6658ea;
    box-shadow: none
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none, 0 0 0 0 rgba(102, 88, 234, .25)
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: transparent
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
    color: #fff;
    background-color: #6658ea;
    border-color: #6658ea;
    box-shadow: none
}

.custom-control-input:disabled~.custom-control-label,
.custom-control-input[disabled]~.custom-control-label {
    color: #575a7b
}

.custom-control-input:disabled~.custom-control-label::before,
.custom-control-input[disabled]~.custom-control-label::before {
    background-color: #eaf2fe
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top
}

.custom-control-label::before {
    position: absolute;
    top: .3375rem;
    left: -1.875rem;
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    pointer-events: none;
    content: "";
    background-color: #dce0f3;
    border: #8d9aae solid 0;
    box-shadow: none
}

.custom-control-label::after {
    position: absolute;
    top: .3375rem;
    left: -1.875rem;
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    content: "";
    background: no-repeat 50%/70% 70%
}

.custom-checkbox .custom-control-label::before {
    border-radius: 1rem
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 8'%3e%3cpath fill='none' stroke='%23FFF' stroke-width='1.125' stroke-linecap='round' stroke-linejoin='round' d='M10.5 0.785156L4.3125 7.21373L1.5 4.29165'/%3e%3c/svg%3e")
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    border-color: #6658ea;
    background-color: #6658ea;
    box-shadow: none
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23FFF' d='M0 2h4'/%3e%3c/svg%3e")
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(102, 88, 234, .5)
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
    background-color: rgba(102, 88, 234, .5)
}

.custom-radio .custom-control-label::before {
    border-radius: 50%
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 8'%3e%3cpath fill='none' stroke='%23FFF' stroke-width='1.125' stroke-linecap='round' stroke-linejoin='round' d='M10.5 0.785156L4.3125 7.21373L1.5 4.29165'/%3e%3c/svg%3e")
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(102, 88, 234, .5)
}

.custom-switch {
    padding-left: 3.25rem
}

.custom-switch .custom-control-label::before {
    left: -3.25rem;
    width: 2.5rem;
    pointer-events: all;
    border-radius: 10rem
}

.custom-switch .custom-control-label::after {
    top: .3375rem;
    left: -3.25rem;
    width: 1.125rem;
    height: 1.125rem;
    background-color: #8d9aae;
    border-radius: 10rem;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .custom-switch .custom-control-label::after {
        transition: none
    }
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #dce0f3;
    transform: translateX(1.375rem)
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(102, 88, 234, .5)
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.6em + 1.5rem + 2px);
    padding: .75rem 2.25rem .75rem 1.25rem;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.6;
    color: #1b1642;
    vertical-align: middle;
    background: #f3f8ff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 8'%3e%3cpath fill='none' stroke='%238D9AAE' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' d='M1 1L7 7L13 1'/%3e%3c/svg%3e") no-repeat right 1.25rem center/14px 8px;
    border: 1px solid transparent;
    border-radius: .5rem 0 .5rem 0;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.custom-select:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(102, 88, 234, .25)
}

.custom-select:focus::-ms-value {
    color: #1b1642;
    background-color: #f3f8ff
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1.25rem;
    background-image: none
}

.custom-select:disabled {
    color: #575a7b;
    background-color: #eaf2fe
}

.custom-select::-ms-expand {
    display: none
}

.custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #1b1642
}

.custom-select-sm {
    height: calc(1.6em + 1rem + 2px);
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    font-size: .875rem
}

.custom-select-lg {
    height: calc(1.6em + 2rem + 2px);
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    font-size: 1.125rem
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.6em + 1.5rem + 2px);
    margin-bottom: 0
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.6em + 1.5rem + 2px);
    margin: 0;
    opacity: 0
}

.custom-file-input:focus~.custom-file-label {
    border-color: transparent;
    box-shadow: 0 0 0 0 rgba(102, 88, 234, .25)
}

.custom-file-input:disabled~.custom-file-label,
.custom-file-input[disabled]~.custom-file-label {
    background-color: #eaf2fe
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse"
}

.custom-file-input~.custom-file-label[data-browse]::after {
    content: attr(data-browse)
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.6em + 1.5rem + 2px);
    padding: .75rem 1.25rem;
    font-weight: 400;
    line-height: 1.6;
    color: #1b1642;
    background-color: #f3f8ff;
    border: 1px solid transparent;
    border-radius: .5rem 0 .5rem 0;
    box-shadow: none
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.6em + 1.5rem);
    padding: .75rem 1.25rem;
    line-height: 1.6;
    color: #1b1642;
    content: "Browse";
    background-color: #f3f8ff;
    border-left: inherit;
    border-radius: 0 .5rem 0 .5rem 0 .5rem 0 .5rem 0 0
}

.custom-range {
    width: 100%;
    height: 1rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.custom-range:focus {
    outline: 0
}

.custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(102, 88, 234, .25)
}

.custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(102, 88, 234, .25)
}

.custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(102, 88, 234, .25)
}

.custom-range::-moz-focus-outer {
    border: 0
}

.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    background-color: #6658ea;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 .1rem .25rem rgba(27, 22, 66, .1);
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none
    }
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #f7f7fe
}

.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dce0f3;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 .25rem .25rem rgba(27, 22, 66, .1)
}

.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #6658ea;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 .1rem .25rem rgba(27, 22, 66, .1);
    -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none
    }
}

.custom-range::-moz-range-thumb:active {
    background-color: #f7f7fe
}

.custom-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dce0f3;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 .25rem .25rem rgba(27, 22, 66, .1)
}

.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    background-color: #6658ea;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 .1rem .25rem rgba(27, 22, 66, .1);
    -ms-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none
    }
}

.custom-range::-ms-thumb:active {
    background-color: #f7f7fe
}

.custom-range::-ms-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: .5rem;
    box-shadow: inset 0 .25rem .25rem rgba(27, 22, 66, .1)
}

.custom-range::-ms-fill-lower {
    background-color: #dce0f3;
    border-radius: 1rem
}

.custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dce0f3;
    border-radius: 1rem
}

.custom-range:disabled::-webkit-slider-thumb {
    background-color: #8d9aae
}

.custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default
}

.custom-range:disabled::-moz-range-thumb {
    background-color: #8d9aae
}

.custom-range:disabled::-moz-range-track {
    cursor: default
}

.custom-range:disabled::-ms-thumb {
    background-color: #8d9aae
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {

    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
        transition: none
    }
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: .5rem 1rem
}

.nav-link:focus,
.nav-link:hover {
    text-decoration: none
}

.nav-link.disabled {
    color: #575a7b;
    pointer-events: none;
    cursor: default
}

.nav-tabs {
    border-bottom: 3px solid transparent
}

.nav-tabs .nav-item {
    margin-bottom: -3px
}

.nav-tabs .nav-link {
    border: 3px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #eaf2fe #eaf2fe transparent
}

.nav-tabs .nav-link.disabled {
    color: #575a7b;
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #495057;
    background-color: transparent;
    border-color: transparent transparent #f5c070 transparent
}

.nav-tabs .dropdown-menu {
    margin-top: -3px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills .nav-link {
    border-radius: .5rem
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #6658ea
}

.nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 1rem
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-lg,
.navbar .container-md,
.navbar .container-sm,
.navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between
}

.navbar-brand {
    display: inline-block;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 1rem;
    font-size: 1.75rem;
    line-height: inherit;
    white-space: nowrap
}

.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none
}

.navbar-text {
    display: inline-block;
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .5rem 0 .5rem 0
}

.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%
}

@media (max-width:575.98px) {

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid,
    .navbar-expand-sm>.container-lg,
    .navbar-expand-sm>.container-md,
    .navbar-expand-sm>.container-sm,
    .navbar-expand-sm>.container-xl {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 1.875rem;
        padding-left: 1.875rem
    }

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid,
    .navbar-expand-sm>.container-lg,
    .navbar-expand-sm>.container-md,
    .navbar-expand-sm>.container-sm,
    .navbar-expand-sm>.container-xl {
        flex-wrap: nowrap
    }

    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler {
        display: none
    }
}

@media (max-width:767.98px) {

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid,
    .navbar-expand-md>.container-lg,
    .navbar-expand-md>.container-md,
    .navbar-expand-md>.container-sm,
    .navbar-expand-md>.container-xl {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 1.875rem;
        padding-left: 1.875rem
    }

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid,
    .navbar-expand-md>.container-lg,
    .navbar-expand-md>.container-md,
    .navbar-expand-md>.container-sm,
    .navbar-expand-md>.container-xl {
        flex-wrap: nowrap
    }

    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }
}

@media (max-width:991.98px) {

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid,
    .navbar-expand-lg>.container-lg,
    .navbar-expand-lg>.container-md,
    .navbar-expand-lg>.container-sm,
    .navbar-expand-lg>.container-xl {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1.875rem;
        padding-left: 1.875rem
    }

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid,
    .navbar-expand-lg>.container-lg,
    .navbar-expand-lg>.container-md,
    .navbar-expand-lg>.container-sm,
    .navbar-expand-lg>.container-xl {
        flex-wrap: nowrap
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }
}

@media (max-width:1199.98px) {

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid,
    .navbar-expand-xl>.container-lg,
    .navbar-expand-xl>.container-md,
    .navbar-expand-xl>.container-sm,
    .navbar-expand-xl>.container-xl {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width:1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 1.875rem;
        padding-left: 1.875rem
    }

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid,
    .navbar-expand-xl>.container-lg,
    .navbar-expand-xl>.container-md,
    .navbar-expand-xl>.container-sm,
    .navbar-expand-xl>.container-xl {
        flex-wrap: nowrap
    }

    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler {
        display: none
    }
}

.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-lg,
.navbar-expand>.container-md,
.navbar-expand>.container-sm,
.navbar-expand>.container-xl {
    padding-right: 0;
    padding-left: 0
}

.navbar-expand .navbar-nav {
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 1.875rem;
    padding-left: 1.875rem
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-lg,
.navbar-expand>.container-md,
.navbar-expand>.container-sm,
.navbar-expand>.container-xl {
    flex-wrap: nowrap
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-light .navbar-brand {
    color: #1b1642
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: #1b1642
}

.navbar-light .navbar-nav .nav-link {
    color: #1b1642
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: #1b1642
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: #1b1642
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: #1b1642
}

.navbar-light .navbar-toggler {
    color: #1b1642;
    border-color: transparent
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23575A7B' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-light .navbar-text {
    color: #1b1642
}

.navbar-light .navbar-text a {
    color: #1b1642
}

.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
    color: #1b1642
}

.navbar-dark .navbar-brand {
    color: #fff
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: #fff
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
    color: #fff
}

.navbar-dark .navbar-toggler {
    color: #fff;
    border-color: transparent
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23FFF' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-dark .navbar-text {
    color: #fff
}

.navbar-dark .navbar-text a {
    color: #fff
}

.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
    color: #fff
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(27, 22, 66, .125);
    border-radius: 0
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card>.list-group {
    border-top: inherit;
    border-bottom: inherit
}

.card>.list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.card>.list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 3rem
}

.card-title {
    margin-bottom: 2rem
}

.card-subtitle {
    margin-top: -1rem;
    margin-bottom: 0
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link:hover {
    text-decoration: none
}

.card-link+.card-link {
    margin-left: 3rem
}

.card-header {
    padding: 2rem 3rem;
    margin-bottom: 0;
    background-color: rgba(27, 22, 66, .03);
    border-bottom: 0 solid rgba(27, 22, 66, .125)
}

.card-header:first-child {
    border-radius: 0
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0
}

.card-footer {
    padding: 2rem 3rem;
    background-color: rgba(27, 22, 66, .03);
    border-top: 0 solid rgba(27, 22, 66, .125)
}

.card-footer:last-child {
    border-radius: 0
}

.card-header-tabs {
    margin-right: -1.5rem;
    margin-bottom: -2rem;
    margin-left: -1.5rem;
    border-bottom: 0
}

.card-header-pills {
    margin-right: -1.5rem;
    margin-left: -1.5rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem
}

.card-img,
.card-img-bottom,
.card-img-top {
    flex-shrink: 0;
    width: 100%
}

.card-img,
.card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.card-deck .card {
    margin-bottom: 16px
}

@media (min-width:576px) {
    .card-deck {
        display: flex;
        flex-flow: row wrap;
        margin-right: -16px;
        margin-left: -16px
    }

    .card-deck .card {
        flex: 1 0 0%;
        margin-right: 16px;
        margin-bottom: 0;
        margin-left: 16px
    }
}

.card-group>.card {
    margin-bottom: 16px
}

@media (min-width:576px) {
    .card-group {
        display: flex;
        flex-flow: row wrap
    }

    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-header,
    .card-group>.card:not(:last-child) .card-img-top {
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-footer,
    .card-group>.card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-header,
    .card-group>.card:not(:first-child) .card-img-top {
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-footer,
    .card-group>.card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0
    }
}

.card-columns .card {
    margin-bottom: 2rem
}

@media (min-width:576px) {
    .card-columns {
        -moz-column-count: 3;
        column-count: 3;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1
    }

    .card-columns .card {
        display: inline-block;
        width: 100%
    }
}

.accordion>.card {
    overflow: hidden
}

.accordion>.card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.accordion>.card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.accordion>.card>.card-header {
    border-radius: 0;
    margin-bottom: 0
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: .5rem 1rem;
    margin-bottom: 1rem;
    font-size: .875rem;
    list-style: none;
    background-color: #f3f8ff;
    border-radius: .5rem
}

.breadcrumb-item {
    display: flex
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: .625rem
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .625rem;
    color: #575a7b;
    content: ""
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: underline
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: none
}

.breadcrumb-item.active {
    color: #1b1642
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 1rem
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #6658ea;
    background-color: #fff;
    border: 1px solid #eaf2fe
}

.page-link:hover {
    z-index: 2;
    color: #5546e8;
    text-decoration: none;
    background-color: #f3f8ff;
    border-color: #dce0f3
}

.page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(102, 88, 234, .25)
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem
}

.page-item:last-child .page-link {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #6658ea;
    border-color: #6658ea
}

.page-item.disabled .page-link {
    color: #575a7b;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dce0f3
}

.pagination-lg .page-link {
    padding: .75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem
}

.pagination-sm .page-link {
    padding: .25rem .5rem;
    font-size: 1rem;
    line-height: 1.5
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem
}

.badge {
    display: inline-block;
    padding: .3em .6em;
    font-size: 75%;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 1rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .badge {
        transition: none
    }
}

a.badge:focus,
a.badge:hover {
    text-decoration: none
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.badge-pill {
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem
}

.badge-primary {
    color: #fff;
    background-color: #6658ea
}

a.badge-primary:focus,
a.badge-primary:hover {
    color: #fff;
    background-color: #3c2be4
}

a.badge-primary.focus,
a.badge-primary:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(102, 88, 234, .5)
}

.badge-secondary {
    color: #fff;
    background-color: #575a7b
}

a.badge-secondary:focus,
a.badge-secondary:hover {
    color: #fff;
    background-color: #42445d
}

a.badge-secondary.focus,
a.badge-secondary:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(87, 90, 123, .5)
}

.badge-success {
    color: #fff;
    background-color: #2cd4a2
}

a.badge-success:focus,
a.badge-success:hover {
    color: #fff;
    background-color: #23aa82
}

a.badge-success.focus,
a.badge-success:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(44, 212, 162, .5)
}

.badge-info {
    color: #fff;
    background-color: #17a2b8
}

a.badge-info:focus,
a.badge-info:hover {
    color: #fff;
    background-color: #117a8b
}

a.badge-info.focus,
a.badge-info:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(23, 162, 184, .5)
}

.badge-warning {
    color: #fff;
    background-color: #f5c070
}

a.badge-warning:focus,
a.badge-warning:hover {
    color: #fff;
    background-color: #f2ab40
}

a.badge-warning.focus,
a.badge-warning:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(245, 192, 112, .5)
}

.badge-danger {
    color: #fff;
    background-color: #f9655b
}

a.badge-danger:focus,
a.badge-danger:hover {
    color: #fff;
    background-color: #f7372a
}

a.badge-danger.focus,
a.badge-danger:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(249, 101, 91, .5)
}

.badge-light {
    color: #1b1642;
    background-color: #f3f8ff
}

a.badge-light:focus,
a.badge-light:hover {
    color: #1b1642;
    background-color: #c0daff
}

a.badge-light.focus,
a.badge-light:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(243, 248, 255, .5)
}

.badge-dark {
    color: #fff;
    background-color: #1b1642
}

a.badge-dark:focus,
a.badge-dark:hover {
    color: #fff;
    background-color: #0b091c
}

a.badge-dark.focus,
a.badge-dark:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(27, 22, 66, .5)
}

.badge-primary-dark {
    color: #fff;
    background-color: #564caf
}

a.badge-primary-dark:focus,
a.badge-primary-dark:hover {
    color: #fff;
    background-color: #453d8b
}

a.badge-primary-dark.focus,
a.badge-primary-dark:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(86, 76, 175, .5)
}

.badge-primary-light {
    color: #fff;
    background-color: #687ded
}

a.badge-primary-light:focus,
a.badge-primary-light:hover {
    color: #fff;
    background-color: #3a56e8
}

a.badge-primary-light.focus,
a.badge-primary-light:focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(104, 125, 237, .5)
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #eaf2fe;
    border-radius: 2rem
}

@media (min-width:576px) {
    .jumbotron {
        padding: 4rem 2rem
    }
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0
}

.alert {
    position: relative;
    padding: 1rem 1.25rem;
    margin-bottom: 1rem;
    border: 0 solid transparent;
    border-radius: .5rem
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 500
}

.alert-dismissible {
    padding-right: 3.625rem
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem 1.25rem;
    color: inherit
}

.alert-primary {
    color: #6658ea;
    background-color: #6658ea;
    border-color: #6658ea
}

.alert-primary hr {
    border-top-color: #5141e7
}

.alert-primary .alert-link {
    color: #3c2be4
}

.alert-secondary {
    color: #575a7b;
    background-color: #575a7b;
    border-color: #575a7b
}

.alert-secondary hr {
    border-top-color: #4c4f6c
}

.alert-secondary .alert-link {
    color: #42445d
}

.alert-success {
    color: #2cd4a2;
    background-color: #2cd4a2;
    border-color: #2cd4a2
}

.alert-success hr {
    border-top-color: #27bf92
}

.alert-success .alert-link {
    color: #23aa82
}

.alert-info {
    color: #17a2b8;
    background-color: #17a2b8;
    border-color: #17a2b8
}

.alert-info hr {
    border-top-color: #148ea1
}

.alert-info .alert-link {
    color: #117a8b
}

.alert-warning {
    color: #f5c070;
    background-color: #f5c070;
    border-color: #f5c070
}

.alert-warning hr {
    border-top-color: #f3b658
}

.alert-warning .alert-link {
    color: #f2ab40
}

.alert-danger {
    color: #f9655b;
    background-color: #f9655b;
    border-color: #f9655b
}

.alert-danger hr {
    border-top-color: #f84e42
}

.alert-danger .alert-link {
    color: #f7372a
}

.alert-light {
    color: #f3f8ff;
    background-color: #f3f8ff;
    border-color: #f3f8ff
}

.alert-light hr {
    border-top-color: #dae9ff
}

.alert-light .alert-link {
    color: #c0daff
}

.alert-dark {
    color: #1b1642;
    background-color: #1b1642;
    border-color: #1b1642
}

.alert-dark hr {
    border-top-color: #13102f
}

.alert-dark .alert-link {
    color: #0b091c
}

.alert-primary-dark {
    color: #564caf;
    background-color: #564caf;
    border-color: #564caf
}

.alert-primary-dark hr {
    border-top-color: #4d449d
}

.alert-primary-dark .alert-link {
    color: #453d8b
}

.alert-primary-light {
    color: #687ded;
    background-color: #687ded;
    border-color: #687ded
}

.alert-primary-light hr {
    border-top-color: #5169ea
}

.alert-primary-light .alert-link {
    color: #3a56e8
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0
    }

    to {
        background-position: 0 0
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0
    }

    to {
        background-position: 0 0
    }
}

.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    line-height: 0;
    font-size: .84375rem;
    background-color: #eaf2fe;
    border-radius: 1rem;
    box-shadow: inset 0 .1rem .1rem rgba(27, 22, 66, .1)
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #6658ea;
    transition: width .6s ease
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar {
        transition: none
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem
}

.progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar-animated {
        -webkit-animation: none;
        animation: none
    }
}

.media {
    display: flex;
    align-items: flex-start
}

.media-body {
    flex: 1
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 1rem
}

.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f3f8ff
}

.list-group-item-action:active {
    color: #1b1642;
    background-color: #eaf2fe
}

.list-group-item {
    position: relative;
    display: block;
    padding: 1.5rem 1.25rem;
    background-color: transparent;
    border: 1px solid #eaf2fe
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}

.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #575a7b;
    pointer-events: none;
    background-color: transparent
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #6658ea;
    border-color: #6658ea
}

.list-group-item+.list-group-item {
    border-top-width: 0
}

.list-group-item+.list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px
}

.list-group-horizontal {
    flex-direction: row
}

.list-group-horizontal>.list-group-item:first-child {
    border-bottom-left-radius: 1rem;
    border-top-right-radius: 0
}

.list-group-horizontal>.list-group-item:last-child {
    border-top-right-radius: 1rem;
    border-bottom-left-radius: 0
}

.list-group-horizontal>.list-group-item.active {
    margin-top: 0
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
}

@media (min-width:576px) {
    .list-group-horizontal-sm {
        flex-direction: row
    }

    .list-group-horizontal-sm>.list-group-item:first-child {
        border-bottom-left-radius: 1rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item:last-child {
        border-top-right-radius: 1rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width:768px) {
    .list-group-horizontal-md {
        flex-direction: row
    }

    .list-group-horizontal-md>.list-group-item:first-child {
        border-bottom-left-radius: 1rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-md>.list-group-item:last-child {
        border-top-right-radius: 1rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-md>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width:992px) {
    .list-group-horizontal-lg {
        flex-direction: row
    }

    .list-group-horizontal-lg>.list-group-item:first-child {
        border-bottom-left-radius: 1rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item:last-child {
        border-top-right-radius: 1rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width:1200px) {
    .list-group-horizontal-xl {
        flex-direction: row
    }

    .list-group-horizontal-xl>.list-group-item:first-child {
        border-bottom-left-radius: 1rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item:last-child {
        border-top-right-radius: 1rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

.list-group-flush {
    border-radius: 0
}

.list-group-flush>.list-group-item {
    border-width: 0 0 1px
}

.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0
}

.list-group-item-primary {
    color: #423899;
    background-color: #d4d0f9
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
    color: #423899;
    background-color: #bfb9f6
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #423899;
    border-color: #423899
}

.list-group-item-secondary {
    color: #3a3960;
    background-color: #d0d1da
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
    color: #3a3960;
    background-color: #c2c3cf
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #3a3960;
    border-color: #3a3960
}

.list-group-item-success {
    color: #247974;
    background-color: #c4f3e5
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
    color: #247974;
    background-color: #afefdc
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #247974;
    border-color: #247974
}

.list-group-item-info {
    color: #195f7f;
    background-color: #bee5eb
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
    color: #195f7f;
    background-color: #abdde5
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #195f7f;
    border-color: #195f7f
}

.list-group-item-warning {
    color: #8c6e5a;
    background-color: #fcedd7
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
    color: #8c6e5a;
    background-color: #fae2bf
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #8c6e5a;
    border-color: #8c6e5a
}

.list-group-item-danger {
    color: #8e3f4f;
    background-color: #fdd4d1
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
    color: #8e3f4f;
    background-color: #fcbdb9
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #8e3f4f;
    border-color: #8e3f4f
}

.list-group-item-light {
    color: #8b8ca4;
    background-color: #fcfdff
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
    color: #8b8ca4;
    background-color: #e3ecff
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #8b8ca4;
    border-color: #8b8ca4
}

.list-group-item-dark {
    color: #1b1642;
    background-color: #bfbeca
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
    color: #1b1642;
    background-color: #b1b0bf
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1642;
    border-color: #1b1642
}

.list-group-item-primary-dark {
    color: #3a327b;
    background-color: #d0cde9
}

.list-group-item-primary-dark.list-group-item-action:focus,
.list-group-item-primary-dark.list-group-item-action:hover {
    color: #3a327b;
    background-color: #bfbbe1
}

.list-group-item-primary-dark.list-group-item-action.active {
    color: #fff;
    background-color: #3a327b;
    border-color: #3a327b
}

.list-group-item-primary-light {
    color: #434c9b;
    background-color: #d5dbfa
}

.list-group-item-primary-light.list-group-item-action:focus,
.list-group-item-primary-light.list-group-item-action:hover {
    color: #434c9b;
    background-color: #bec7f7
}

.list-group-item-primary-light.list-group-item-action.active {
    color: #fff;
    background-color: #434c9b;
    border-color: #434c9b
}

.close {
    float: right;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1;
    color: currentColor;
    text-shadow: none;
    opacity: .5
}

.close:hover {
    color: currentColor;
    text-decoration: none
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
    opacity: .75
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0
}

a.close.disabled {
    pointer-events: none
}

.toast {
    max-width: 350px;
    overflow: hidden;
    font-size: .875rem;
    color: #575a7b;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, .1);
    box-shadow: 0 0 24px rgba(27, 22, 66, .04), 0 44px 74px rgba(27, 22, 66, .06);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: .5rem 0 .5rem 0
}

.toast:not(:last-child) {
    margin-bottom: 1rem
}

.toast.showing {
    opacity: 1
}

.toast.show {
    display: block;
    opacity: 1
}

.toast.hide {
    display: none
}

.toast-header {
    display: flex;
    align-items: center;
    padding: 1.25rem 1rem;
    color: #1b1642;
    background-color: transparent;
    background-clip: padding-box;
    border-bottom: 0 solid #eaf2fe
}

.toast-body {
    padding: 1rem
}

.modal-open {
    overflow: hidden
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none
}

.modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0, -50px)
}

@media (prefers-reduced-motion:reduce) {
    .modal.fade .modal-dialog {
        transition: none
    }
}

.modal.show .modal-dialog {
    transform: none
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02)
}

.modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
    flex-shrink: 0
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem)
}

.modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: ""
}

.modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none
}

.modal-dialog-centered.modal-dialog-scrollable::before {
    content: none
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(27, 22, 66, .2);
    border-radius: 1rem 0 1rem 0;
    box-shadow: 0 0 24px rgba(27, 22, 66, .04), 0 44px 74px rgba(27, 22, 66, .06);
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #1b1642
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: .9
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 0 solid #eaf2fe;
    border-top-left-radius: calc(1rem 0 1rem 0 - 0);
    border-top-right-radius: calc(1rem 0 1rem 0 - 0)
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.6
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 3rem
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 2.75rem;
    border-top: 0 solid #eaf2fe;
    border-bottom-right-radius: calc(1rem 0 1rem 0 - 0);
    border-bottom-left-radius: calc(1rem 0 1rem 0 - 0)
}

.modal-footer>* {
    margin: .25rem
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}

@media (min-width:576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto
    }

    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem)
    }

    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem)
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem)
    }

    .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem);
        height: -webkit-min-content;
        height: -moz-min-content;
        height: min-content
    }

    .modal-content {
        box-shadow: 0 0 24px rgba(27, 22, 66, .04), 0 44px 74px rgba(27, 22, 66, .06)
    }

    .modal-sm {
        max-width: 420px
    }
}

@media (min-width:992px) {

    .modal-lg,
    .modal-xl {
        max-width: 800px
    }
}

@media (min-width:1200px) {
    .modal-xl {
        max-width: 1140px
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 1rem;
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: 1
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem
}

.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-auto[x-placement^=top],
.bs-tooltip-top {
    padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=top] .arrow,
.bs-tooltip-top .arrow {
    bottom: 0
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
    top: 0;
    border-width: .4rem .4rem 0;
    border-top-color: #1b1642
}

.bs-tooltip-auto[x-placement^=right],
.bs-tooltip-right {
    padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=right] .arrow,
.bs-tooltip-right .arrow {
    left: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
    right: 0;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #1b1642
}

.bs-tooltip-auto[x-placement^=bottom],
.bs-tooltip-bottom {
    padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow,
.bs-tooltip-bottom .arrow {
    top: 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
    bottom: 0;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #1b1642
}

.bs-tooltip-auto[x-placement^=left],
.bs-tooltip-left {
    padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=left] .arrow,
.bs-tooltip-left .arrow {
    right: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
    left: 0;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #1b1642
}

.tooltip-inner {
    max-width: 300px;
    padding: .5rem 1rem;
    color: #fff;
    text-align: center;
    background-color: #1b1642;
    border-radius: .375rem
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 1.125rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(27, 22, 66, .2);
    border-radius: .5rem;
    box-shadow: 0 0 24px rgba(27, 22, 66, .04), 0 44px 74px rgba(27, 22, 66, .06)
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: .5rem;
    margin: 0 .5rem
}

.popover .arrow::after,
.popover .arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-popover-auto[x-placement^=top],
.bs-popover-top {
    margin-bottom: .5rem
}

.bs-popover-auto[x-placement^=top]>.arrow,
.bs-popover-top>.arrow {
    bottom: -.5rem
}

.bs-popover-auto[x-placement^=top]>.arrow::before,
.bs-popover-top>.arrow::before {
    bottom: 0;
    border-width: .5rem .5rem 0;
    border-top-color: rgba(27, 22, 66, .25)
}

.bs-popover-auto[x-placement^=top]>.arrow::after,
.bs-popover-top>.arrow::after {
    bottom: 0;
    border-width: .5rem .5rem 0;
    border-top-color: #fff
}

.bs-popover-auto[x-placement^=right],
.bs-popover-right {
    margin-left: .5rem
}

.bs-popover-auto[x-placement^=right]>.arrow,
.bs-popover-right>.arrow {
    left: -.5rem;
    width: .5rem;
    height: 1rem;
    margin: .5rem 0
}

.bs-popover-auto[x-placement^=right]>.arrow::before,
.bs-popover-right>.arrow::before {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: rgba(27, 22, 66, .25)
}

.bs-popover-auto[x-placement^=right]>.arrow::after,
.bs-popover-right>.arrow::after {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #fff
}

.bs-popover-auto[x-placement^=bottom],
.bs-popover-bottom {
    margin-top: .5rem
}

.bs-popover-auto[x-placement^=bottom]>.arrow,
.bs-popover-bottom>.arrow {
    top: -.5rem
}

.bs-popover-auto[x-placement^=bottom]>.arrow::before,
.bs-popover-bottom>.arrow::before {
    top: 0;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: rgba(27, 22, 66, .25)
}

.bs-popover-auto[x-placement^=bottom]>.arrow::after,
.bs-popover-bottom>.arrow::after {
    top: 0;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: #fff
}

.bs-popover-auto[x-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -.5rem;
    content: "";
    border-bottom: 0 solid #f7f7f7
}

.bs-popover-auto[x-placement^=left],
.bs-popover-left {
    margin-right: .5rem
}

.bs-popover-auto[x-placement^=left]>.arrow,
.bs-popover-left>.arrow {
    right: -.5rem;
    width: .5rem;
    height: 1rem;
    margin: .5rem 0
}

.bs-popover-auto[x-placement^=left]>.arrow::before,
.bs-popover-left>.arrow::before {
    right: 0;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: rgba(27, 22, 66, .25)
}

.bs-popover-auto[x-placement^=left]>.arrow::after,
.bs-popover-left>.arrow::after {
    right: 0;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #fff
}

.popover-header {
    padding: .5rem .75rem;
    margin-bottom: 0;
    font-size: 1.125rem;
    background-color: #f7f7f7;
    border-bottom: 0 solid #ebebeb;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem
}

.popover-header:empty {
    display: none
}

.popover-body {
    padding: 1.5rem 1.5rem;
    color: #1b1642
}

.carousel {
    position: relative
}

.carousel.pointer-event {
    touch-action: pan-y
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .carousel-item {
        transition: none
    }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
    display: block
}

.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
    transform: translateX(100%)
}

.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s .6s
}

@media (prefers-reduced-motion:reduce) {

    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
        transition: none
    }
}

.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: .5;
    transition: opacity .15s ease
}

@media (prefers-reduced-motion:reduce) {

    .carousel-control-next,
    .carousel-control-prev {
        transition: none
    }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50%/100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease
}

@media (prefers-reduced-motion:reduce) {
    .carousel-indicators li {
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center
}

@-webkit-keyframes spinner-border {
    to {
        transform: rotate(360deg)
    }
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg)
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: .2em
}

@-webkit-keyframes spinner-grow {
    0% {
        transform: scale(0)
    }

    50% {
        opacity: 1;
        transform: none
    }
}

@keyframes spinner-grow {
    0% {
        transform: scale(0)
    }

    50% {
        opacity: 1;
        transform: none
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow .75s linear infinite;
    animation: spinner-grow .75s linear infinite
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.bg-primary {
    background-color: #6658ea !important
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
    background-color: #3c2be4 !important
}

.bg-secondary {
    background-color: #575a7b !important
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
    background-color: #42445d !important
}

.bg-success {
    background-color: #2cd4a2 !important
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
    background-color: #23aa82 !important
}

.bg-info {
    background-color: #17a2b8 !important
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
    background-color: #117a8b !important
}

.bg-warning {
    background-color: #f5c070 !important
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
    background-color: #f2ab40 !important
}

.bg-danger {
    background-color: #f9655b !important
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
    background-color: #f7372a !important
}

.bg-light {
    background-color: #f3f8ff !important
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
    background-color: #c0daff !important
}

.bg-dark {
    background-color: #1b1642 !important
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
    background-color: #0b091c !important
}

.bg-primary-dark {
    background-color: #564caf !important
}

a.bg-primary-dark:focus,
a.bg-primary-dark:hover,
button.bg-primary-dark:focus,
button.bg-primary-dark:hover {
    background-color: #453d8b !important
}

.bg-primary-light {
    background-color: #687ded !important
}

a.bg-primary-light:focus,
a.bg-primary-light:hover,
button.bg-primary-light:focus,
button.bg-primary-light:hover {
    background-color: #3a56e8 !important
}

.bg-white {
    background-color: #fff !important
}

.bg-transparent {
    background-color: transparent !important
}

.border {
    border: 1px solid #eaf2fe !important
}

.border-top {
    border-top: 1px solid #eaf2fe !important
}

.border-right {
    border-right: 1px solid #eaf2fe !important
}

.border-bottom {
    border-bottom: 1px solid #eaf2fe !important
}

.border-left {
    border-left: 1px solid #eaf2fe !important
}

.border-0 {
    border: 0 !important
}

.border-top-0 {
    border-top: 0 !important
}

.border-right-0 {
    border-right: 0 !important
}

.border-bottom-0 {
    border-bottom: 0 !important
}

.border-left-0 {
    border-left: 0 !important
}

.border-primary {
    border-color: #6658ea !important
}

.border-secondary {
    border-color: #575a7b !important
}

.border-success {
    border-color: #2cd4a2 !important
}

.border-info {
    border-color: #17a2b8 !important
}

.border-warning {
    border-color: #f5c070 !important
}

.border-danger {
    border-color: #f9655b !important
}

.border-light {
    border-color: #f3f8ff !important
}

.border-dark {
    border-color: #1b1642 !important
}

.border-primary-dark {
    border-color: #564caf !important
}

.border-primary-light {
    border-color: #687ded !important
}

.border-white {
    border-color: #fff !important
}

.rounded-sm {
    border-radius: .5rem !important
}

.rounded {
    border-radius: 1rem !important
}

.rounded-top {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important
}

.rounded-right {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important
}

.rounded-bottom {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important
}

.rounded-left {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important
}

.rounded-lg {
    border-radius: 2rem !important
}

.rounded-circle {
    border-radius: 50% !important
}

.rounded-pill {
    border-radius: 50rem !important
}

.rounded-0 {
    border-radius: 0 !important
}

.clearfix::after {
    display: block;
    clear: both;
    content: ""
}

.d-none {
    display: none !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: flex !important
}

.d-inline-flex {
    display: inline-flex !important
}

@media (min-width:576px) {
    .d-sm-none {
        display: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: flex !important
    }

    .d-sm-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width:768px) {
    .d-md-none {
        display: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: flex !important
    }

    .d-md-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width:992px) {
    .d-lg-none {
        display: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: flex !important
    }

    .d-lg-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width:1200px) {
    .d-xl-none {
        display: none !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: flex !important
    }

    .d-xl-inline-flex {
        display: inline-flex !important
    }
}

@media print {
    .d-print-none {
        display: none !important
    }

    .d-print-inline {
        display: inline !important
    }

    .d-print-inline-block {
        display: inline-block !important
    }

    .d-print-block {
        display: block !important
    }

    .d-print-table {
        display: table !important
    }

    .d-print-table-row {
        display: table-row !important
    }

    .d-print-table-cell {
        display: table-cell !important
    }

    .d-print-flex {
        display: flex !important
    }

    .d-print-inline-flex {
        display: inline-flex !important
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden
}

.embed-responsive::before {
    display: block;
    content: ""
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0
}

.embed-responsive-21by9::before {
    padding-top: 42.85714%
}

.embed-responsive-16by9::before {
    padding-top: 56.25%
}

.embed-responsive-4by3::before {
    padding-top: 75%
}

.embed-responsive-1by1::before {
    padding-top: 100%
}

.flex-row {
    flex-direction: row !important
}

.flex-column {
    flex-direction: column !important
}

.flex-row-reverse {
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    flex-direction: column-reverse !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.flex-nowrap {
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.flex-fill {
    flex: 1 1 auto !important
}

.flex-grow-0 {
    flex-grow: 0 !important
}

.flex-grow-1 {
    flex-grow: 1 !important
}

.flex-shrink-0 {
    flex-shrink: 0 !important
}

.flex-shrink-1 {
    flex-shrink: 1 !important
}

.justify-content-start {
    justify-content: flex-start !important
}

.justify-content-end {
    justify-content: flex-end !important
}

.justify-content-center {
    justify-content: center !important
}

.justify-content-between {
    justify-content: space-between !important
}

.justify-content-around {
    justify-content: space-around !important
}

.align-items-start {
    align-items: flex-start !important
}

.align-items-end {
    align-items: flex-end !important
}

.align-items-center {
    align-items: center !important
}

.align-items-baseline {
    align-items: baseline !important
}

.align-items-stretch {
    align-items: stretch !important
}

.align-content-start {
    align-content: flex-start !important
}

.align-content-end {
    align-content: flex-end !important
}

.align-content-center {
    align-content: center !important
}

.align-content-between {
    align-content: space-between !important
}

.align-content-around {
    align-content: space-around !important
}

.align-content-stretch {
    align-content: stretch !important
}

.align-self-auto {
    align-self: auto !important
}

.align-self-start {
    align-self: flex-start !important
}

.align-self-end {
    align-self: flex-end !important
}

.align-self-center {
    align-self: center !important
}

.align-self-baseline {
    align-self: baseline !important
}

.align-self-stretch {
    align-self: stretch !important
}

@media (min-width:576px) {
    .flex-sm-row {
        flex-direction: row !important
    }

    .flex-sm-column {
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-sm-fill {
        flex: 1 1 auto !important
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-sm-start {
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        justify-content: center !important
    }

    .justify-content-sm-between {
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        justify-content: space-around !important
    }

    .align-items-sm-start {
        align-items: flex-start !important
    }

    .align-items-sm-end {
        align-items: flex-end !important
    }

    .align-items-sm-center {
        align-items: center !important
    }

    .align-items-sm-baseline {
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        align-items: stretch !important
    }

    .align-content-sm-start {
        align-content: flex-start !important
    }

    .align-content-sm-end {
        align-content: flex-end !important
    }

    .align-content-sm-center {
        align-content: center !important
    }

    .align-content-sm-between {
        align-content: space-between !important
    }

    .align-content-sm-around {
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        align-content: stretch !important
    }

    .align-self-sm-auto {
        align-self: auto !important
    }

    .align-self-sm-start {
        align-self: flex-start !important
    }

    .align-self-sm-end {
        align-self: flex-end !important
    }

    .align-self-sm-center {
        align-self: center !important
    }

    .align-self-sm-baseline {
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        align-self: stretch !important
    }
}

@media (min-width:768px) {
    .flex-md-row {
        flex-direction: row !important
    }

    .flex-md-column {
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-md-wrap {
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-md-fill {
        flex: 1 1 auto !important
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-md-start {
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        justify-content: center !important
    }

    .justify-content-md-between {
        justify-content: space-between !important
    }

    .justify-content-md-around {
        justify-content: space-around !important
    }

    .align-items-md-start {
        align-items: flex-start !important
    }

    .align-items-md-end {
        align-items: flex-end !important
    }

    .align-items-md-center {
        align-items: center !important
    }

    .align-items-md-baseline {
        align-items: baseline !important
    }

    .align-items-md-stretch {
        align-items: stretch !important
    }

    .align-content-md-start {
        align-content: flex-start !important
    }

    .align-content-md-end {
        align-content: flex-end !important
    }

    .align-content-md-center {
        align-content: center !important
    }

    .align-content-md-between {
        align-content: space-between !important
    }

    .align-content-md-around {
        align-content: space-around !important
    }

    .align-content-md-stretch {
        align-content: stretch !important
    }

    .align-self-md-auto {
        align-self: auto !important
    }

    .align-self-md-start {
        align-self: flex-start !important
    }

    .align-self-md-end {
        align-self: flex-end !important
    }

    .align-self-md-center {
        align-self: center !important
    }

    .align-self-md-baseline {
        align-self: baseline !important
    }

    .align-self-md-stretch {
        align-self: stretch !important
    }
}

@media (min-width:992px) {
    .flex-lg-row {
        flex-direction: row !important
    }

    .flex-lg-column {
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-lg-fill {
        flex: 1 1 auto !important
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-lg-start {
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        justify-content: center !important
    }

    .justify-content-lg-between {
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        justify-content: space-around !important
    }

    .align-items-lg-start {
        align-items: flex-start !important
    }

    .align-items-lg-end {
        align-items: flex-end !important
    }

    .align-items-lg-center {
        align-items: center !important
    }

    .align-items-lg-baseline {
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        align-items: stretch !important
    }

    .align-content-lg-start {
        align-content: flex-start !important
    }

    .align-content-lg-end {
        align-content: flex-end !important
    }

    .align-content-lg-center {
        align-content: center !important
    }

    .align-content-lg-between {
        align-content: space-between !important
    }

    .align-content-lg-around {
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        align-content: stretch !important
    }

    .align-self-lg-auto {
        align-self: auto !important
    }

    .align-self-lg-start {
        align-self: flex-start !important
    }

    .align-self-lg-end {
        align-self: flex-end !important
    }

    .align-self-lg-center {
        align-self: center !important
    }

    .align-self-lg-baseline {
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        align-self: stretch !important
    }
}

@media (min-width:1200px) {
    .flex-xl-row {
        flex-direction: row !important
    }

    .flex-xl-column {
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-xl-fill {
        flex: 1 1 auto !important
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-xl-start {
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        justify-content: center !important
    }

    .justify-content-xl-between {
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        justify-content: space-around !important
    }

    .align-items-xl-start {
        align-items: flex-start !important
    }

    .align-items-xl-end {
        align-items: flex-end !important
    }

    .align-items-xl-center {
        align-items: center !important
    }

    .align-items-xl-baseline {
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        align-items: stretch !important
    }

    .align-content-xl-start {
        align-content: flex-start !important
    }

    .align-content-xl-end {
        align-content: flex-end !important
    }

    .align-content-xl-center {
        align-content: center !important
    }

    .align-content-xl-between {
        align-content: space-between !important
    }

    .align-content-xl-around {
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        align-content: stretch !important
    }

    .align-self-xl-auto {
        align-self: auto !important
    }

    .align-self-xl-start {
        align-self: flex-start !important
    }

    .align-self-xl-end {
        align-self: flex-end !important
    }

    .align-self-xl-center {
        align-self: center !important
    }

    .align-self-xl-baseline {
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        align-self: stretch !important
    }
}

.float-left {
    float: left !important
}

.float-right {
    float: right !important
}

.float-none {
    float: none !important
}

@media (min-width:576px) {
    .float-sm-left {
        float: left !important
    }

    .float-sm-right {
        float: right !important
    }

    .float-sm-none {
        float: none !important
    }
}

@media (min-width:768px) {
    .float-md-left {
        float: left !important
    }

    .float-md-right {
        float: right !important
    }

    .float-md-none {
        float: none !important
    }
}

@media (min-width:992px) {
    .float-lg-left {
        float: left !important
    }

    .float-lg-right {
        float: right !important
    }

    .float-lg-none {
        float: none !important
    }
}

@media (min-width:1200px) {
    .float-xl-left {
        float: left !important
    }

    .float-xl-right {
        float: right !important
    }

    .float-xl-none {
        float: none !important
    }
}

.user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    -ms-user-select: all !important;
    user-select: all !important
}

.user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important
}

.user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important
}

.overflow-auto {
    overflow: auto !important
}

.overflow-hidden {
    overflow: hidden !important
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(27, 22, 66, .075) !important
}

.shadow {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06) !important
}

.shadow-lg {
    box-shadow: 0 0 24px rgba(27, 22, 66, .04), 0 44px 74px rgba(27, 22, 66, .06) !important
}

.shadow-none {
    box-shadow: none !important
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}

.w-125 {
    width: 125% !important
}

.w-150 {
    width: 150% !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.h-auto {
    height: auto !important
}

.h-125 {
    height: 125% !important
}

.h-150 {
    height: 150% !important
}

.mw-100 {
    max-width: 100% !important
}

.mh-100 {
    max-height: 100% !important
}

.min-vw-100 {
    min-width: 100vw !important
}

.min-vh-100 {
    min-height: 100vh !important
}

.vw-100 {
    width: 100vw !important
}

.vh-100 {
    height: 100vh !important
}

.m-0 {
    margin: 0 !important
}

.mt-0,
.my-0 {
    margin-top: 0 !important
}

.mr-0,
.mx-0 {
    margin-right: 0 !important
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important
}

.ml-0,
.mx-0 {
    margin-left: 0 !important
}

.m-1 {
    margin: .25rem !important
}

.mt-1,
.my-1 {
    margin-top: .25rem !important
}

.mr-1,
.mx-1 {
    margin-right: .25rem !important
}

.mb-1,
.my-1 {
    margin-bottom: .25rem !important
}

.ml-1,
.mx-1 {
    margin-left: .25rem !important
}

.m-2 {
    margin: .5rem !important
}

.mt-2,
.my-2 {
    margin-top: .5rem !important
}

.mr-2,
.mx-2 {
    margin-right: .5rem !important
}

.mb-2,
.my-2 {
    margin-bottom: .5rem !important
}

.ml-2,
.mx-2 {
    margin-left: .5rem !important
}

.m-3 {
    margin: .75rem !important
}

.mt-3,
.my-3 {
    margin-top: .75rem !important
}

.mr-3,
.mx-3 {
    margin-right: .75rem !important
}

.mb-3,
.my-3 {
    margin-bottom: .75rem !important
}

.ml-3,
.mx-3 {
    margin-left: .75rem !important
}

.m-4 {
    margin: 1rem !important
}

.mt-4,
.my-4 {
    margin-top: 1rem !important
}

.mr-4,
.mx-4 {
    margin-right: 1rem !important
}

.mb-4,
.my-4 {
    margin-bottom: 1rem !important
}

.ml-4,
.mx-4 {
    margin-left: 1rem !important
}

.m-5 {
    margin: 1.5rem !important
}

.mt-5,
.my-5 {
    margin-top: 1.5rem !important
}

.mr-5,
.mx-5 {
    margin-right: 1.5rem !important
}

.mb-5,
.my-5 {
    margin-bottom: 1.5rem !important
}

.ml-5,
.mx-5 {
    margin-left: 1.5rem !important
}

.m-6 {
    margin: 2rem !important
}

.mt-6,
.my-6 {
    margin-top: 2rem !important
}

.mr-6,
.mx-6 {
    margin-right: 2rem !important
}

.mb-6,
.my-6 {
    margin-bottom: 2rem !important
}

.ml-6,
.mx-6 {
    margin-left: 2rem !important
}

.m-7 {
    margin: 2.5rem !important
}

.mt-7,
.my-7 {
    margin-top: 2.5rem !important
}

.mr-7,
.mx-7 {
    margin-right: 2.5rem !important
}

.mb-7,
.my-7 {
    margin-bottom: 2.5rem !important
}

.ml-7,
.mx-7 {
    margin-left: 2.5rem !important
}

.m-8 {
    margin: 3rem !important
}

.mt-8,
.my-8 {
    margin-top: 3rem !important
}

.mr-8,
.mx-8 {
    margin-right: 3rem !important
}

.mb-8,
.my-8 {
    margin-bottom: 3rem !important
}

.ml-8,
.mx-8 {
    margin-left: 3rem !important
}

.m-9 {
    margin: 4rem !important
}

.mt-9,
.my-9 {
    margin-top: 4rem !important
}

.mr-9,
.mx-9 {
    margin-right: 4rem !important
}

.mb-9,
.my-9 {
    margin-bottom: 4rem !important
}

.ml-9,
.mx-9 {
    margin-left: 4rem !important
}

.m-10 {
    margin: 5rem !important
}

.mt-10,
.my-10 {
    margin-top: 5rem !important
}

.mr-10,
.mx-10 {
    margin-right: 5rem !important
}

.mb-10,
.my-10 {
    margin-bottom: 5rem !important
}

.ml-10,
.mx-10 {
    margin-left: 5rem !important
}

.m-11 {
    margin: 6rem !important
}

.mt-11,
.my-11 {
    margin-top: 6rem !important
}

.mr-11,
.mx-11 {
    margin-right: 6rem !important
}

.mb-11,
.my-11 {
    margin-bottom: 6rem !important
}

.ml-11,
.mx-11 {
    margin-left: 6rem !important
}

.m-12 {
    margin: 8rem !important
}

.mt-12,
.my-12 {
    margin-top: 8rem !important
}

.mr-12,
.mx-12 {
    margin-right: 8rem !important
}

.mb-12,
.my-12 {
    margin-bottom: 8rem !important
}

.ml-12,
.mx-12 {
    margin-left: 8rem !important
}

.m-13 {
    margin: 10rem !important
}

.mt-13,
.my-13 {
    margin-top: 10rem !important
}

.mr-13,
.mx-13 {
    margin-right: 10rem !important
}

.mb-13,
.my-13 {
    margin-bottom: 10rem !important
}

.ml-13,
.mx-13 {
    margin-left: 10rem !important
}

.m-14 {
    margin: 12rem !important
}

.mt-14,
.my-14 {
    margin-top: 12rem !important
}

.mr-14,
.mx-14 {
    margin-right: 12rem !important
}

.mb-14,
.my-14 {
    margin-bottom: 12rem !important
}

.ml-14,
.mx-14 {
    margin-left: 12rem !important
}

.m-15 {
    margin: 14rem !important
}

.mt-15,
.my-15 {
    margin-top: 14rem !important
}

.mr-15,
.mx-15 {
    margin-right: 14rem !important
}

.mb-15,
.my-15 {
    margin-bottom: 14rem !important
}

.ml-15,
.mx-15 {
    margin-left: 14rem !important
}

.m-16 {
    margin: 16rem !important
}

.mt-16,
.my-16 {
    margin-top: 16rem !important
}

.mr-16,
.mx-16 {
    margin-right: 16rem !important
}

.mb-16,
.my-16 {
    margin-bottom: 16rem !important
}

.ml-16,
.mx-16 {
    margin-left: 16rem !important
}

.m-17 {
    margin: 20rem !important
}

.mt-17,
.my-17 {
    margin-top: 20rem !important
}

.mr-17,
.mx-17 {
    margin-right: 20rem !important
}

.mb-17,
.my-17 {
    margin-bottom: 20rem !important
}

.ml-17,
.mx-17 {
    margin-left: 20rem !important
}

.m-18 {
    margin: 24rem !important
}

.mt-18,
.my-18 {
    margin-top: 24rem !important
}

.mr-18,
.mx-18 {
    margin-right: 24rem !important
}

.mb-18,
.my-18 {
    margin-bottom: 24rem !important
}

.ml-18,
.mx-18 {
    margin-left: 24rem !important
}

.p-0 {
    padding: 0 !important
}

.pt-0,
.py-0 {
    padding-top: 0 !important
}

.pr-0,
.px-0 {
    padding-right: 0 !important
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important
}

.pl-0,
.px-0 {
    padding-left: 0 !important
}

.p-1 {
    padding: .25rem !important
}

.pt-1,
.py-1 {
    padding-top: .25rem !important
}

.pr-1,
.px-1 {
    padding-right: .25rem !important
}

.pb-1,
.py-1 {
    padding-bottom: .25rem !important
}

.pl-1,
.px-1 {
    padding-left: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.pt-2,
.py-2 {
    padding-top: .5rem !important
}

.pr-2,
.px-2 {
    padding-right: .5rem !important
}

.pb-2,
.py-2 {
    padding-bottom: .5rem !important
}

.pl-2,
.px-2 {
    padding-left: .5rem !important
}

.p-3 {
    padding: .75rem !important
}

.pt-3,
.py-3 {
    padding-top: .75rem !important
}

.pr-3,
.px-3 {
    padding-right: .75rem !important
}

.pb-3,
.py-3 {
    padding-bottom: .75rem !important
}

.pl-3,
.px-3 {
    padding-left: .75rem !important
}

.p-4 {
    padding: 1rem !important
}

.pt-4,
.py-4 {
    padding-top: 1rem !important
}

.pr-4,
.px-4 {
    padding-right: 1rem !important
}

.pb-4,
.py-4 {
    padding-bottom: 1rem !important
}

.pl-4,
.px-4 {
    padding-left: 1rem !important
}

.p-5 {
    padding: 1.5rem !important
}

.pt-5,
.py-5 {
    padding-top: 1.5rem !important
}

.pr-5,
.px-5 {
    padding-right: 1.5rem !important
}

.pb-5,
.py-5 {
    padding-bottom: 1.5rem !important
}

.pl-5,
.px-5 {
    padding-left: 1.5rem !important
}

.p-6 {
    padding: 2rem !important
}

.pt-6,
.py-6 {
    padding-top: 2rem !important
}

.pr-6,
.px-6 {
    padding-right: 2rem !important
}

.pb-6,
.py-6 {
    padding-bottom: 2rem !important
}

.pl-6,
.px-6 {
    padding-left: 2rem !important
}

.p-7 {
    padding: 2.5rem !important
}

.pt-7,
.py-7 {
    padding-top: 2.5rem !important
}

.pr-7,
.px-7 {
    padding-right: 2.5rem !important
}

.pb-7,
.py-7 {
    padding-bottom: 2.5rem !important
}

.pl-7,
.px-7 {
    padding-left: 2.5rem !important
}

.p-8 {
    padding: 3rem !important
}

.pt-8,
.py-8 {
    padding-top: 3rem !important
}

.pr-8,
.px-8 {
    padding-right: 3rem !important
}

.pb-8,
.py-8 {
    padding-bottom: 3rem !important
}

.pl-8,
.px-8 {
    padding-left: 3rem !important
}

.p-9 {
    padding: 4rem !important
}

.pt-9,
.py-9 {
    padding-top: 4rem !important
}

.pr-9,
.px-9 {
    padding-right: 4rem !important
}

.pb-9,
.py-9 {
    padding-bottom: 4rem !important
}

.pl-9,
.px-9 {
    padding-left: 4rem !important
}

.p-10 {
    padding: 5rem !important
}

.pt-10,
.py-10 {
    padding-top: 5rem !important
}

.pr-10,
.px-10 {
    padding-right: 5rem !important
}

.pb-10,
.py-10 {
    padding-bottom: 5rem !important
}

.pl-10,
.px-10 {
    padding-left: 5rem !important
}

.p-11 {
    padding: 6rem !important
}

.pt-11,
.py-11 {
    padding-top: 6rem !important
}

.pr-11,
.px-11 {
    padding-right: 6rem !important
}

.pb-11,
.py-11 {
    padding-bottom: 6rem !important
}

.pl-11,
.px-11 {
    padding-left: 6rem !important
}

.p-12 {
    padding: 8rem !important
}

.pt-12,
.py-12 {
    padding-top: 8rem !important
}

.pr-12,
.px-12 {
    padding-right: 8rem !important
}

.pb-12,
.py-12 {
    padding-bottom: 8rem !important
}

.pl-12,
.px-12 {
    padding-left: 8rem !important
}

.p-13 {
    padding: 10rem !important
}

.pt-13,
.py-13 {
    padding-top: 10rem !important
}

.pr-13,
.px-13 {
    padding-right: 10rem !important
}

.pb-13,
.py-13 {
    padding-bottom: 10rem !important
}

.pl-13,
.px-13 {
    padding-left: 10rem !important
}

.p-14 {
    padding: 12rem !important
}

.pt-14,
.py-14 {
    padding-top: 12rem !important
}

.pr-14,
.px-14 {
    padding-right: 12rem !important
}

.pb-14,
.py-14 {
    padding-bottom: 12rem !important
}

.pl-14,
.px-14 {
    padding-left: 12rem !important
}

.p-15 {
    padding: 14rem !important
}

.pt-15,
.py-15 {
    padding-top: 14rem !important
}

.pr-15,
.px-15 {
    padding-right: 14rem !important
}

.pb-15,
.py-15 {
    padding-bottom: 14rem !important
}

.pl-15,
.px-15 {
    padding-left: 14rem !important
}

.p-16 {
    padding: 16rem !important
}

.pt-16,
.py-16 {
    padding-top: 16rem !important
}

.pr-16,
.px-16 {
    padding-right: 16rem !important
}

.pb-16,
.py-16 {
    padding-bottom: 16rem !important
}

.pl-16,
.px-16 {
    padding-left: 16rem !important
}

.p-17 {
    padding: 20rem !important
}

.pt-17,
.py-17 {
    padding-top: 20rem !important
}

.pr-17,
.px-17 {
    padding-right: 20rem !important
}

.pb-17,
.py-17 {
    padding-bottom: 20rem !important
}

.pl-17,
.px-17 {
    padding-left: 20rem !important
}

.p-18 {
    padding: 24rem !important
}

.pt-18,
.py-18 {
    padding-top: 24rem !important
}

.pr-18,
.px-18 {
    padding-right: 24rem !important
}

.pb-18,
.py-18 {
    padding-bottom: 24rem !important
}

.pl-18,
.px-18 {
    padding-left: 24rem !important
}

.m-n1 {
    margin: -.25rem !important
}

.mt-n1,
.my-n1 {
    margin-top: -.25rem !important
}

.mr-n1,
.mx-n1 {
    margin-right: -.25rem !important
}

.mb-n1,
.my-n1 {
    margin-bottom: -.25rem !important
}

.ml-n1,
.mx-n1 {
    margin-left: -.25rem !important
}

.m-n2 {
    margin: -.5rem !important
}

.mt-n2,
.my-n2 {
    margin-top: -.5rem !important
}

.mr-n2,
.mx-n2 {
    margin-right: -.5rem !important
}

.mb-n2,
.my-n2 {
    margin-bottom: -.5rem !important
}

.ml-n2,
.mx-n2 {
    margin-left: -.5rem !important
}

.m-n3 {
    margin: -.75rem !important
}

.mt-n3,
.my-n3 {
    margin-top: -.75rem !important
}

.mr-n3,
.mx-n3 {
    margin-right: -.75rem !important
}

.mb-n3,
.my-n3 {
    margin-bottom: -.75rem !important
}

.ml-n3,
.mx-n3 {
    margin-left: -.75rem !important
}

.m-n4 {
    margin: -1rem !important
}

.mt-n4,
.my-n4 {
    margin-top: -1rem !important
}

.mr-n4,
.mx-n4 {
    margin-right: -1rem !important
}

.mb-n4,
.my-n4 {
    margin-bottom: -1rem !important
}

.ml-n4,
.mx-n4 {
    margin-left: -1rem !important
}

.m-n5 {
    margin: -1.5rem !important
}

.mt-n5,
.my-n5 {
    margin-top: -1.5rem !important
}

.mr-n5,
.mx-n5 {
    margin-right: -1.5rem !important
}

.mb-n5,
.my-n5 {
    margin-bottom: -1.5rem !important
}

.ml-n5,
.mx-n5 {
    margin-left: -1.5rem !important
}

.m-n6 {
    margin: -2rem !important
}

.mt-n6,
.my-n6 {
    margin-top: -2rem !important
}

.mr-n6,
.mx-n6 {
    margin-right: -2rem !important
}

.mb-n6,
.my-n6 {
    margin-bottom: -2rem !important
}

.ml-n6,
.mx-n6 {
    margin-left: -2rem !important
}

.m-n7 {
    margin: -2.5rem !important
}

.mt-n7,
.my-n7 {
    margin-top: -2.5rem !important
}

.mr-n7,
.mx-n7 {
    margin-right: -2.5rem !important
}

.mb-n7,
.my-n7 {
    margin-bottom: -2.5rem !important
}

.ml-n7,
.mx-n7 {
    margin-left: -2.5rem !important
}

.m-n8 {
    margin: -3rem !important
}

.mt-n8,
.my-n8 {
    margin-top: -3rem !important
}

.mr-n8,
.mx-n8 {
    margin-right: -3rem !important
}

.mb-n8,
.my-n8 {
    margin-bottom: -3rem !important
}

.ml-n8,
.mx-n8 {
    margin-left: -3rem !important
}

.m-n9 {
    margin: -4rem !important
}

.mt-n9,
.my-n9 {
    margin-top: -4rem !important
}

.mr-n9,
.mx-n9 {
    margin-right: -4rem !important
}

.mb-n9,
.my-n9 {
    margin-bottom: -4rem !important
}

.ml-n9,
.mx-n9 {
    margin-left: -4rem !important
}

.m-n10 {
    margin: -5rem !important
}

.mt-n10,
.my-n10 {
    margin-top: -5rem !important
}

.mr-n10,
.mx-n10 {
    margin-right: -5rem !important
}

.mb-n10,
.my-n10 {
    margin-bottom: -5rem !important
}

.ml-n10,
.mx-n10 {
    margin-left: -5rem !important
}

.m-n11 {
    margin: -6rem !important
}

.mt-n11,
.my-n11 {
    margin-top: -6rem !important
}

.mr-n11,
.mx-n11 {
    margin-right: -6rem !important
}

.mb-n11,
.my-n11 {
    margin-bottom: -6rem !important
}

.ml-n11,
.mx-n11 {
    margin-left: -6rem !important
}

.m-n12 {
    margin: -8rem !important
}

.mt-n12,
.my-n12 {
    margin-top: -8rem !important
}

.mr-n12,
.mx-n12 {
    margin-right: -8rem !important
}

.mb-n12,
.my-n12 {
    margin-bottom: -8rem !important
}

.ml-n12,
.mx-n12 {
    margin-left: -8rem !important
}

.m-n13 {
    margin: -10rem !important
}

.mt-n13,
.my-n13 {
    margin-top: -10rem !important
}

.mr-n13,
.mx-n13 {
    margin-right: -10rem !important
}

.mb-n13,
.my-n13 {
    margin-bottom: -10rem !important
}

.ml-n13,
.mx-n13 {
    margin-left: -10rem !important
}

.m-n14 {
    margin: -12rem !important
}

.mt-n14,
.my-n14 {
    margin-top: -12rem !important
}

.mr-n14,
.mx-n14 {
    margin-right: -12rem !important
}

.mb-n14,
.my-n14 {
    margin-bottom: -12rem !important
}

.ml-n14,
.mx-n14 {
    margin-left: -12rem !important
}

.m-n15 {
    margin: -14rem !important
}

.mt-n15,
.my-n15 {
    margin-top: -14rem !important
}

.mr-n15,
.mx-n15 {
    margin-right: -14rem !important
}

.mb-n15,
.my-n15 {
    margin-bottom: -14rem !important
}

.ml-n15,
.mx-n15 {
    margin-left: -14rem !important
}

.m-n16 {
    margin: -16rem !important
}

.mt-n16,
.my-n16 {
    margin-top: -16rem !important
}

.mr-n16,
.mx-n16 {
    margin-right: -16rem !important
}

.mb-n16,
.my-n16 {
    margin-bottom: -16rem !important
}

.ml-n16,
.mx-n16 {
    margin-left: -16rem !important
}

.m-n17 {
    margin: -20rem !important
}

.mt-n17,
.my-n17 {
    margin-top: -20rem !important
}

.mr-n17,
.mx-n17 {
    margin-right: -20rem !important
}

.mb-n17,
.my-n17 {
    margin-bottom: -20rem !important
}

.ml-n17,
.mx-n17 {
    margin-left: -20rem !important
}

.m-n18 {
    margin: -24rem !important
}

.mt-n18,
.my-n18 {
    margin-top: -24rem !important
}

.mr-n18,
.mx-n18 {
    margin-right: -24rem !important
}

.mb-n18,
.my-n18 {
    margin-bottom: -24rem !important
}

.ml-n18,
.mx-n18 {
    margin-left: -24rem !important
}

.m-auto {
    margin: auto !important
}

.mt-auto,
.my-auto {
    margin-top: auto !important
}

.mr-auto,
.mx-auto {
    margin-right: auto !important
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important
}

.ml-auto,
.mx-auto {
    margin-left: auto !important
}

@media (min-width:576px) {
    .m-sm-0 {
        margin: 0 !important
    }

    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important
    }

    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important
    }

    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important
    }

    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important
    }

    .m-sm-1 {
        margin: .25rem !important
    }

    .mt-sm-1,
    .my-sm-1 {
        margin-top: .25rem !important
    }

    .mr-sm-1,
    .mx-sm-1 {
        margin-right: .25rem !important
    }

    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: .25rem !important
    }

    .ml-sm-1,
    .mx-sm-1 {
        margin-left: .25rem !important
    }

    .m-sm-2 {
        margin: .5rem !important
    }

    .mt-sm-2,
    .my-sm-2 {
        margin-top: .5rem !important
    }

    .mr-sm-2,
    .mx-sm-2 {
        margin-right: .5rem !important
    }

    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: .5rem !important
    }

    .ml-sm-2,
    .mx-sm-2 {
        margin-left: .5rem !important
    }

    .m-sm-3 {
        margin: .75rem !important
    }

    .mt-sm-3,
    .my-sm-3 {
        margin-top: .75rem !important
    }

    .mr-sm-3,
    .mx-sm-3 {
        margin-right: .75rem !important
    }

    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: .75rem !important
    }

    .ml-sm-3,
    .mx-sm-3 {
        margin-left: .75rem !important
    }

    .m-sm-4 {
        margin: 1rem !important
    }

    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1rem !important
    }

    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1rem !important
    }

    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1rem !important
    }

    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1rem !important
    }

    .m-sm-5 {
        margin: 1.5rem !important
    }

    .mt-sm-5,
    .my-sm-5 {
        margin-top: 1.5rem !important
    }

    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 1.5rem !important
    }

    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 1.5rem !important
    }

    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 1.5rem !important
    }

    .m-sm-6 {
        margin: 2rem !important
    }

    .mt-sm-6,
    .my-sm-6 {
        margin-top: 2rem !important
    }

    .mr-sm-6,
    .mx-sm-6 {
        margin-right: 2rem !important
    }

    .mb-sm-6,
    .my-sm-6 {
        margin-bottom: 2rem !important
    }

    .ml-sm-6,
    .mx-sm-6 {
        margin-left: 2rem !important
    }

    .m-sm-7 {
        margin: 2.5rem !important
    }

    .mt-sm-7,
    .my-sm-7 {
        margin-top: 2.5rem !important
    }

    .mr-sm-7,
    .mx-sm-7 {
        margin-right: 2.5rem !important
    }

    .mb-sm-7,
    .my-sm-7 {
        margin-bottom: 2.5rem !important
    }

    .ml-sm-7,
    .mx-sm-7 {
        margin-left: 2.5rem !important
    }

    .m-sm-8 {
        margin: 3rem !important
    }

    .mt-sm-8,
    .my-sm-8 {
        margin-top: 3rem !important
    }

    .mr-sm-8,
    .mx-sm-8 {
        margin-right: 3rem !important
    }

    .mb-sm-8,
    .my-sm-8 {
        margin-bottom: 3rem !important
    }

    .ml-sm-8,
    .mx-sm-8 {
        margin-left: 3rem !important
    }

    .m-sm-9 {
        margin: 4rem !important
    }

    .mt-sm-9,
    .my-sm-9 {
        margin-top: 4rem !important
    }

    .mr-sm-9,
    .mx-sm-9 {
        margin-right: 4rem !important
    }

    .mb-sm-9,
    .my-sm-9 {
        margin-bottom: 4rem !important
    }

    .ml-sm-9,
    .mx-sm-9 {
        margin-left: 4rem !important
    }

    .m-sm-10 {
        margin: 5rem !important
    }

    .mt-sm-10,
    .my-sm-10 {
        margin-top: 5rem !important
    }

    .mr-sm-10,
    .mx-sm-10 {
        margin-right: 5rem !important
    }

    .mb-sm-10,
    .my-sm-10 {
        margin-bottom: 5rem !important
    }

    .ml-sm-10,
    .mx-sm-10 {
        margin-left: 5rem !important
    }

    .m-sm-11 {
        margin: 6rem !important
    }

    .mt-sm-11,
    .my-sm-11 {
        margin-top: 6rem !important
    }

    .mr-sm-11,
    .mx-sm-11 {
        margin-right: 6rem !important
    }

    .mb-sm-11,
    .my-sm-11 {
        margin-bottom: 6rem !important
    }

    .ml-sm-11,
    .mx-sm-11 {
        margin-left: 6rem !important
    }

    .m-sm-12 {
        margin: 8rem !important
    }

    .mt-sm-12,
    .my-sm-12 {
        margin-top: 8rem !important
    }

    .mr-sm-12,
    .mx-sm-12 {
        margin-right: 8rem !important
    }

    .mb-sm-12,
    .my-sm-12 {
        margin-bottom: 8rem !important
    }

    .ml-sm-12,
    .mx-sm-12 {
        margin-left: 8rem !important
    }

    .m-sm-13 {
        margin: 10rem !important
    }

    .mt-sm-13,
    .my-sm-13 {
        margin-top: 10rem !important
    }

    .mr-sm-13,
    .mx-sm-13 {
        margin-right: 10rem !important
    }

    .mb-sm-13,
    .my-sm-13 {
        margin-bottom: 10rem !important
    }

    .ml-sm-13,
    .mx-sm-13 {
        margin-left: 10rem !important
    }

    .m-sm-14 {
        margin: 12rem !important
    }

    .mt-sm-14,
    .my-sm-14 {
        margin-top: 12rem !important
    }

    .mr-sm-14,
    .mx-sm-14 {
        margin-right: 12rem !important
    }

    .mb-sm-14,
    .my-sm-14 {
        margin-bottom: 12rem !important
    }

    .ml-sm-14,
    .mx-sm-14 {
        margin-left: 12rem !important
    }

    .m-sm-15 {
        margin: 14rem !important
    }

    .mt-sm-15,
    .my-sm-15 {
        margin-top: 14rem !important
    }

    .mr-sm-15,
    .mx-sm-15 {
        margin-right: 14rem !important
    }

    .mb-sm-15,
    .my-sm-15 {
        margin-bottom: 14rem !important
    }

    .ml-sm-15,
    .mx-sm-15 {
        margin-left: 14rem !important
    }

    .m-sm-16 {
        margin: 16rem !important
    }

    .mt-sm-16,
    .my-sm-16 {
        margin-top: 16rem !important
    }

    .mr-sm-16,
    .mx-sm-16 {
        margin-right: 16rem !important
    }

    .mb-sm-16,
    .my-sm-16 {
        margin-bottom: 16rem !important
    }

    .ml-sm-16,
    .mx-sm-16 {
        margin-left: 16rem !important
    }

    .m-sm-17 {
        margin: 20rem !important
    }

    .mt-sm-17,
    .my-sm-17 {
        margin-top: 20rem !important
    }

    .mr-sm-17,
    .mx-sm-17 {
        margin-right: 20rem !important
    }

    .mb-sm-17,
    .my-sm-17 {
        margin-bottom: 20rem !important
    }

    .ml-sm-17,
    .mx-sm-17 {
        margin-left: 20rem !important
    }

    .m-sm-18 {
        margin: 24rem !important
    }

    .mt-sm-18,
    .my-sm-18 {
        margin-top: 24rem !important
    }

    .mr-sm-18,
    .mx-sm-18 {
        margin-right: 24rem !important
    }

    .mb-sm-18,
    .my-sm-18 {
        margin-bottom: 24rem !important
    }

    .ml-sm-18,
    .mx-sm-18 {
        margin-left: 24rem !important
    }

    .p-sm-0 {
        padding: 0 !important
    }

    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important
    }

    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important
    }

    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important
    }

    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important
    }

    .p-sm-1 {
        padding: .25rem !important
    }

    .pt-sm-1,
    .py-sm-1 {
        padding-top: .25rem !important
    }

    .pr-sm-1,
    .px-sm-1 {
        padding-right: .25rem !important
    }

    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: .25rem !important
    }

    .pl-sm-1,
    .px-sm-1 {
        padding-left: .25rem !important
    }

    .p-sm-2 {
        padding: .5rem !important
    }

    .pt-sm-2,
    .py-sm-2 {
        padding-top: .5rem !important
    }

    .pr-sm-2,
    .px-sm-2 {
        padding-right: .5rem !important
    }

    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: .5rem !important
    }

    .pl-sm-2,
    .px-sm-2 {
        padding-left: .5rem !important
    }

    .p-sm-3 {
        padding: .75rem !important
    }

    .pt-sm-3,
    .py-sm-3 {
        padding-top: .75rem !important
    }

    .pr-sm-3,
    .px-sm-3 {
        padding-right: .75rem !important
    }

    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: .75rem !important
    }

    .pl-sm-3,
    .px-sm-3 {
        padding-left: .75rem !important
    }

    .p-sm-4 {
        padding: 1rem !important
    }

    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1rem !important
    }

    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1rem !important
    }

    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1rem !important
    }

    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1rem !important
    }

    .p-sm-5 {
        padding: 1.5rem !important
    }

    .pt-sm-5,
    .py-sm-5 {
        padding-top: 1.5rem !important
    }

    .pr-sm-5,
    .px-sm-5 {
        padding-right: 1.5rem !important
    }

    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 1.5rem !important
    }

    .pl-sm-5,
    .px-sm-5 {
        padding-left: 1.5rem !important
    }

    .p-sm-6 {
        padding: 2rem !important
    }

    .pt-sm-6,
    .py-sm-6 {
        padding-top: 2rem !important
    }

    .pr-sm-6,
    .px-sm-6 {
        padding-right: 2rem !important
    }

    .pb-sm-6,
    .py-sm-6 {
        padding-bottom: 2rem !important
    }

    .pl-sm-6,
    .px-sm-6 {
        padding-left: 2rem !important
    }

    .p-sm-7 {
        padding: 2.5rem !important
    }

    .pt-sm-7,
    .py-sm-7 {
        padding-top: 2.5rem !important
    }

    .pr-sm-7,
    .px-sm-7 {
        padding-right: 2.5rem !important
    }

    .pb-sm-7,
    .py-sm-7 {
        padding-bottom: 2.5rem !important
    }

    .pl-sm-7,
    .px-sm-7 {
        padding-left: 2.5rem !important
    }

    .p-sm-8 {
        padding: 3rem !important
    }

    .pt-sm-8,
    .py-sm-8 {
        padding-top: 3rem !important
    }

    .pr-sm-8,
    .px-sm-8 {
        padding-right: 3rem !important
    }

    .pb-sm-8,
    .py-sm-8 {
        padding-bottom: 3rem !important
    }

    .pl-sm-8,
    .px-sm-8 {
        padding-left: 3rem !important
    }

    .p-sm-9 {
        padding: 4rem !important
    }

    .pt-sm-9,
    .py-sm-9 {
        padding-top: 4rem !important
    }

    .pr-sm-9,
    .px-sm-9 {
        padding-right: 4rem !important
    }

    .pb-sm-9,
    .py-sm-9 {
        padding-bottom: 4rem !important
    }

    .pl-sm-9,
    .px-sm-9 {
        padding-left: 4rem !important
    }

    .p-sm-10 {
        padding: 5rem !important
    }

    .pt-sm-10,
    .py-sm-10 {
        padding-top: 5rem !important
    }

    .pr-sm-10,
    .px-sm-10 {
        padding-right: 5rem !important
    }

    .pb-sm-10,
    .py-sm-10 {
        padding-bottom: 5rem !important
    }

    .pl-sm-10,
    .px-sm-10 {
        padding-left: 5rem !important
    }

    .p-sm-11 {
        padding: 6rem !important
    }

    .pt-sm-11,
    .py-sm-11 {
        padding-top: 6rem !important
    }

    .pr-sm-11,
    .px-sm-11 {
        padding-right: 6rem !important
    }

    .pb-sm-11,
    .py-sm-11 {
        padding-bottom: 6rem !important
    }

    .pl-sm-11,
    .px-sm-11 {
        padding-left: 6rem !important
    }

    .p-sm-12 {
        padding: 8rem !important
    }

    .pt-sm-12,
    .py-sm-12 {
        padding-top: 8rem !important
    }

    .pr-sm-12,
    .px-sm-12 {
        padding-right: 8rem !important
    }

    .pb-sm-12,
    .py-sm-12 {
        padding-bottom: 8rem !important
    }

    .pl-sm-12,
    .px-sm-12 {
        padding-left: 8rem !important
    }

    .p-sm-13 {
        padding: 10rem !important
    }

    .pt-sm-13,
    .py-sm-13 {
        padding-top: 10rem !important
    }

    .pr-sm-13,
    .px-sm-13 {
        padding-right: 10rem !important
    }

    .pb-sm-13,
    .py-sm-13 {
        padding-bottom: 10rem !important
    }

    .pl-sm-13,
    .px-sm-13 {
        padding-left: 10rem !important
    }

    .p-sm-14 {
        padding: 12rem !important
    }

    .pt-sm-14,
    .py-sm-14 {
        padding-top: 12rem !important
    }

    .pr-sm-14,
    .px-sm-14 {
        padding-right: 12rem !important
    }

    .pb-sm-14,
    .py-sm-14 {
        padding-bottom: 12rem !important
    }

    .pl-sm-14,
    .px-sm-14 {
        padding-left: 12rem !important
    }

    .p-sm-15 {
        padding: 14rem !important
    }

    .pt-sm-15,
    .py-sm-15 {
        padding-top: 14rem !important
    }

    .pr-sm-15,
    .px-sm-15 {
        padding-right: 14rem !important
    }

    .pb-sm-15,
    .py-sm-15 {
        padding-bottom: 14rem !important
    }

    .pl-sm-15,
    .px-sm-15 {
        padding-left: 14rem !important
    }

    .p-sm-16 {
        padding: 16rem !important
    }

    .pt-sm-16,
    .py-sm-16 {
        padding-top: 16rem !important
    }

    .pr-sm-16,
    .px-sm-16 {
        padding-right: 16rem !important
    }

    .pb-sm-16,
    .py-sm-16 {
        padding-bottom: 16rem !important
    }

    .pl-sm-16,
    .px-sm-16 {
        padding-left: 16rem !important
    }

    .p-sm-17 {
        padding: 20rem !important
    }

    .pt-sm-17,
    .py-sm-17 {
        padding-top: 20rem !important
    }

    .pr-sm-17,
    .px-sm-17 {
        padding-right: 20rem !important
    }

    .pb-sm-17,
    .py-sm-17 {
        padding-bottom: 20rem !important
    }

    .pl-sm-17,
    .px-sm-17 {
        padding-left: 20rem !important
    }

    .p-sm-18 {
        padding: 24rem !important
    }

    .pt-sm-18,
    .py-sm-18 {
        padding-top: 24rem !important
    }

    .pr-sm-18,
    .px-sm-18 {
        padding-right: 24rem !important
    }

    .pb-sm-18,
    .py-sm-18 {
        padding-bottom: 24rem !important
    }

    .pl-sm-18,
    .px-sm-18 {
        padding-left: 24rem !important
    }

    .m-sm-n1 {
        margin: -.25rem !important
    }

    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -.25rem !important
    }

    .mr-sm-n1,
    .mx-sm-n1 {
        margin-right: -.25rem !important
    }

    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-sm-n1,
    .mx-sm-n1 {
        margin-left: -.25rem !important
    }

    .m-sm-n2 {
        margin: -.5rem !important
    }

    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -.5rem !important
    }

    .mr-sm-n2,
    .mx-sm-n2 {
        margin-right: -.5rem !important
    }

    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-sm-n2,
    .mx-sm-n2 {
        margin-left: -.5rem !important
    }

    .m-sm-n3 {
        margin: -.75rem !important
    }

    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -.75rem !important
    }

    .mr-sm-n3,
    .mx-sm-n3 {
        margin-right: -.75rem !important
    }

    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -.75rem !important
    }

    .ml-sm-n3,
    .mx-sm-n3 {
        margin-left: -.75rem !important
    }

    .m-sm-n4 {
        margin: -1rem !important
    }

    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1rem !important
    }

    .mr-sm-n4,
    .mx-sm-n4 {
        margin-right: -1rem !important
    }

    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1rem !important
    }

    .ml-sm-n4,
    .mx-sm-n4 {
        margin-left: -1rem !important
    }

    .m-sm-n5 {
        margin: -1.5rem !important
    }

    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -1.5rem !important
    }

    .mr-sm-n5,
    .mx-sm-n5 {
        margin-right: -1.5rem !important
    }

    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -1.5rem !important
    }

    .ml-sm-n5,
    .mx-sm-n5 {
        margin-left: -1.5rem !important
    }

    .m-sm-n6 {
        margin: -2rem !important
    }

    .mt-sm-n6,
    .my-sm-n6 {
        margin-top: -2rem !important
    }

    .mr-sm-n6,
    .mx-sm-n6 {
        margin-right: -2rem !important
    }

    .mb-sm-n6,
    .my-sm-n6 {
        margin-bottom: -2rem !important
    }

    .ml-sm-n6,
    .mx-sm-n6 {
        margin-left: -2rem !important
    }

    .m-sm-n7 {
        margin: -2.5rem !important
    }

    .mt-sm-n7,
    .my-sm-n7 {
        margin-top: -2.5rem !important
    }

    .mr-sm-n7,
    .mx-sm-n7 {
        margin-right: -2.5rem !important
    }

    .mb-sm-n7,
    .my-sm-n7 {
        margin-bottom: -2.5rem !important
    }

    .ml-sm-n7,
    .mx-sm-n7 {
        margin-left: -2.5rem !important
    }

    .m-sm-n8 {
        margin: -3rem !important
    }

    .mt-sm-n8,
    .my-sm-n8 {
        margin-top: -3rem !important
    }

    .mr-sm-n8,
    .mx-sm-n8 {
        margin-right: -3rem !important
    }

    .mb-sm-n8,
    .my-sm-n8 {
        margin-bottom: -3rem !important
    }

    .ml-sm-n8,
    .mx-sm-n8 {
        margin-left: -3rem !important
    }

    .m-sm-n9 {
        margin: -4rem !important
    }

    .mt-sm-n9,
    .my-sm-n9 {
        margin-top: -4rem !important
    }

    .mr-sm-n9,
    .mx-sm-n9 {
        margin-right: -4rem !important
    }

    .mb-sm-n9,
    .my-sm-n9 {
        margin-bottom: -4rem !important
    }

    .ml-sm-n9,
    .mx-sm-n9 {
        margin-left: -4rem !important
    }

    .m-sm-n10 {
        margin: -5rem !important
    }

    .mt-sm-n10,
    .my-sm-n10 {
        margin-top: -5rem !important
    }

    .mr-sm-n10,
    .mx-sm-n10 {
        margin-right: -5rem !important
    }

    .mb-sm-n10,
    .my-sm-n10 {
        margin-bottom: -5rem !important
    }

    .ml-sm-n10,
    .mx-sm-n10 {
        margin-left: -5rem !important
    }

    .m-sm-n11 {
        margin: -6rem !important
    }

    .mt-sm-n11,
    .my-sm-n11 {
        margin-top: -6rem !important
    }

    .mr-sm-n11,
    .mx-sm-n11 {
        margin-right: -6rem !important
    }

    .mb-sm-n11,
    .my-sm-n11 {
        margin-bottom: -6rem !important
    }

    .ml-sm-n11,
    .mx-sm-n11 {
        margin-left: -6rem !important
    }

    .m-sm-n12 {
        margin: -8rem !important
    }

    .mt-sm-n12,
    .my-sm-n12 {
        margin-top: -8rem !important
    }

    .mr-sm-n12,
    .mx-sm-n12 {
        margin-right: -8rem !important
    }

    .mb-sm-n12,
    .my-sm-n12 {
        margin-bottom: -8rem !important
    }

    .ml-sm-n12,
    .mx-sm-n12 {
        margin-left: -8rem !important
    }

    .m-sm-n13 {
        margin: -10rem !important
    }

    .mt-sm-n13,
    .my-sm-n13 {
        margin-top: -10rem !important
    }

    .mr-sm-n13,
    .mx-sm-n13 {
        margin-right: -10rem !important
    }

    .mb-sm-n13,
    .my-sm-n13 {
        margin-bottom: -10rem !important
    }

    .ml-sm-n13,
    .mx-sm-n13 {
        margin-left: -10rem !important
    }

    .m-sm-n14 {
        margin: -12rem !important
    }

    .mt-sm-n14,
    .my-sm-n14 {
        margin-top: -12rem !important
    }

    .mr-sm-n14,
    .mx-sm-n14 {
        margin-right: -12rem !important
    }

    .mb-sm-n14,
    .my-sm-n14 {
        margin-bottom: -12rem !important
    }

    .ml-sm-n14,
    .mx-sm-n14 {
        margin-left: -12rem !important
    }

    .m-sm-n15 {
        margin: -14rem !important
    }

    .mt-sm-n15,
    .my-sm-n15 {
        margin-top: -14rem !important
    }

    .mr-sm-n15,
    .mx-sm-n15 {
        margin-right: -14rem !important
    }

    .mb-sm-n15,
    .my-sm-n15 {
        margin-bottom: -14rem !important
    }

    .ml-sm-n15,
    .mx-sm-n15 {
        margin-left: -14rem !important
    }

    .m-sm-n16 {
        margin: -16rem !important
    }

    .mt-sm-n16,
    .my-sm-n16 {
        margin-top: -16rem !important
    }

    .mr-sm-n16,
    .mx-sm-n16 {
        margin-right: -16rem !important
    }

    .mb-sm-n16,
    .my-sm-n16 {
        margin-bottom: -16rem !important
    }

    .ml-sm-n16,
    .mx-sm-n16 {
        margin-left: -16rem !important
    }

    .m-sm-n17 {
        margin: -20rem !important
    }

    .mt-sm-n17,
    .my-sm-n17 {
        margin-top: -20rem !important
    }

    .mr-sm-n17,
    .mx-sm-n17 {
        margin-right: -20rem !important
    }

    .mb-sm-n17,
    .my-sm-n17 {
        margin-bottom: -20rem !important
    }

    .ml-sm-n17,
    .mx-sm-n17 {
        margin-left: -20rem !important
    }

    .m-sm-n18 {
        margin: -24rem !important
    }

    .mt-sm-n18,
    .my-sm-n18 {
        margin-top: -24rem !important
    }

    .mr-sm-n18,
    .mx-sm-n18 {
        margin-right: -24rem !important
    }

    .mb-sm-n18,
    .my-sm-n18 {
        margin-bottom: -24rem !important
    }

    .ml-sm-n18,
    .mx-sm-n18 {
        margin-left: -24rem !important
    }

    .m-sm-auto {
        margin: auto !important
    }

    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important
    }

    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important
    }

    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important
    }

    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important
    }
}

@media (min-width:768px) {
    .m-md-0 {
        margin: 0 !important
    }

    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important
    }

    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important
    }

    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important
    }

    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important
    }

    .m-md-1 {
        margin: .25rem !important
    }

    .mt-md-1,
    .my-md-1 {
        margin-top: .25rem !important
    }

    .mr-md-1,
    .mx-md-1 {
        margin-right: .25rem !important
    }

    .mb-md-1,
    .my-md-1 {
        margin-bottom: .25rem !important
    }

    .ml-md-1,
    .mx-md-1 {
        margin-left: .25rem !important
    }

    .m-md-2 {
        margin: .5rem !important
    }

    .mt-md-2,
    .my-md-2 {
        margin-top: .5rem !important
    }

    .mr-md-2,
    .mx-md-2 {
        margin-right: .5rem !important
    }

    .mb-md-2,
    .my-md-2 {
        margin-bottom: .5rem !important
    }

    .ml-md-2,
    .mx-md-2 {
        margin-left: .5rem !important
    }

    .m-md-3 {
        margin: .75rem !important
    }

    .mt-md-3,
    .my-md-3 {
        margin-top: .75rem !important
    }

    .mr-md-3,
    .mx-md-3 {
        margin-right: .75rem !important
    }

    .mb-md-3,
    .my-md-3 {
        margin-bottom: .75rem !important
    }

    .ml-md-3,
    .mx-md-3 {
        margin-left: .75rem !important
    }

    .m-md-4 {
        margin: 1rem !important
    }

    .mt-md-4,
    .my-md-4 {
        margin-top: 1rem !important
    }

    .mr-md-4,
    .mx-md-4 {
        margin-right: 1rem !important
    }

    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1rem !important
    }

    .ml-md-4,
    .mx-md-4 {
        margin-left: 1rem !important
    }

    .m-md-5 {
        margin: 1.5rem !important
    }

    .mt-md-5,
    .my-md-5 {
        margin-top: 1.5rem !important
    }

    .mr-md-5,
    .mx-md-5 {
        margin-right: 1.5rem !important
    }

    .mb-md-5,
    .my-md-5 {
        margin-bottom: 1.5rem !important
    }

    .ml-md-5,
    .mx-md-5 {
        margin-left: 1.5rem !important
    }

    .m-md-6 {
        margin: 2rem !important
    }

    .mt-md-6,
    .my-md-6 {
        margin-top: 2rem !important
    }

    .mr-md-6,
    .mx-md-6 {
        margin-right: 2rem !important
    }

    .mb-md-6,
    .my-md-6 {
        margin-bottom: 2rem !important
    }

    .ml-md-6,
    .mx-md-6 {
        margin-left: 2rem !important
    }

    .m-md-7 {
        margin: 2.5rem !important
    }

    .mt-md-7,
    .my-md-7 {
        margin-top: 2.5rem !important
    }

    .mr-md-7,
    .mx-md-7 {
        margin-right: 2.5rem !important
    }

    .mb-md-7,
    .my-md-7 {
        margin-bottom: 2.5rem !important
    }

    .ml-md-7,
    .mx-md-7 {
        margin-left: 2.5rem !important
    }

    .m-md-8 {
        margin: 3rem !important
    }

    .mt-md-8,
    .my-md-8 {
        margin-top: 3rem !important
    }

    .mr-md-8,
    .mx-md-8 {
        margin-right: 3rem !important
    }

    .mb-md-8,
    .my-md-8 {
        margin-bottom: 3rem !important
    }

    .ml-md-8,
    .mx-md-8 {
        margin-left: 3rem !important
    }

    .m-md-9 {
        margin: 4rem !important
    }

    .mt-md-9,
    .my-md-9 {
        margin-top: 4rem !important
    }

    .mr-md-9,
    .mx-md-9 {
        margin-right: 4rem !important
    }

    .mb-md-9,
    .my-md-9 {
        margin-bottom: 4rem !important
    }

    .ml-md-9,
    .mx-md-9 {
        margin-left: 4rem !important
    }

    .m-md-10 {
        margin: 5rem !important
    }

    .mt-md-10,
    .my-md-10 {
        margin-top: 5rem !important
    }

    .mr-md-10,
    .mx-md-10 {
        margin-right: 5rem !important
    }

    .mb-md-10,
    .my-md-10 {
        margin-bottom: 5rem !important
    }

    .ml-md-10,
    .mx-md-10 {
        margin-left: 5rem !important
    }

    .m-md-11 {
        margin: 6rem !important
    }

    .mt-md-11,
    .my-md-11 {
        margin-top: 6rem !important
    }

    .mr-md-11,
    .mx-md-11 {
        margin-right: 6rem !important
    }

    .mb-md-11,
    .my-md-11 {
        margin-bottom: 6rem !important
    }

    .ml-md-11,
    .mx-md-11 {
        margin-left: 6rem !important
    }

    .m-md-12 {
        margin: 8rem !important
    }

    .mt-md-12,
    .my-md-12 {
        margin-top: 8rem !important
    }

    .mr-md-12,
    .mx-md-12 {
        margin-right: 8rem !important
    }

    .mb-md-12,
    .my-md-12 {
        margin-bottom: 8rem !important
    }

    .ml-md-12,
    .mx-md-12 {
        margin-left: 8rem !important
    }

    .m-md-13 {
        margin: 10rem !important
    }

    .mt-md-13,
    .my-md-13 {
        margin-top: 10rem !important
    }

    .mr-md-13,
    .mx-md-13 {
        margin-right: 10rem !important
    }

    .mb-md-13,
    .my-md-13 {
        margin-bottom: 10rem !important
    }

    .ml-md-13,
    .mx-md-13 {
        margin-left: 10rem !important
    }

    .m-md-14 {
        margin: 12rem !important
    }

    .mt-md-14,
    .my-md-14 {
        margin-top: 12rem !important
    }

    .mr-md-14,
    .mx-md-14 {
        margin-right: 12rem !important
    }

    .mb-md-14,
    .my-md-14 {
        margin-bottom: 12rem !important
    }

    .ml-md-14,
    .mx-md-14 {
        margin-left: 12rem !important
    }

    .m-md-15 {
        margin: 14rem !important
    }

    .mt-md-15,
    .my-md-15 {
        margin-top: 14rem !important
    }

    .mr-md-15,
    .mx-md-15 {
        margin-right: 14rem !important
    }

    .mb-md-15,
    .my-md-15 {
        margin-bottom: 14rem !important
    }

    .ml-md-15,
    .mx-md-15 {
        margin-left: 14rem !important
    }

    .m-md-16 {
        margin: 16rem !important
    }

    .mt-md-16,
    .my-md-16 {
        margin-top: 16rem !important
    }

    .mr-md-16,
    .mx-md-16 {
        margin-right: 16rem !important
    }

    .mb-md-16,
    .my-md-16 {
        margin-bottom: 16rem !important
    }

    .ml-md-16,
    .mx-md-16 {
        margin-left: 16rem !important
    }

    .m-md-17 {
        margin: 20rem !important
    }

    .mt-md-17,
    .my-md-17 {
        margin-top: 20rem !important
    }

    .mr-md-17,
    .mx-md-17 {
        margin-right: 20rem !important
    }

    .mb-md-17,
    .my-md-17 {
        margin-bottom: 20rem !important
    }

    .ml-md-17,
    .mx-md-17 {
        margin-left: 20rem !important
    }

    .m-md-18 {
        margin: 24rem !important
    }

    .mt-md-18,
    .my-md-18 {
        margin-top: 24rem !important
    }

    .mr-md-18,
    .mx-md-18 {
        margin-right: 24rem !important
    }

    .mb-md-18,
    .my-md-18 {
        margin-bottom: 24rem !important
    }

    .ml-md-18,
    .mx-md-18 {
        margin-left: 24rem !important
    }

    .p-md-0 {
        padding: 0 !important
    }

    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important
    }

    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important
    }

    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important
    }

    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important
    }

    .p-md-1 {
        padding: .25rem !important
    }

    .pt-md-1,
    .py-md-1 {
        padding-top: .25rem !important
    }

    .pr-md-1,
    .px-md-1 {
        padding-right: .25rem !important
    }

    .pb-md-1,
    .py-md-1 {
        padding-bottom: .25rem !important
    }

    .pl-md-1,
    .px-md-1 {
        padding-left: .25rem !important
    }

    .p-md-2 {
        padding: .5rem !important
    }

    .pt-md-2,
    .py-md-2 {
        padding-top: .5rem !important
    }

    .pr-md-2,
    .px-md-2 {
        padding-right: .5rem !important
    }

    .pb-md-2,
    .py-md-2 {
        padding-bottom: .5rem !important
    }

    .pl-md-2,
    .px-md-2 {
        padding-left: .5rem !important
    }

    .p-md-3 {
        padding: .75rem !important
    }

    .pt-md-3,
    .py-md-3 {
        padding-top: .75rem !important
    }

    .pr-md-3,
    .px-md-3 {
        padding-right: .75rem !important
    }

    .pb-md-3,
    .py-md-3 {
        padding-bottom: .75rem !important
    }

    .pl-md-3,
    .px-md-3 {
        padding-left: .75rem !important
    }

    .p-md-4 {
        padding: 1rem !important
    }

    .pt-md-4,
    .py-md-4 {
        padding-top: 1rem !important
    }

    .pr-md-4,
    .px-md-4 {
        padding-right: 1rem !important
    }

    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1rem !important
    }

    .pl-md-4,
    .px-md-4 {
        padding-left: 1rem !important
    }

    .p-md-5 {
        padding: 1.5rem !important
    }

    .pt-md-5,
    .py-md-5 {
        padding-top: 1.5rem !important
    }

    .pr-md-5,
    .px-md-5 {
        padding-right: 1.5rem !important
    }

    .pb-md-5,
    .py-md-5 {
        padding-bottom: 1.5rem !important
    }

    .pl-md-5,
    .px-md-5 {
        padding-left: 1.5rem !important
    }

    .p-md-6 {
        padding: 2rem !important
    }

    .pt-md-6,
    .py-md-6 {
        padding-top: 2rem !important
    }

    .pr-md-6,
    .px-md-6 {
        padding-right: 2rem !important
    }

    .pb-md-6,
    .py-md-6 {
        padding-bottom: 2rem !important
    }

    .pl-md-6,
    .px-md-6 {
        padding-left: 2rem !important
    }

    .p-md-7 {
        padding: 2.5rem !important
    }

    .pt-md-7,
    .py-md-7 {
        padding-top: 2.5rem !important
    }

    .pr-md-7,
    .px-md-7 {
        padding-right: 2.5rem !important
    }

    .pb-md-7,
    .py-md-7 {
        padding-bottom: 2.5rem !important
    }

    .pl-md-7,
    .px-md-7 {
        padding-left: 2.5rem !important
    }

    .p-md-8 {
        padding: 3rem !important
    }

    .pt-md-8,
    .py-md-8 {
        padding-top: 3rem !important
    }

    .pr-md-8,
    .px-md-8 {
        padding-right: 3rem !important
    }

    .pb-md-8,
    .py-md-8 {
        padding-bottom: 3rem !important
    }

    .pl-md-8,
    .px-md-8 {
        padding-left: 3rem !important
    }

    .p-md-9 {
        padding: 4rem !important
    }

    .pt-md-9,
    .py-md-9 {
        padding-top: 4rem !important
    }

    .pr-md-9,
    .px-md-9 {
        padding-right: 4rem !important
    }

    .pb-md-9,
    .py-md-9 {
        padding-bottom: 4rem !important
    }

    .pl-md-9,
    .px-md-9 {
        padding-left: 4rem !important
    }

    .p-md-10 {
        padding: 5rem !important
    }

    .pt-md-10,
    .py-md-10 {
        padding-top: 5rem !important
    }

    .pr-md-10,
    .px-md-10 {
        padding-right: 5rem !important
    }

    .pb-md-10,
    .py-md-10 {
        padding-bottom: 5rem !important
    }

    .pl-md-10,
    .px-md-10 {
        padding-left: 5rem !important
    }

    .p-md-11 {
        padding: 6rem !important
    }

    .pt-md-11,
    .py-md-11 {
        padding-top: 6rem !important
    }

    .pr-md-11,
    .px-md-11 {
        padding-right: 6rem !important
    }

    .pb-md-11,
    .py-md-11 {
        padding-bottom: 6rem !important
    }

    .pl-md-11,
    .px-md-11 {
        padding-left: 6rem !important
    }

    .p-md-12 {
        padding: 8rem !important
    }

    .pt-md-12,
    .py-md-12 {
        padding-top: 8rem !important
    }

    .pr-md-12,
    .px-md-12 {
        padding-right: 8rem !important
    }

    .pb-md-12,
    .py-md-12 {
        padding-bottom: 8rem !important
    }

    .pl-md-12,
    .px-md-12 {
        padding-left: 8rem !important
    }

    .p-md-13 {
        padding: 10rem !important
    }

    .pt-md-13,
    .py-md-13 {
        padding-top: 10rem !important
    }

    .pr-md-13,
    .px-md-13 {
        padding-right: 10rem !important
    }

    .pb-md-13,
    .py-md-13 {
        padding-bottom: 10rem !important
    }

    .pl-md-13,
    .px-md-13 {
        padding-left: 10rem !important
    }

    .p-md-14 {
        padding: 12rem !important
    }

    .pt-md-14,
    .py-md-14 {
        padding-top: 12rem !important
    }

    .pr-md-14,
    .px-md-14 {
        padding-right: 12rem !important
    }

    .pb-md-14,
    .py-md-14 {
        padding-bottom: 12rem !important
    }

    .pl-md-14,
    .px-md-14 {
        padding-left: 12rem !important
    }

    .p-md-15 {
        padding: 14rem !important
    }

    .pt-md-15,
    .py-md-15 {
        padding-top: 14rem !important
    }

    .pr-md-15,
    .px-md-15 {
        padding-right: 14rem !important
    }

    .pb-md-15,
    .py-md-15 {
        padding-bottom: 14rem !important
    }

    .pl-md-15,
    .px-md-15 {
        padding-left: 14rem !important
    }

    .p-md-16 {
        padding: 16rem !important
    }

    .pt-md-16,
    .py-md-16 {
        padding-top: 16rem !important
    }

    .pr-md-16,
    .px-md-16 {
        padding-right: 16rem !important
    }

    .pb-md-16,
    .py-md-16 {
        padding-bottom: 16rem !important
    }

    .pl-md-16,
    .px-md-16 {
        padding-left: 16rem !important
    }

    .p-md-17 {
        padding: 20rem !important
    }

    .pt-md-17,
    .py-md-17 {
        padding-top: 20rem !important
    }

    .pr-md-17,
    .px-md-17 {
        padding-right: 20rem !important
    }

    .pb-md-17,
    .py-md-17 {
        padding-bottom: 20rem !important
    }

    .pl-md-17,
    .px-md-17 {
        padding-left: 20rem !important
    }

    .p-md-18 {
        padding: 24rem !important
    }

    .pt-md-18,
    .py-md-18 {
        padding-top: 24rem !important
    }

    .pr-md-18,
    .px-md-18 {
        padding-right: 24rem !important
    }

    .pb-md-18,
    .py-md-18 {
        padding-bottom: 24rem !important
    }

    .pl-md-18,
    .px-md-18 {
        padding-left: 24rem !important
    }

    .m-md-n1 {
        margin: -.25rem !important
    }

    .mt-md-n1,
    .my-md-n1 {
        margin-top: -.25rem !important
    }

    .mr-md-n1,
    .mx-md-n1 {
        margin-right: -.25rem !important
    }

    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-md-n1,
    .mx-md-n1 {
        margin-left: -.25rem !important
    }

    .m-md-n2 {
        margin: -.5rem !important
    }

    .mt-md-n2,
    .my-md-n2 {
        margin-top: -.5rem !important
    }

    .mr-md-n2,
    .mx-md-n2 {
        margin-right: -.5rem !important
    }

    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-md-n2,
    .mx-md-n2 {
        margin-left: -.5rem !important
    }

    .m-md-n3 {
        margin: -.75rem !important
    }

    .mt-md-n3,
    .my-md-n3 {
        margin-top: -.75rem !important
    }

    .mr-md-n3,
    .mx-md-n3 {
        margin-right: -.75rem !important
    }

    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -.75rem !important
    }

    .ml-md-n3,
    .mx-md-n3 {
        margin-left: -.75rem !important
    }

    .m-md-n4 {
        margin: -1rem !important
    }

    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1rem !important
    }

    .mr-md-n4,
    .mx-md-n4 {
        margin-right: -1rem !important
    }

    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1rem !important
    }

    .ml-md-n4,
    .mx-md-n4 {
        margin-left: -1rem !important
    }

    .m-md-n5 {
        margin: -1.5rem !important
    }

    .mt-md-n5,
    .my-md-n5 {
        margin-top: -1.5rem !important
    }

    .mr-md-n5,
    .mx-md-n5 {
        margin-right: -1.5rem !important
    }

    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -1.5rem !important
    }

    .ml-md-n5,
    .mx-md-n5 {
        margin-left: -1.5rem !important
    }

    .m-md-n6 {
        margin: -2rem !important
    }

    .mt-md-n6,
    .my-md-n6 {
        margin-top: -2rem !important
    }

    .mr-md-n6,
    .mx-md-n6 {
        margin-right: -2rem !important
    }

    .mb-md-n6,
    .my-md-n6 {
        margin-bottom: -2rem !important
    }

    .ml-md-n6,
    .mx-md-n6 {
        margin-left: -2rem !important
    }

    .m-md-n7 {
        margin: -2.5rem !important
    }

    .mt-md-n7,
    .my-md-n7 {
        margin-top: -2.5rem !important
    }

    .mr-md-n7,
    .mx-md-n7 {
        margin-right: -2.5rem !important
    }

    .mb-md-n7,
    .my-md-n7 {
        margin-bottom: -2.5rem !important
    }

    .ml-md-n7,
    .mx-md-n7 {
        margin-left: -2.5rem !important
    }

    .m-md-n8 {
        margin: -3rem !important
    }

    .mt-md-n8,
    .my-md-n8 {
        margin-top: -3rem !important
    }

    .mr-md-n8,
    .mx-md-n8 {
        margin-right: -3rem !important
    }

    .mb-md-n8,
    .my-md-n8 {
        margin-bottom: -3rem !important
    }

    .ml-md-n8,
    .mx-md-n8 {
        margin-left: -3rem !important
    }

    .m-md-n9 {
        margin: -4rem !important
    }

    .mt-md-n9,
    .my-md-n9 {
        margin-top: -4rem !important
    }

    .mr-md-n9,
    .mx-md-n9 {
        margin-right: -4rem !important
    }

    .mb-md-n9,
    .my-md-n9 {
        margin-bottom: -4rem !important
    }

    .ml-md-n9,
    .mx-md-n9 {
        margin-left: -4rem !important
    }

    .m-md-n10 {
        margin: -5rem !important
    }

    .mt-md-n10,
    .my-md-n10 {
        margin-top: -5rem !important
    }

    .mr-md-n10,
    .mx-md-n10 {
        margin-right: -5rem !important
    }

    .mb-md-n10,
    .my-md-n10 {
        margin-bottom: -5rem !important
    }

    .ml-md-n10,
    .mx-md-n10 {
        margin-left: -5rem !important
    }

    .m-md-n11 {
        margin: -6rem !important
    }

    .mt-md-n11,
    .my-md-n11 {
        margin-top: -6rem !important
    }

    .mr-md-n11,
    .mx-md-n11 {
        margin-right: -6rem !important
    }

    .mb-md-n11,
    .my-md-n11 {
        margin-bottom: -6rem !important
    }

    .ml-md-n11,
    .mx-md-n11 {
        margin-left: -6rem !important
    }

    .m-md-n12 {
        margin: -8rem !important
    }

    .mt-md-n12,
    .my-md-n12 {
        margin-top: -8rem !important
    }

    .mr-md-n12,
    .mx-md-n12 {
        margin-right: -8rem !important
    }

    .mb-md-n12,
    .my-md-n12 {
        margin-bottom: -8rem !important
    }

    .ml-md-n12,
    .mx-md-n12 {
        margin-left: -8rem !important
    }

    .m-md-n13 {
        margin: -10rem !important
    }

    .mt-md-n13,
    .my-md-n13 {
        margin-top: -10rem !important
    }

    .mr-md-n13,
    .mx-md-n13 {
        margin-right: -10rem !important
    }

    .mb-md-n13,
    .my-md-n13 {
        margin-bottom: -10rem !important
    }

    .ml-md-n13,
    .mx-md-n13 {
        margin-left: -10rem !important
    }

    .m-md-n14 {
        margin: -12rem !important
    }

    .mt-md-n14,
    .my-md-n14 {
        margin-top: -12rem !important
    }

    .mr-md-n14,
    .mx-md-n14 {
        margin-right: -12rem !important
    }

    .mb-md-n14,
    .my-md-n14 {
        margin-bottom: -12rem !important
    }

    .ml-md-n14,
    .mx-md-n14 {
        margin-left: -12rem !important
    }

    .m-md-n15 {
        margin: -14rem !important
    }

    .mt-md-n15,
    .my-md-n15 {
        margin-top: -14rem !important
    }

    .mr-md-n15,
    .mx-md-n15 {
        margin-right: -14rem !important
    }

    .mb-md-n15,
    .my-md-n15 {
        margin-bottom: -14rem !important
    }

    .ml-md-n15,
    .mx-md-n15 {
        margin-left: -14rem !important
    }

    .m-md-n16 {
        margin: -16rem !important
    }

    .mt-md-n16,
    .my-md-n16 {
        margin-top: -16rem !important
    }

    .mr-md-n16,
    .mx-md-n16 {
        margin-right: -16rem !important
    }

    .mb-md-n16,
    .my-md-n16 {
        margin-bottom: -16rem !important
    }

    .ml-md-n16,
    .mx-md-n16 {
        margin-left: -16rem !important
    }

    .m-md-n17 {
        margin: -20rem !important
    }

    .mt-md-n17,
    .my-md-n17 {
        margin-top: -20rem !important
    }

    .mr-md-n17,
    .mx-md-n17 {
        margin-right: -20rem !important
    }

    .mb-md-n17,
    .my-md-n17 {
        margin-bottom: -20rem !important
    }

    .ml-md-n17,
    .mx-md-n17 {
        margin-left: -20rem !important
    }

    .m-md-n18 {
        margin: -24rem !important
    }

    .mt-md-n18,
    .my-md-n18 {
        margin-top: -24rem !important
    }

    .mr-md-n18,
    .mx-md-n18 {
        margin-right: -24rem !important
    }

    .mb-md-n18,
    .my-md-n18 {
        margin-bottom: -24rem !important
    }

    .ml-md-n18,
    .mx-md-n18 {
        margin-left: -24rem !important
    }

    .m-md-auto {
        margin: auto !important
    }

    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important
    }

    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important
    }

    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important
    }

    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important
    }
}

@media (min-width:992px) {
    .m-lg-0 {
        margin: 0 !important
    }

    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important
    }

    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important
    }

    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important
    }

    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important
    }

    .m-lg-1 {
        margin: .25rem !important
    }

    .mt-lg-1,
    .my-lg-1 {
        margin-top: .25rem !important
    }

    .mr-lg-1,
    .mx-lg-1 {
        margin-right: .25rem !important
    }

    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: .25rem !important
    }

    .ml-lg-1,
    .mx-lg-1 {
        margin-left: .25rem !important
    }

    .m-lg-2 {
        margin: .5rem !important
    }

    .mt-lg-2,
    .my-lg-2 {
        margin-top: .5rem !important
    }

    .mr-lg-2,
    .mx-lg-2 {
        margin-right: .5rem !important
    }

    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: .5rem !important
    }

    .ml-lg-2,
    .mx-lg-2 {
        margin-left: .5rem !important
    }

    .m-lg-3 {
        margin: .75rem !important
    }

    .mt-lg-3,
    .my-lg-3 {
        margin-top: .75rem !important
    }

    .mr-lg-3,
    .mx-lg-3 {
        margin-right: .75rem !important
    }

    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: .75rem !important
    }

    .ml-lg-3,
    .mx-lg-3 {
        margin-left: .75rem !important
    }

    .m-lg-4 {
        margin: 1rem !important
    }

    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1rem !important
    }

    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1rem !important
    }

    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1rem !important
    }

    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1rem !important
    }

    .m-lg-5 {
        margin: 1.5rem !important
    }

    .mt-lg-5,
    .my-lg-5 {
        margin-top: 1.5rem !important
    }

    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 1.5rem !important
    }

    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 1.5rem !important
    }

    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 1.5rem !important
    }

    .m-lg-6 {
        margin: 2rem !important
    }

    .mt-lg-6,
    .my-lg-6 {
        margin-top: 2rem !important
    }

    .mr-lg-6,
    .mx-lg-6 {
        margin-right: 2rem !important
    }

    .mb-lg-6,
    .my-lg-6 {
        margin-bottom: 2rem !important
    }

    .ml-lg-6,
    .mx-lg-6 {
        margin-left: 2rem !important
    }

    .m-lg-7 {
        margin: 2.5rem !important
    }

    .mt-lg-7,
    .my-lg-7 {
        margin-top: 2.5rem !important
    }

    .mr-lg-7,
    .mx-lg-7 {
        margin-right: 2.5rem !important
    }

    .mb-lg-7,
    .my-lg-7 {
        margin-bottom: 2.5rem !important
    }

    .ml-lg-7,
    .mx-lg-7 {
        margin-left: 2.5rem !important
    }

    .m-lg-8 {
        margin: 3rem !important
    }

    .mt-lg-8,
    .my-lg-8 {
        margin-top: 3rem !important
    }

    .mr-lg-8,
    .mx-lg-8 {
        margin-right: 3rem !important
    }

    .mb-lg-8,
    .my-lg-8 {
        margin-bottom: 3rem !important
    }

    .ml-lg-8,
    .mx-lg-8 {
        margin-left: 3rem !important
    }

    .m-lg-9 {
        margin: 4rem !important
    }

    .mt-lg-9,
    .my-lg-9 {
        margin-top: 4rem !important
    }

    .mr-lg-9,
    .mx-lg-9 {
        margin-right: 4rem !important
    }

    .mb-lg-9,
    .my-lg-9 {
        margin-bottom: 4rem !important
    }

    .ml-lg-9,
    .mx-lg-9 {
        margin-left: 4rem !important
    }

    .m-lg-10 {
        margin: 5rem !important
    }

    .mt-lg-10,
    .my-lg-10 {
        margin-top: 5rem !important
    }

    .mr-lg-10,
    .mx-lg-10 {
        margin-right: 5rem !important
    }

    .mb-lg-10,
    .my-lg-10 {
        margin-bottom: 5rem !important
    }

    .ml-lg-10,
    .mx-lg-10 {
        margin-left: 5rem !important
    }

    .m-lg-11 {
        margin: 6rem !important
    }

    .mt-lg-11,
    .my-lg-11 {
        margin-top: 6rem !important
    }

    .mr-lg-11,
    .mx-lg-11 {
        margin-right: 6rem !important
    }

    .mb-lg-11,
    .my-lg-11 {
        margin-bottom: 6rem !important
    }

    .ml-lg-11,
    .mx-lg-11 {
        margin-left: 6rem !important
    }

    .m-lg-12 {
        margin: 8rem !important
    }

    .mt-lg-12,
    .my-lg-12 {
        margin-top: 8rem !important
    }

    .mr-lg-12,
    .mx-lg-12 {
        margin-right: 8rem !important
    }

    .mb-lg-12,
    .my-lg-12 {
        margin-bottom: 8rem !important
    }

    .ml-lg-12,
    .mx-lg-12 {
        margin-left: 8rem !important
    }

    .m-lg-13 {
        margin: 10rem !important
    }

    .mt-lg-13,
    .my-lg-13 {
        margin-top: 10rem !important
    }

    .mr-lg-13,
    .mx-lg-13 {
        margin-right: 10rem !important
    }

    .mb-lg-13,
    .my-lg-13 {
        margin-bottom: 10rem !important
    }

    .ml-lg-13,
    .mx-lg-13 {
        margin-left: 10rem !important
    }

    .m-lg-14 {
        margin: 12rem !important
    }

    .mt-lg-14,
    .my-lg-14 {
        margin-top: 12rem !important
    }

    .mr-lg-14,
    .mx-lg-14 {
        margin-right: 12rem !important
    }

    .mb-lg-14,
    .my-lg-14 {
        margin-bottom: 12rem !important
    }

    .ml-lg-14,
    .mx-lg-14 {
        margin-left: 12rem !important
    }

    .m-lg-15 {
        margin: 14rem !important
    }

    .mt-lg-15,
    .my-lg-15 {
        margin-top: 14rem !important
    }

    .mr-lg-15,
    .mx-lg-15 {
        margin-right: 14rem !important
    }

    .mb-lg-15,
    .my-lg-15 {
        margin-bottom: 14rem !important
    }

    .ml-lg-15,
    .mx-lg-15 {
        margin-left: 14rem !important
    }

    .m-lg-16 {
        margin: 16rem !important
    }

    .mt-lg-16,
    .my-lg-16 {
        margin-top: 16rem !important
    }

    .mr-lg-16,
    .mx-lg-16 {
        margin-right: 16rem !important
    }

    .mb-lg-16,
    .my-lg-16 {
        margin-bottom: 16rem !important
    }

    .ml-lg-16,
    .mx-lg-16 {
        margin-left: 16rem !important
    }

    .m-lg-17 {
        margin: 20rem !important
    }

    .mt-lg-17,
    .my-lg-17 {
        margin-top: 20rem !important
    }

    .mr-lg-17,
    .mx-lg-17 {
        margin-right: 20rem !important
    }

    .mb-lg-17,
    .my-lg-17 {
        margin-bottom: 20rem !important
    }

    .ml-lg-17,
    .mx-lg-17 {
        margin-left: 20rem !important
    }

    .m-lg-18 {
        margin: 24rem !important
    }

    .mt-lg-18,
    .my-lg-18 {
        margin-top: 24rem !important
    }

    .mr-lg-18,
    .mx-lg-18 {
        margin-right: 24rem !important
    }

    .mb-lg-18,
    .my-lg-18 {
        margin-bottom: 24rem !important
    }

    .ml-lg-18,
    .mx-lg-18 {
        margin-left: 24rem !important
    }

    .p-lg-0 {
        padding: 0 !important
    }

    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important
    }

    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important
    }

    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important
    }

    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important
    }

    .p-lg-1 {
        padding: .25rem !important
    }

    .pt-lg-1,
    .py-lg-1 {
        padding-top: .25rem !important
    }

    .pr-lg-1,
    .px-lg-1 {
        padding-right: .25rem !important
    }

    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: .25rem !important
    }

    .pl-lg-1,
    .px-lg-1 {
        padding-left: .25rem !important
    }

    .p-lg-2 {
        padding: .5rem !important
    }

    .pt-lg-2,
    .py-lg-2 {
        padding-top: .5rem !important
    }

    .pr-lg-2,
    .px-lg-2 {
        padding-right: .5rem !important
    }

    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: .5rem !important
    }

    .pl-lg-2,
    .px-lg-2 {
        padding-left: .5rem !important
    }

    .p-lg-3 {
        padding: .75rem !important
    }

    .pt-lg-3,
    .py-lg-3 {
        padding-top: .75rem !important
    }

    .pr-lg-3,
    .px-lg-3 {
        padding-right: .75rem !important
    }

    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: .75rem !important
    }

    .pl-lg-3,
    .px-lg-3 {
        padding-left: .75rem !important
    }

    .p-lg-4 {
        padding: 1rem !important
    }

    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1rem !important
    }

    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1rem !important
    }

    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1rem !important
    }

    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1rem !important
    }

    .p-lg-5 {
        padding: 1.5rem !important
    }

    .pt-lg-5,
    .py-lg-5 {
        padding-top: 1.5rem !important
    }

    .pr-lg-5,
    .px-lg-5 {
        padding-right: 1.5rem !important
    }

    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 1.5rem !important
    }

    .pl-lg-5,
    .px-lg-5 {
        padding-left: 1.5rem !important
    }

    .p-lg-6 {
        padding: 2rem !important
    }

    .pt-lg-6,
    .py-lg-6 {
        padding-top: 2rem !important
    }

    .pr-lg-6,
    .px-lg-6 {
        padding-right: 2rem !important
    }

    .pb-lg-6,
    .py-lg-6 {
        padding-bottom: 2rem !important
    }

    .pl-lg-6,
    .px-lg-6 {
        padding-left: 2rem !important
    }

    .p-lg-7 {
        padding: 2.5rem !important
    }

    .pt-lg-7,
    .py-lg-7 {
        padding-top: 2.5rem !important
    }

    .pr-lg-7,
    .px-lg-7 {
        padding-right: 2.5rem !important
    }

    .pb-lg-7,
    .py-lg-7 {
        padding-bottom: 2.5rem !important
    }

    .pl-lg-7,
    .px-lg-7 {
        padding-left: 2.5rem !important
    }

    .p-lg-8 {
        padding: 3rem !important
    }

    .pt-lg-8,
    .py-lg-8 {
        padding-top: 3rem !important
    }

    .pr-lg-8,
    .px-lg-8 {
        padding-right: 3rem !important
    }

    .pb-lg-8,
    .py-lg-8 {
        padding-bottom: 3rem !important
    }

    .pl-lg-8,
    .px-lg-8 {
        padding-left: 3rem !important
    }

    .p-lg-9 {
        padding: 4rem !important
    }

    .pt-lg-9,
    .py-lg-9 {
        padding-top: 4rem !important
    }

    .pr-lg-9,
    .px-lg-9 {
        padding-right: 4rem !important
    }

    .pb-lg-9,
    .py-lg-9 {
        padding-bottom: 4rem !important
    }

    .pl-lg-9,
    .px-lg-9 {
        padding-left: 4rem !important
    }

    .p-lg-10 {
        padding: 5rem !important
    }

    .pt-lg-10,
    .py-lg-10 {
        padding-top: 5rem !important
    }

    .pr-lg-10,
    .px-lg-10 {
        padding-right: 5rem !important
    }

    .pb-lg-10,
    .py-lg-10 {
        padding-bottom: 5rem !important
    }

    .pl-lg-10,
    .px-lg-10 {
        padding-left: 5rem !important
    }

    .p-lg-11 {
        padding: 6rem !important
    }

    .pt-lg-11,
    .py-lg-11 {
        padding-top: 6rem !important
    }

    .pr-lg-11,
    .px-lg-11 {
        padding-right: 6rem !important
    }

    .pb-lg-11,
    .py-lg-11 {
        padding-bottom: 6rem !important
    }

    .pl-lg-11,
    .px-lg-11 {
        padding-left: 6rem !important
    }

    .p-lg-12 {
        padding: 8rem !important
    }

    .pt-lg-12,
    .py-lg-12 {
        padding-top: 8rem !important
    }

    .pr-lg-12,
    .px-lg-12 {
        padding-right: 8rem !important
    }

    .pb-lg-12,
    .py-lg-12 {
        padding-bottom: 8rem !important
    }

    .pl-lg-12,
    .px-lg-12 {
        padding-left: 8rem !important
    }

    .p-lg-13 {
        padding: 10rem !important
    }

    .pt-lg-13,
    .py-lg-13 {
        padding-top: 10rem !important
    }

    .pr-lg-13,
    .px-lg-13 {
        padding-right: 10rem !important
    }

    .pb-lg-13,
    .py-lg-13 {
        padding-bottom: 10rem !important
    }

    .pl-lg-13,
    .px-lg-13 {
        padding-left: 10rem !important
    }

    .p-lg-14 {
        padding: 12rem !important
    }

    .pt-lg-14,
    .py-lg-14 {
        padding-top: 12rem !important
    }

    .pr-lg-14,
    .px-lg-14 {
        padding-right: 12rem !important
    }

    .pb-lg-14,
    .py-lg-14 {
        padding-bottom: 12rem !important
    }

    .pl-lg-14,
    .px-lg-14 {
        padding-left: 12rem !important
    }

    .p-lg-15 {
        padding: 14rem !important
    }

    .pt-lg-15,
    .py-lg-15 {
        padding-top: 14rem !important
    }

    .pr-lg-15,
    .px-lg-15 {
        padding-right: 14rem !important
    }

    .pb-lg-15,
    .py-lg-15 {
        padding-bottom: 14rem !important
    }

    .pl-lg-15,
    .px-lg-15 {
        padding-left: 14rem !important
    }

    .p-lg-16 {
        padding: 16rem !important
    }

    .pt-lg-16,
    .py-lg-16 {
        padding-top: 16rem !important
    }

    .pr-lg-16,
    .px-lg-16 {
        padding-right: 16rem !important
    }

    .pb-lg-16,
    .py-lg-16 {
        padding-bottom: 16rem !important
    }

    .pl-lg-16,
    .px-lg-16 {
        padding-left: 16rem !important
    }

    .p-lg-17 {
        padding: 20rem !important
    }

    .pt-lg-17,
    .py-lg-17 {
        padding-top: 20rem !important
    }

    .pr-lg-17,
    .px-lg-17 {
        padding-right: 20rem !important
    }

    .pb-lg-17,
    .py-lg-17 {
        padding-bottom: 20rem !important
    }

    .pl-lg-17,
    .px-lg-17 {
        padding-left: 20rem !important
    }

    .p-lg-18 {
        padding: 24rem !important
    }

    .pt-lg-18,
    .py-lg-18 {
        padding-top: 24rem !important
    }

    .pr-lg-18,
    .px-lg-18 {
        padding-right: 24rem !important
    }

    .pb-lg-18,
    .py-lg-18 {
        padding-bottom: 24rem !important
    }

    .pl-lg-18,
    .px-lg-18 {
        padding-left: 24rem !important
    }

    .m-lg-n1 {
        margin: -.25rem !important
    }

    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -.25rem !important
    }

    .mr-lg-n1,
    .mx-lg-n1 {
        margin-right: -.25rem !important
    }

    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-lg-n1,
    .mx-lg-n1 {
        margin-left: -.25rem !important
    }

    .m-lg-n2 {
        margin: -.5rem !important
    }

    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -.5rem !important
    }

    .mr-lg-n2,
    .mx-lg-n2 {
        margin-right: -.5rem !important
    }

    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-lg-n2,
    .mx-lg-n2 {
        margin-left: -.5rem !important
    }

    .m-lg-n3 {
        margin: -.75rem !important
    }

    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -.75rem !important
    }

    .mr-lg-n3,
    .mx-lg-n3 {
        margin-right: -.75rem !important
    }

    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -.75rem !important
    }

    .ml-lg-n3,
    .mx-lg-n3 {
        margin-left: -.75rem !important
    }

    .m-lg-n4 {
        margin: -1rem !important
    }

    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1rem !important
    }

    .mr-lg-n4,
    .mx-lg-n4 {
        margin-right: -1rem !important
    }

    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1rem !important
    }

    .ml-lg-n4,
    .mx-lg-n4 {
        margin-left: -1rem !important
    }

    .m-lg-n5 {
        margin: -1.5rem !important
    }

    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -1.5rem !important
    }

    .mr-lg-n5,
    .mx-lg-n5 {
        margin-right: -1.5rem !important
    }

    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -1.5rem !important
    }

    .ml-lg-n5,
    .mx-lg-n5 {
        margin-left: -1.5rem !important
    }

    .m-lg-n6 {
        margin: -2rem !important
    }

    .mt-lg-n6,
    .my-lg-n6 {
        margin-top: -2rem !important
    }

    .mr-lg-n6,
    .mx-lg-n6 {
        margin-right: -2rem !important
    }

    .mb-lg-n6,
    .my-lg-n6 {
        margin-bottom: -2rem !important
    }

    .ml-lg-n6,
    .mx-lg-n6 {
        margin-left: -2rem !important
    }

    .m-lg-n7 {
        margin: -2.5rem !important
    }

    .mt-lg-n7,
    .my-lg-n7 {
        margin-top: -2.5rem !important
    }

    .mr-lg-n7,
    .mx-lg-n7 {
        margin-right: -2.5rem !important
    }

    .mb-lg-n7,
    .my-lg-n7 {
        margin-bottom: -2.5rem !important
    }

    .ml-lg-n7,
    .mx-lg-n7 {
        margin-left: -2.5rem !important
    }

    .m-lg-n8 {
        margin: -3rem !important
    }

    .mt-lg-n8,
    .my-lg-n8 {
        margin-top: -3rem !important
    }

    .mr-lg-n8,
    .mx-lg-n8 {
        margin-right: -3rem !important
    }

    .mb-lg-n8,
    .my-lg-n8 {
        margin-bottom: -3rem !important
    }

    .ml-lg-n8,
    .mx-lg-n8 {
        margin-left: -3rem !important
    }

    .m-lg-n9 {
        margin: -4rem !important
    }

    .mt-lg-n9,
    .my-lg-n9 {
        margin-top: -4rem !important
    }

    .mr-lg-n9,
    .mx-lg-n9 {
        margin-right: -4rem !important
    }

    .mb-lg-n9,
    .my-lg-n9 {
        margin-bottom: -4rem !important
    }

    .ml-lg-n9,
    .mx-lg-n9 {
        margin-left: -4rem !important
    }

    .m-lg-n10 {
        margin: -5rem !important
    }

    .mt-lg-n10,
    .my-lg-n10 {
        margin-top: -5rem !important
    }

    .mr-lg-n10,
    .mx-lg-n10 {
        margin-right: -5rem !important
    }

    .mb-lg-n10,
    .my-lg-n10 {
        margin-bottom: -5rem !important
    }

    .ml-lg-n10,
    .mx-lg-n10 {
        margin-left: -5rem !important
    }

    .m-lg-n11 {
        margin: -6rem !important
    }

    .mt-lg-n11,
    .my-lg-n11 {
        margin-top: -6rem !important
    }

    .mr-lg-n11,
    .mx-lg-n11 {
        margin-right: -6rem !important
    }

    .mb-lg-n11,
    .my-lg-n11 {
        margin-bottom: -6rem !important
    }

    .ml-lg-n11,
    .mx-lg-n11 {
        margin-left: -6rem !important
    }

    .m-lg-n12 {
        margin: -8rem !important
    }

    .mt-lg-n12,
    .my-lg-n12 {
        margin-top: -8rem !important
    }

    .mr-lg-n12,
    .mx-lg-n12 {
        margin-right: -8rem !important
    }

    .mb-lg-n12,
    .my-lg-n12 {
        margin-bottom: -8rem !important
    }

    .ml-lg-n12,
    .mx-lg-n12 {
        margin-left: -8rem !important
    }

    .m-lg-n13 {
        margin: -10rem !important
    }

    .mt-lg-n13,
    .my-lg-n13 {
        margin-top: -10rem !important
    }

    .mr-lg-n13,
    .mx-lg-n13 {
        margin-right: -10rem !important
    }

    .mb-lg-n13,
    .my-lg-n13 {
        margin-bottom: -10rem !important
    }

    .ml-lg-n13,
    .mx-lg-n13 {
        margin-left: -10rem !important
    }

    .m-lg-n14 {
        margin: -12rem !important
    }

    .mt-lg-n14,
    .my-lg-n14 {
        margin-top: -12rem !important
    }

    .mr-lg-n14,
    .mx-lg-n14 {
        margin-right: -12rem !important
    }

    .mb-lg-n14,
    .my-lg-n14 {
        margin-bottom: -12rem !important
    }

    .ml-lg-n14,
    .mx-lg-n14 {
        margin-left: -12rem !important
    }

    .m-lg-n15 {
        margin: -14rem !important
    }

    .mt-lg-n15,
    .my-lg-n15 {
        margin-top: -14rem !important
    }

    .mr-lg-n15,
    .mx-lg-n15 {
        margin-right: -14rem !important
    }

    .mb-lg-n15,
    .my-lg-n15 {
        margin-bottom: -14rem !important
    }

    .ml-lg-n15,
    .mx-lg-n15 {
        margin-left: -14rem !important
    }

    .m-lg-n16 {
        margin: -16rem !important
    }

    .mt-lg-n16,
    .my-lg-n16 {
        margin-top: -16rem !important
    }

    .mr-lg-n16,
    .mx-lg-n16 {
        margin-right: -16rem !important
    }

    .mb-lg-n16,
    .my-lg-n16 {
        margin-bottom: -16rem !important
    }

    .ml-lg-n16,
    .mx-lg-n16 {
        margin-left: -16rem !important
    }

    .m-lg-n17 {
        margin: -20rem !important
    }

    .mt-lg-n17,
    .my-lg-n17 {
        margin-top: -20rem !important
    }

    .mr-lg-n17,
    .mx-lg-n17 {
        margin-right: -20rem !important
    }

    .mb-lg-n17,
    .my-lg-n17 {
        margin-bottom: -20rem !important
    }

    .ml-lg-n17,
    .mx-lg-n17 {
        margin-left: -20rem !important
    }

    .m-lg-n18 {
        margin: -24rem !important
    }

    .mt-lg-n18,
    .my-lg-n18 {
        margin-top: -24rem !important
    }

    .mr-lg-n18,
    .mx-lg-n18 {
        margin-right: -24rem !important
    }

    .mb-lg-n18,
    .my-lg-n18 {
        margin-bottom: -24rem !important
    }

    .ml-lg-n18,
    .mx-lg-n18 {
        margin-left: -24rem !important
    }

    .m-lg-auto {
        margin: auto !important
    }

    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important
    }

    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important
    }

    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important
    }

    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important
    }
}

@media (min-width:1200px) {
    .m-xl-0 {
        margin: 0 !important
    }

    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important
    }

    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important
    }

    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important
    }

    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important
    }

    .m-xl-1 {
        margin: .25rem !important
    }

    .mt-xl-1,
    .my-xl-1 {
        margin-top: .25rem !important
    }

    .mr-xl-1,
    .mx-xl-1 {
        margin-right: .25rem !important
    }

    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: .25rem !important
    }

    .ml-xl-1,
    .mx-xl-1 {
        margin-left: .25rem !important
    }

    .m-xl-2 {
        margin: .5rem !important
    }

    .mt-xl-2,
    .my-xl-2 {
        margin-top: .5rem !important
    }

    .mr-xl-2,
    .mx-xl-2 {
        margin-right: .5rem !important
    }

    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: .5rem !important
    }

    .ml-xl-2,
    .mx-xl-2 {
        margin-left: .5rem !important
    }

    .m-xl-3 {
        margin: .75rem !important
    }

    .mt-xl-3,
    .my-xl-3 {
        margin-top: .75rem !important
    }

    .mr-xl-3,
    .mx-xl-3 {
        margin-right: .75rem !important
    }

    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: .75rem !important
    }

    .ml-xl-3,
    .mx-xl-3 {
        margin-left: .75rem !important
    }

    .m-xl-4 {
        margin: 1rem !important
    }

    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1rem !important
    }

    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1rem !important
    }

    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1rem !important
    }

    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1rem !important
    }

    .m-xl-5 {
        margin: 1.5rem !important
    }

    .mt-xl-5,
    .my-xl-5 {
        margin-top: 1.5rem !important
    }

    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 1.5rem !important
    }

    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 1.5rem !important
    }

    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 1.5rem !important
    }

    .m-xl-6 {
        margin: 2rem !important
    }

    .mt-xl-6,
    .my-xl-6 {
        margin-top: 2rem !important
    }

    .mr-xl-6,
    .mx-xl-6 {
        margin-right: 2rem !important
    }

    .mb-xl-6,
    .my-xl-6 {
        margin-bottom: 2rem !important
    }

    .ml-xl-6,
    .mx-xl-6 {
        margin-left: 2rem !important
    }

    .m-xl-7 {
        margin: 2.5rem !important
    }

    .mt-xl-7,
    .my-xl-7 {
        margin-top: 2.5rem !important
    }

    .mr-xl-7,
    .mx-xl-7 {
        margin-right: 2.5rem !important
    }

    .mb-xl-7,
    .my-xl-7 {
        margin-bottom: 2.5rem !important
    }

    .ml-xl-7,
    .mx-xl-7 {
        margin-left: 2.5rem !important
    }

    .m-xl-8 {
        margin: 3rem !important
    }

    .mt-xl-8,
    .my-xl-8 {
        margin-top: 3rem !important
    }

    .mr-xl-8,
    .mx-xl-8 {
        margin-right: 3rem !important
    }

    .mb-xl-8,
    .my-xl-8 {
        margin-bottom: 3rem !important
    }

    .ml-xl-8,
    .mx-xl-8 {
        margin-left: 3rem !important
    }

    .m-xl-9 {
        margin: 4rem !important
    }

    .mt-xl-9,
    .my-xl-9 {
        margin-top: 4rem !important
    }

    .mr-xl-9,
    .mx-xl-9 {
        margin-right: 4rem !important
    }

    .mb-xl-9,
    .my-xl-9 {
        margin-bottom: 4rem !important
    }

    .ml-xl-9,
    .mx-xl-9 {
        margin-left: 4rem !important
    }

    .m-xl-10 {
        margin: 5rem !important
    }

    .mt-xl-10,
    .my-xl-10 {
        margin-top: 5rem !important
    }

    .mr-xl-10,
    .mx-xl-10 {
        margin-right: 5rem !important
    }

    .mb-xl-10,
    .my-xl-10 {
        margin-bottom: 5rem !important
    }

    .ml-xl-10,
    .mx-xl-10 {
        margin-left: 5rem !important
    }

    .m-xl-11 {
        margin: 6rem !important
    }

    .mt-xl-11,
    .my-xl-11 {
        margin-top: 6rem !important
    }

    .mr-xl-11,
    .mx-xl-11 {
        margin-right: 6rem !important
    }

    .mb-xl-11,
    .my-xl-11 {
        margin-bottom: 6rem !important
    }

    .ml-xl-11,
    .mx-xl-11 {
        margin-left: 6rem !important
    }

    .m-xl-12 {
        margin: 8rem !important
    }

    .mt-xl-12,
    .my-xl-12 {
        margin-top: 8rem !important
    }

    .mr-xl-12,
    .mx-xl-12 {
        margin-right: 8rem !important
    }

    .mb-xl-12,
    .my-xl-12 {
        margin-bottom: 8rem !important
    }

    .ml-xl-12,
    .mx-xl-12 {
        margin-left: 8rem !important
    }

    .m-xl-13 {
        margin: 10rem !important
    }

    .mt-xl-13,
    .my-xl-13 {
        margin-top: 10rem !important
    }

    .mr-xl-13,
    .mx-xl-13 {
        margin-right: 10rem !important
    }

    .mb-xl-13,
    .my-xl-13 {
        margin-bottom: 10rem !important
    }

    .ml-xl-13,
    .mx-xl-13 {
        margin-left: 10rem !important
    }

    .m-xl-14 {
        margin: 12rem !important
    }

    .mt-xl-14,
    .my-xl-14 {
        margin-top: 12rem !important
    }

    .mr-xl-14,
    .mx-xl-14 {
        margin-right: 12rem !important
    }

    .mb-xl-14,
    .my-xl-14 {
        margin-bottom: 12rem !important
    }

    .ml-xl-14,
    .mx-xl-14 {
        margin-left: 12rem !important
    }

    .m-xl-15 {
        margin: 14rem !important
    }

    .mt-xl-15,
    .my-xl-15 {
        margin-top: 14rem !important
    }

    .mr-xl-15,
    .mx-xl-15 {
        margin-right: 14rem !important
    }

    .mb-xl-15,
    .my-xl-15 {
        margin-bottom: 14rem !important
    }

    .ml-xl-15,
    .mx-xl-15 {
        margin-left: 14rem !important
    }

    .m-xl-16 {
        margin: 16rem !important
    }

    .mt-xl-16,
    .my-xl-16 {
        margin-top: 16rem !important
    }

    .mr-xl-16,
    .mx-xl-16 {
        margin-right: 16rem !important
    }

    .mb-xl-16,
    .my-xl-16 {
        margin-bottom: 16rem !important
    }

    .ml-xl-16,
    .mx-xl-16 {
        margin-left: 16rem !important
    }

    .m-xl-17 {
        margin: 20rem !important
    }

    .mt-xl-17,
    .my-xl-17 {
        margin-top: 20rem !important
    }

    .mr-xl-17,
    .mx-xl-17 {
        margin-right: 20rem !important
    }

    .mb-xl-17,
    .my-xl-17 {
        margin-bottom: 20rem !important
    }

    .ml-xl-17,
    .mx-xl-17 {
        margin-left: 20rem !important
    }

    .m-xl-18 {
        margin: 24rem !important
    }

    .mt-xl-18,
    .my-xl-18 {
        margin-top: 24rem !important
    }

    .mr-xl-18,
    .mx-xl-18 {
        margin-right: 24rem !important
    }

    .mb-xl-18,
    .my-xl-18 {
        margin-bottom: 24rem !important
    }

    .ml-xl-18,
    .mx-xl-18 {
        margin-left: 24rem !important
    }

    .p-xl-0 {
        padding: 0 !important
    }

    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important
    }

    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important
    }

    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important
    }

    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important
    }

    .p-xl-1 {
        padding: .25rem !important
    }

    .pt-xl-1,
    .py-xl-1 {
        padding-top: .25rem !important
    }

    .pr-xl-1,
    .px-xl-1 {
        padding-right: .25rem !important
    }

    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: .25rem !important
    }

    .pl-xl-1,
    .px-xl-1 {
        padding-left: .25rem !important
    }

    .p-xl-2 {
        padding: .5rem !important
    }

    .pt-xl-2,
    .py-xl-2 {
        padding-top: .5rem !important
    }

    .pr-xl-2,
    .px-xl-2 {
        padding-right: .5rem !important
    }

    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: .5rem !important
    }

    .pl-xl-2,
    .px-xl-2 {
        padding-left: .5rem !important
    }

    .p-xl-3 {
        padding: .75rem !important
    }

    .pt-xl-3,
    .py-xl-3 {
        padding-top: .75rem !important
    }

    .pr-xl-3,
    .px-xl-3 {
        padding-right: .75rem !important
    }

    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: .75rem !important
    }

    .pl-xl-3,
    .px-xl-3 {
        padding-left: .75rem !important
    }

    .p-xl-4 {
        padding: 1rem !important
    }

    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1rem !important
    }

    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1rem !important
    }

    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1rem !important
    }

    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1rem !important
    }

    .p-xl-5 {
        padding: 1.5rem !important
    }

    .pt-xl-5,
    .py-xl-5 {
        padding-top: 1.5rem !important
    }

    .pr-xl-5,
    .px-xl-5 {
        padding-right: 1.5rem !important
    }

    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 1.5rem !important
    }

    .pl-xl-5,
    .px-xl-5 {
        padding-left: 1.5rem !important
    }

    .p-xl-6 {
        padding: 2rem !important
    }

    .pt-xl-6,
    .py-xl-6 {
        padding-top: 2rem !important
    }

    .pr-xl-6,
    .px-xl-6 {
        padding-right: 2rem !important
    }

    .pb-xl-6,
    .py-xl-6 {
        padding-bottom: 2rem !important
    }

    .pl-xl-6,
    .px-xl-6 {
        padding-left: 2rem !important
    }

    .p-xl-7 {
        padding: 2.5rem !important
    }

    .pt-xl-7,
    .py-xl-7 {
        padding-top: 2.5rem !important
    }

    .pr-xl-7,
    .px-xl-7 {
        padding-right: 2.5rem !important
    }

    .pb-xl-7,
    .py-xl-7 {
        padding-bottom: 2.5rem !important
    }

    .pl-xl-7,
    .px-xl-7 {
        padding-left: 2.5rem !important
    }

    .p-xl-8 {
        padding: 3rem !important
    }

    .pt-xl-8,
    .py-xl-8 {
        padding-top: 3rem !important
    }

    .pr-xl-8,
    .px-xl-8 {
        padding-right: 3rem !important
    }

    .pb-xl-8,
    .py-xl-8 {
        padding-bottom: 3rem !important
    }

    .pl-xl-8,
    .px-xl-8 {
        padding-left: 3rem !important
    }

    .p-xl-9 {
        padding: 4rem !important
    }

    .pt-xl-9,
    .py-xl-9 {
        padding-top: 4rem !important
    }

    .pr-xl-9,
    .px-xl-9 {
        padding-right: 4rem !important
    }

    .pb-xl-9,
    .py-xl-9 {
        padding-bottom: 4rem !important
    }

    .pl-xl-9,
    .px-xl-9 {
        padding-left: 4rem !important
    }

    .p-xl-10 {
        padding: 5rem !important
    }

    .pt-xl-10,
    .py-xl-10 {
        padding-top: 5rem !important
    }

    .pr-xl-10,
    .px-xl-10 {
        padding-right: 5rem !important
    }

    .pb-xl-10,
    .py-xl-10 {
        padding-bottom: 5rem !important
    }

    .pl-xl-10,
    .px-xl-10 {
        padding-left: 5rem !important
    }

    .p-xl-11 {
        padding: 6rem !important
    }

    .pt-xl-11,
    .py-xl-11 {
        padding-top: 6rem !important
    }

    .pr-xl-11,
    .px-xl-11 {
        padding-right: 6rem !important
    }

    .pb-xl-11,
    .py-xl-11 {
        padding-bottom: 6rem !important
    }

    .pl-xl-11,
    .px-xl-11 {
        padding-left: 6rem !important
    }

    .p-xl-12 {
        padding: 8rem !important
    }

    .pt-xl-12,
    .py-xl-12 {
        padding-top: 8rem !important
    }

    .pr-xl-12,
    .px-xl-12 {
        padding-right: 8rem !important
    }

    .pb-xl-12,
    .py-xl-12 {
        padding-bottom: 8rem !important
    }

    .pl-xl-12,
    .px-xl-12 {
        padding-left: 8rem !important
    }

    .p-xl-13 {
        padding: 10rem !important
    }

    .pt-xl-13,
    .py-xl-13 {
        padding-top: 10rem !important
    }

    .pr-xl-13,
    .px-xl-13 {
        padding-right: 10rem !important
    }

    .pb-xl-13,
    .py-xl-13 {
        padding-bottom: 10rem !important
    }

    .pl-xl-13,
    .px-xl-13 {
        padding-left: 10rem !important
    }

    .p-xl-14 {
        padding: 12rem !important
    }

    .pt-xl-14,
    .py-xl-14 {
        padding-top: 12rem !important
    }

    .pr-xl-14,
    .px-xl-14 {
        padding-right: 12rem !important
    }

    .pb-xl-14,
    .py-xl-14 {
        padding-bottom: 12rem !important
    }

    .pl-xl-14,
    .px-xl-14 {
        padding-left: 12rem !important
    }

    .p-xl-15 {
        padding: 14rem !important
    }

    .pt-xl-15,
    .py-xl-15 {
        padding-top: 14rem !important
    }

    .pr-xl-15,
    .px-xl-15 {
        padding-right: 14rem !important
    }

    .pb-xl-15,
    .py-xl-15 {
        padding-bottom: 14rem !important
    }

    .pl-xl-15,
    .px-xl-15 {
        padding-left: 14rem !important
    }

    .p-xl-16 {
        padding: 16rem !important
    }

    .pt-xl-16,
    .py-xl-16 {
        padding-top: 16rem !important
    }

    .pr-xl-16,
    .px-xl-16 {
        padding-right: 16rem !important
    }

    .pb-xl-16,
    .py-xl-16 {
        padding-bottom: 16rem !important
    }

    .pl-xl-16,
    .px-xl-16 {
        padding-left: 16rem !important
    }

    .p-xl-17 {
        padding: 20rem !important
    }

    .pt-xl-17,
    .py-xl-17 {
        padding-top: 20rem !important
    }

    .pr-xl-17,
    .px-xl-17 {
        padding-right: 20rem !important
    }

    .pb-xl-17,
    .py-xl-17 {
        padding-bottom: 20rem !important
    }

    .pl-xl-17,
    .px-xl-17 {
        padding-left: 20rem !important
    }

    .p-xl-18 {
        padding: 24rem !important
    }

    .pt-xl-18,
    .py-xl-18 {
        padding-top: 24rem !important
    }

    .pr-xl-18,
    .px-xl-18 {
        padding-right: 24rem !important
    }

    .pb-xl-18,
    .py-xl-18 {
        padding-bottom: 24rem !important
    }

    .pl-xl-18,
    .px-xl-18 {
        padding-left: 24rem !important
    }

    .m-xl-n1 {
        margin: -.25rem !important
    }

    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -.25rem !important
    }

    .mr-xl-n1,
    .mx-xl-n1 {
        margin-right: -.25rem !important
    }

    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-xl-n1,
    .mx-xl-n1 {
        margin-left: -.25rem !important
    }

    .m-xl-n2 {
        margin: -.5rem !important
    }

    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -.5rem !important
    }

    .mr-xl-n2,
    .mx-xl-n2 {
        margin-right: -.5rem !important
    }

    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-xl-n2,
    .mx-xl-n2 {
        margin-left: -.5rem !important
    }

    .m-xl-n3 {
        margin: -.75rem !important
    }

    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -.75rem !important
    }

    .mr-xl-n3,
    .mx-xl-n3 {
        margin-right: -.75rem !important
    }

    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -.75rem !important
    }

    .ml-xl-n3,
    .mx-xl-n3 {
        margin-left: -.75rem !important
    }

    .m-xl-n4 {
        margin: -1rem !important
    }

    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1rem !important
    }

    .mr-xl-n4,
    .mx-xl-n4 {
        margin-right: -1rem !important
    }

    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1rem !important
    }

    .ml-xl-n4,
    .mx-xl-n4 {
        margin-left: -1rem !important
    }

    .m-xl-n5 {
        margin: -1.5rem !important
    }

    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -1.5rem !important
    }

    .mr-xl-n5,
    .mx-xl-n5 {
        margin-right: -1.5rem !important
    }

    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -1.5rem !important
    }

    .ml-xl-n5,
    .mx-xl-n5 {
        margin-left: -1.5rem !important
    }

    .m-xl-n6 {
        margin: -2rem !important
    }

    .mt-xl-n6,
    .my-xl-n6 {
        margin-top: -2rem !important
    }

    .mr-xl-n6,
    .mx-xl-n6 {
        margin-right: -2rem !important
    }

    .mb-xl-n6,
    .my-xl-n6 {
        margin-bottom: -2rem !important
    }

    .ml-xl-n6,
    .mx-xl-n6 {
        margin-left: -2rem !important
    }

    .m-xl-n7 {
        margin: -2.5rem !important
    }

    .mt-xl-n7,
    .my-xl-n7 {
        margin-top: -2.5rem !important
    }

    .mr-xl-n7,
    .mx-xl-n7 {
        margin-right: -2.5rem !important
    }

    .mb-xl-n7,
    .my-xl-n7 {
        margin-bottom: -2.5rem !important
    }

    .ml-xl-n7,
    .mx-xl-n7 {
        margin-left: -2.5rem !important
    }

    .m-xl-n8 {
        margin: -3rem !important
    }

    .mt-xl-n8,
    .my-xl-n8 {
        margin-top: -3rem !important
    }

    .mr-xl-n8,
    .mx-xl-n8 {
        margin-right: -3rem !important
    }

    .mb-xl-n8,
    .my-xl-n8 {
        margin-bottom: -3rem !important
    }

    .ml-xl-n8,
    .mx-xl-n8 {
        margin-left: -3rem !important
    }

    .m-xl-n9 {
        margin: -4rem !important
    }

    .mt-xl-n9,
    .my-xl-n9 {
        margin-top: -4rem !important
    }

    .mr-xl-n9,
    .mx-xl-n9 {
        margin-right: -4rem !important
    }

    .mb-xl-n9,
    .my-xl-n9 {
        margin-bottom: -4rem !important
    }

    .ml-xl-n9,
    .mx-xl-n9 {
        margin-left: -4rem !important
    }

    .m-xl-n10 {
        margin: -5rem !important
    }

    .mt-xl-n10,
    .my-xl-n10 {
        margin-top: -5rem !important
    }

    .mr-xl-n10,
    .mx-xl-n10 {
        margin-right: -5rem !important
    }

    .mb-xl-n10,
    .my-xl-n10 {
        margin-bottom: -5rem !important
    }

    .ml-xl-n10,
    .mx-xl-n10 {
        margin-left: -5rem !important
    }

    .m-xl-n11 {
        margin: -6rem !important
    }

    .mt-xl-n11,
    .my-xl-n11 {
        margin-top: -6rem !important
    }

    .mr-xl-n11,
    .mx-xl-n11 {
        margin-right: -6rem !important
    }

    .mb-xl-n11,
    .my-xl-n11 {
        margin-bottom: -6rem !important
    }

    .ml-xl-n11,
    .mx-xl-n11 {
        margin-left: -6rem !important
    }

    .m-xl-n12 {
        margin: -8rem !important
    }

    .mt-xl-n12,
    .my-xl-n12 {
        margin-top: -8rem !important
    }

    .mr-xl-n12,
    .mx-xl-n12 {
        margin-right: -8rem !important
    }

    .mb-xl-n12,
    .my-xl-n12 {
        margin-bottom: -8rem !important
    }

    .ml-xl-n12,
    .mx-xl-n12 {
        margin-left: -8rem !important
    }

    .m-xl-n13 {
        margin: -10rem !important
    }

    .mt-xl-n13,
    .my-xl-n13 {
        margin-top: -10rem !important
    }

    .mr-xl-n13,
    .mx-xl-n13 {
        margin-right: -10rem !important
    }

    .mb-xl-n13,
    .my-xl-n13 {
        margin-bottom: -10rem !important
    }

    .ml-xl-n13,
    .mx-xl-n13 {
        margin-left: -10rem !important
    }

    .m-xl-n14 {
        margin: -12rem !important
    }

    .mt-xl-n14,
    .my-xl-n14 {
        margin-top: -12rem !important
    }

    .mr-xl-n14,
    .mx-xl-n14 {
        margin-right: -12rem !important
    }

    .mb-xl-n14,
    .my-xl-n14 {
        margin-bottom: -12rem !important
    }

    .ml-xl-n14,
    .mx-xl-n14 {
        margin-left: -12rem !important
    }

    .m-xl-n15 {
        margin: -14rem !important
    }

    .mt-xl-n15,
    .my-xl-n15 {
        margin-top: -14rem !important
    }

    .mr-xl-n15,
    .mx-xl-n15 {
        margin-right: -14rem !important
    }

    .mb-xl-n15,
    .my-xl-n15 {
        margin-bottom: -14rem !important
    }

    .ml-xl-n15,
    .mx-xl-n15 {
        margin-left: -14rem !important
    }

    .m-xl-n16 {
        margin: -16rem !important
    }

    .mt-xl-n16,
    .my-xl-n16 {
        margin-top: -16rem !important
    }

    .mr-xl-n16,
    .mx-xl-n16 {
        margin-right: -16rem !important
    }

    .mb-xl-n16,
    .my-xl-n16 {
        margin-bottom: -16rem !important
    }

    .ml-xl-n16,
    .mx-xl-n16 {
        margin-left: -16rem !important
    }

    .m-xl-n17 {
        margin: -20rem !important
    }

    .mt-xl-n17,
    .my-xl-n17 {
        margin-top: -20rem !important
    }

    .mr-xl-n17,
    .mx-xl-n17 {
        margin-right: -20rem !important
    }

    .mb-xl-n17,
    .my-xl-n17 {
        margin-bottom: -20rem !important
    }

    .ml-xl-n17,
    .mx-xl-n17 {
        margin-left: -20rem !important
    }

    .m-xl-n18 {
        margin: -24rem !important
    }

    .mt-xl-n18,
    .my-xl-n18 {
        margin-top: -24rem !important
    }

    .mr-xl-n18,
    .mx-xl-n18 {
        margin-right: -24rem !important
    }

    .mb-xl-n18,
    .my-xl-n18 {
        margin-bottom: -24rem !important
    }

    .ml-xl-n18,
    .mx-xl-n18 {
        margin-left: -24rem !important
    }

    .m-xl-auto {
        margin: auto !important
    }

    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important
    }

    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important
    }

    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important
    }

    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important
    }
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0)
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important
}

.text-justify {
    text-align: justify !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.text-left {
    text-align: left !important
}

.text-right {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

@media (min-width:576px) {
    .text-sm-left {
        text-align: left !important
    }

    .text-sm-right {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }
}

@media (min-width:768px) {
    .text-md-left {
        text-align: left !important
    }

    .text-md-right {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }
}

@media (min-width:992px) {
    .text-lg-left {
        text-align: left !important
    }

    .text-lg-right {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }
}

@media (min-width:1200px) {
    .text-xl-left {
        text-align: left !important
    }

    .text-xl-right {
        text-align: right !important
    }

    .text-xl-center {
        text-align: center !important
    }
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.font-weight-light {
    font-weight: 300 !important
}

.font-weight-lighter {
    font-weight: lighter !important
}

.font-weight-normal {
    font-weight: 400 !important
}

.font-weight-bold {
    font-weight: 500 !important
}

.font-weight-bolder {
    font-weight: 700 !important
}

.font-italic {
    font-style: italic !important
}

.text-white {
    color: #fff !important
}

.text-primary {
    color: #6658ea !important
}

a.text-primary:focus,
a.text-primary:hover {
    color: #2e1bda !important
}

.text-secondary {
    color: #575a7b !important
}

a.text-secondary:focus,
a.text-secondary:hover {
    color: #37394e !important
}

.text-success {
    color: #2cd4a2 !important
}

a.text-success:focus,
a.text-success:hover {
    color: #1e9572 !important
}

.text-info {
    color: #17a2b8 !important
}

a.text-info:focus,
a.text-info:hover {
    color: #0f6674 !important
}

.text-warning {
    color: #f5c070 !important
}

a.text-warning:focus,
a.text-warning:hover {
    color: #f0a129 !important
}

.text-danger {
    color: #f9655b !important
}

a.text-danger:focus,
a.text-danger:hover {
    color: #f62011 !important
}

.text-light {
    color: #f3f8ff !important
}

a.text-light:focus,
a.text-light:hover {
    color: #a7cbff !important
}

.text-dark {
    color: #1b1642 !important
}

a.text-dark:focus,
a.text-dark:hover {
    color: #040309 !important
}

.text-primary-dark {
    color: #564caf !important
}

a.text-primary-dark:focus,
a.text-primary-dark:hover {
    color: #3c357a !important
}

.text-primary-light {
    color: #687ded !important
}

a.text-primary-light:focus,
a.text-primary-light:hover {
    color: #2442e5 !important
}

.text-body {
    color: #1b1642 !important
}

.text-muted {
    color: #575a7b !important
}

.text-black-50 {
    color: rgba(27, 22, 66, .5) !important
}

.text-white-50 {
    color: rgba(255, 255, 255, .5) !important
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.text-decoration-none {
    text-decoration: none !important
}

.text-break {
    word-wrap: break-word !important
}

.text-reset {
    color: inherit !important
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

@media print {

    *,
    ::after,
    ::before {
        text-shadow: none !important;
        box-shadow: none !important
    }

    a:not(.btn) {
        text-decoration: underline
    }

    abbr[title]::after {
        content: " ("attr(title) ")"
    }

    pre {
        white-space: pre-wrap !important
    }

    blockquote,
    pre {
        border: 1px solid #8d9aae;
        page-break-inside: avoid
    }

    thead {
        display: table-header-group
    }

    img,
    tr {
        page-break-inside: avoid
    }

    h2,
    h3,
    p {
        orphans: 3;
        widows: 3
    }

    h2,
    h3 {
        page-break-after: avoid
    }

    @page {
        size: a3
    }

    body {
        min-width: 992px !important
    }

    .container {
        min-width: 992px !important
    }

    .navbar {
        display: none
    }

    .badge {
        border: 1px solid #1b1642
    }

    .table {
        border-collapse: collapse !important
    }

    .table td,
    .table th {
        background-color: #fff !important
    }

    .table-bordered td,
    .table-bordered th {
        border: 1px solid #dce0f3 !important
    }

    .table-dark {
        color: inherit
    }

    .table-dark tbody+tbody,
    .table-dark td,
    .table-dark th,
    .table-dark thead th {
        border-color: #eaf2fe
    }

    .table .thead-dark th {
        color: inherit;
        border-color: #eaf2fe
    }
}

.alert {
    font-size: 1rem
}

.alert-primary {
    color: #fff
}

.alert-secondary {
    color: #fff
}

.alert-success {
    color: #fff
}

.alert-info {
    color: #fff
}

.alert-warning {
    color: #fff
}

.alert-danger {
    color: #fff
}

.alert-light {
    color: #1b1642
}

.alert-dark {
    color: #fff
}

.alert-primary-dark {
    color: #fff
}

.alert-primary-light {
    color: #fff
}

.alert-dismissible .close>.fe {
    line-height: 1.6rem
}

.badge-primary-soft {
    background-color: rgba(102, 88, 234, .1);
    color: #6658ea
}

a.badge-primary-soft:focus,
a.badge-primary-soft:hover {
    background-color: rgba(102, 88, 234, .2);
    color: #6658ea
}

.badge-secondary-soft {
    background-color: rgba(87, 90, 123, .1);
    color: #575a7b
}

a.badge-secondary-soft:focus,
a.badge-secondary-soft:hover {
    background-color: rgba(87, 90, 123, .2);
    color: #575a7b
}

.badge-success-soft {
    background-color: rgba(44, 212, 162, .1);
    color: #2cd4a2
}

a.badge-success-soft:focus,
a.badge-success-soft:hover {
    background-color: rgba(44, 212, 162, .2);
    color: #2cd4a2
}

.badge-info-soft {
    background-color: rgba(23, 162, 184, .1);
    color: #17a2b8
}

a.badge-info-soft:focus,
a.badge-info-soft:hover {
    background-color: rgba(23, 162, 184, .2);
    color: #17a2b8
}

.badge-warning-soft {
    background-color: rgba(245, 192, 112, .1);
    color: #f5c070
}

a.badge-warning-soft:focus,
a.badge-warning-soft:hover {
    background-color: rgba(245, 192, 112, .2);
    color: #f5c070
}

.badge-danger-soft {
    background-color: rgba(249, 101, 91, .1);
    color: #f9655b
}

a.badge-danger-soft:focus,
a.badge-danger-soft:hover {
    background-color: rgba(249, 101, 91, .2);
    color: #f9655b
}

.badge-light-soft {
    background-color: rgba(243, 248, 255, .1);
    color: #f3f8ff
}

a.badge-light-soft:focus,
a.badge-light-soft:hover {
    background-color: rgba(243, 248, 255, .2);
    color: #f3f8ff
}

.badge-dark-soft {
    background-color: rgba(27, 22, 66, .1);
    color: #1b1642
}

a.badge-dark-soft:focus,
a.badge-dark-soft:hover {
    background-color: rgba(27, 22, 66, .2);
    color: #1b1642
}

.badge-primary-dark-soft {
    background-color: rgba(86, 76, 175, .1);
    color: #564caf
}

a.badge-primary-dark-soft:focus,
a.badge-primary-dark-soft:hover {
    background-color: rgba(86, 76, 175, .2);
    color: #564caf
}

.badge-primary-light-soft {
    background-color: rgba(104, 125, 237, .1);
    color: #687ded
}

a.badge-primary-light-soft:focus,
a.badge-primary-light-soft:hover {
    background-color: rgba(104, 125, 237, .2);
    color: #687ded
}

.breadcrumb-item+.breadcrumb-item {
    display: flex;
    align-items: center
}

.breadcrumb-item+.breadcrumb-item::before {
    font-family: Feather
}

.breadcrumb-dark {
    background-color: #1b1642
}

.breadcrumb-dark .breadcrumb-item {
    color: #fff
}

.breadcrumb-dark .breadcrumb-item>a {
    color: #fff
}

.breadcrumb-dark .breadcrumb-item+.breadcrumb-item::before {
    color: #8d9aae
}

.btn:not([class*=btn-outline]) {
    border-color: transparent !important
}

.btn-primary:focus,
.btn-primary:hover {
    background-color: #e25e5e
}

.btn-secondary:focus,
.btn-secondary:hover {
    background-color: #f1d8d8
}

.btn-success:focus,
.btn-success:hover {
    background-color: #28c495
}

.btn-info:focus,
.btn-info:hover {
    background-color: #1592a6
}

.btn-warning:focus,
.btn-warning:hover {
    background-color: #f4b85d
}

.btn-danger:focus,
.btn-danger:hover {
    background-color: #f85347
}

.btn-light:focus,
.btn-light:hover {
    background-color: #dfecff
}

.btn-dark:focus,
.btn-dark:hover {
    background-color: #151133
}

.btn-primary-dark:focus,
.btn-primary-dark:hover {
    background-color: #4f46a1
}

.btn-primary-light:focus,
.btn-primary-light:hover {
    background-color: #566deb
}

.btn-white {
    color: #1b1642;
    background-color: #fff;
    border-color: rgba(black, 0.1);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    /* -webkit-box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .02); */
    /* box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 10px 24px rgba(27, 22, 66, .02); */
    color: #6658ea
}

.btn-white:hover {
    color: #1b1642;
    background-color: #ececec;
    border-color: #e6e6e6
}

.btn-white.focus,
.btn-white:focus {
    color: #1b1642;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(221, 220, 227, .5)
}

.btn-white.disabled,
.btn-white:disabled {
    color: #1b1642;
    background-color: #fff;
    border-color: #fff
}

.btn-white:not(:disabled):not(.disabled).active,
.btn-white:not(:disabled):not(.disabled):active,
.show>.btn-white.dropdown-toggle {
    color: #1b1642;
    background-color: #e6e6e6;
    border-color: #dfdfdf
}

.btn-white:not(:disabled):not(.disabled).active:focus,
.btn-white:not(:disabled):not(.disabled):active:focus,
.show>.btn-white.dropdown-toggle:focus {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(221, 220, 227, .5)
}

.btn-white:active,
.btn-white:focus,
.btn-white:hover {
    background-color: #fff;
    color: #5546e8
}

.btn-white:not(:disabled):not(.disabled).active,
.btn-white:not(:disabled):not(.disabled):active {
    background-color: #fff;
    color: #5546e8
}

.btn-gray-200 {
    color: #1b1642;
    background-color: #eaf2fe;
    border-color: #eaf2fe;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06);
    color: #5546e8
}

.btn-gray-200:hover {
    color: #1b1642;
    background-color: #c5dbfc;
    border-color: #b9d4fc
}

.btn-gray-200.focus,
.btn-gray-200:focus {
    color: #1b1642;
    background-color: #c5dbfc;
    border-color: #b9d4fc;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(203, 209, 226, .5)
}

.btn-gray-200.disabled,
.btn-gray-200:disabled {
    color: #1b1642;
    background-color: #eaf2fe;
    border-color: #eaf2fe
}

.btn-gray-200:not(:disabled):not(.disabled).active,
.btn-gray-200:not(:disabled):not(.disabled):active,
.show>.btn-gray-200.dropdown-toggle {
    color: #1b1642;
    background-color: #b9d4fc;
    border-color: #adccfb
}

.btn-gray-200:not(:disabled):not(.disabled).active:focus,
.btn-gray-200:not(:disabled):not(.disabled):active:focus,
.show>.btn-gray-200.dropdown-toggle:focus {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(203, 209, 226, .5)
}

.btn-gray-200:active,
.btn-gray-200:focus,
.btn-gray-200:hover {
    color: #5546e8
}

.btn-gray-200:not(:disabled):not(.disabled).active,
.btn-gray-200:not(:disabled):not(.disabled):active {
    color: #5546e8
}

.btn-gray-300 {
    color: #1b1642;
    background-color: #dce0f3;
    border-color: #dce0f3;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06);
    color: #5546e8
}

.btn-gray-300:hover {
    color: #1b1642;
    background-color: #c0c7e9;
    border-color: #b6bee6
}

.btn-gray-300.focus,
.btn-gray-300:focus {
    color: #1b1642;
    background-color: #c0c7e9;
    border-color: #b6bee6;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(191, 194, 216, .5)
}

.btn-gray-300.disabled,
.btn-gray-300:disabled {
    color: #1b1642;
    background-color: #dce0f3;
    border-color: #dce0f3
}

.btn-gray-300:not(:disabled):not(.disabled).active,
.btn-gray-300:not(:disabled):not(.disabled):active,
.show>.btn-gray-300.dropdown-toggle {
    color: #fff;
    background-color: #b6bee6;
    border-color: #adb6e3
}

.btn-gray-300:not(:disabled):not(.disabled).active:focus,
.btn-gray-300:not(:disabled):not(.disabled):active:focus,
.show>.btn-gray-300.dropdown-toggle:focus {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(191, 194, 216, .5)
}

.btn-gray-300:active,
.btn-gray-300:focus,
.btn-gray-300:hover {
    color: #5546e8
}

.btn-gray-300:not(:disabled):not(.disabled).active,
.btn-gray-300:not(:disabled):not(.disabled):active {
    color: #5546e8
}

.btn-circle {
    padding-left: .75rem;
    padding-right: .75rem;
    border-radius: 50%
}

.btn-circle>* {
    display: block;
    width: calc(1.125rem * 1.6);
    line-height: inherit
}

.btn-circle.btn-lg,
.btn-group-lg>.btn-circle.btn {
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 50%
}

.btn-circle.btn-lg>*,
.btn-group-lg>.btn-circle.btn>* {
    width: calc(1.125rem * 1.6)
}

.btn-circle.btn-sm,
.btn-group-sm>.btn-circle.btn {
    padding-left: .5rem;
    padding-right: .5rem;
    border-radius: 50%
}

.btn-circle.btn-sm>*,
.btn-group-sm>.btn-circle.btn>* {
    width: calc(.875rem * 1.6)
}

.btn-link {
    font-weight: 500
}

.btn-group {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06)
}

.btn-group .btn {
    box-shadow: none
}

.card {
    box-shadow: 0 0 24px rgba(27, 22, 66, .04), 0 44px 74px rgba(27, 22, 66, .06)
}

.card-footer {
    background-color: inherit
}

.card-body {
    padding: 2rem
}

.card-footer {
    padding-left: 2rem;
    padding-right: 2rem
}

.card-lg .card-body {
    padding: 3rem
}

.card-lg .card-footer {
    padding-left: 3rem;
    padding-right: 3rem
}

@media (min-width:992px) {
    .card-body {
        padding: 3rem
    }

    .card-footer {
        padding-left: 3rem;
        padding-right: 3rem
    }

    .card-lg .card-body {
        padding: 5rem
    }

    .card-lg .card-footer {
        padding-left: 5rem;
        padding-right: 5rem
    }

    .card-sm .card-body {
        padding: 2rem
    }

    .card-sm .card-footer {
        padding-left: 2rem;
        padding-right: 2rem
    }
}

.card-img-overlay {
    top: auto
}

.card-flush {
    background-color: transparent;
    box-shadow: none
}

.card-flush .card-body {
    padding-left: 0;
    padding-right: 0
}

@media (min-width:768px) {
    .card-img-slider {
        height: 100%;
        width: 100%
    }

    .card-img-slider * {
        height: inherit !important;
        width: inherit !important
    }
}

@media (min-width:768px) {
    .card-stack {
        display: grid;
        grid-auto-rows: 1fr
    }

    .card-stack-item {
        position: -webkit-sticky;
        position: sticky;
        top: 140px
    }

    .card-stack-item>.card {
        height: 100%;
        transform-origin: top center
    }

    .card-stack-item>.card>.row {
        height: inherit
    }
}

.collapse-toggle {
    display: flex
}

.collapse-toggle::after {
    content: "\e9b1";
    align-self: flex-start;
    margin-left: auto;
    font-family: Feather;
    color: #f5c070;
    transform-origin: center center;
    transition: all .2s ease-in-out
}

.collapse-toggle[aria-expanded=true]::after {
    transform: rotate(45deg)
}

.timeline-radio {
    display: flex
}

.timeline-radio-input {
    display: none
}

.timeline-radio-input:checked~.timeline-radio-label {
    opacity: .2
}

.timeline-radio-input:checked+.timeline-radio-label {
    opacity: 1
}

.timeline-radio-input:checked+.timeline-radio-label::before {
    box-shadow: 0 0 0 5px currentColor
}

.timeline-radio-label {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    pointer-events: none
}

.timeline-radio-label:first-of-type {
    width: 20px
}

.timeline-radio-label>small {
    white-space: nowrap
}

.timeline-radio-label:not(:first-of-type)>small,
.timeline-radio-label:not(:last-of-type)>small {
    transform: translateX(50%) translateX(-10px)
}

.timeline-radio-label::after,
.timeline-radio-label::before {
    content: "";
    display: block;
    cursor: pointer
}

.timeline-radio-label::before {
    z-index: 1;
    width: 20px;
    height: 20px;
    margin-bottom: 1rem;
    background-color: #fff;
    box-shadow: inset 0 0 0 20px currentColor;
    border-radius: 50%;
    pointer-events: auto
}

.timeline-radio-label::after {
    position: absolute;
    top: calc((20px - 5px)/ 2);
    right: 0;
    width: 100%;
    border-top: 5px solid currentColor
}

.timeline-radio-primary .timeline-radio-label::after,
.timeline-radio-primary .timeline-radio-label::before {
    color: #6658ea
}

.timeline-radio-secondary .timeline-radio-label::after,
.timeline-radio-secondary .timeline-radio-label::before {
    color: #575a7b
}

.timeline-radio-success .timeline-radio-label::after,
.timeline-radio-success .timeline-radio-label::before {
    color: #2cd4a2
}

.timeline-radio-info .timeline-radio-label::after,
.timeline-radio-info .timeline-radio-label::before {
    color: #17a2b8
}

.timeline-radio-warning .timeline-radio-label::after,
.timeline-radio-warning .timeline-radio-label::before {
    color: #f5c070
}

.timeline-radio-danger .timeline-radio-label::after,
.timeline-radio-danger .timeline-radio-label::before {
    color: #f9655b
}

.timeline-radio-light .timeline-radio-label::after,
.timeline-radio-light .timeline-radio-label::before {
    color: #f3f8ff
}

.timeline-radio-dark .timeline-radio-label::after,
.timeline-radio-dark .timeline-radio-label::before {
    color: #1b1642
}

.timeline-radio-primary-dark .timeline-radio-label::after,
.timeline-radio-primary-dark .timeline-radio-label::before {
    color: #564caf
}

.timeline-radio-primary-light .timeline-radio-label::after,
.timeline-radio-primary-light .timeline-radio-label::before {
    color: #687ded
}

.custom-switch .custom-control-label::before {
    top: calc((1.8rem - 1.5rem)/ 2);
    height: 1.5rem
}

.custom-switch .custom-control-label::after {
    left: -3.0625rem;
    background-color: #fff
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    transform: translateX(1rem);
    background-color: #fff
}

.custom-control-dark.custom-switch .custom-control-label::before {
    background-color: rgba(255, 255, 255, .1)
}

.custom-switch .custom-control-input:checked~.custom-control-label::before {
    background-color: #6658ea
}

.nav-tabs .nav-link {
    padding: 0 0 1.3125rem;
    border-width: 0 0 3px 0
}

.nav-tabs .nav-item+.nav-item {
    margin-left: 2rem
}

.nav-dark .nav-link {
    color: #8d9aae
}

.nav-dark .nav-link:hover {
    color: #fff
}

.nav-dark .nav-link.active {
    color: #fff
}

.dropdown-menu {
    padding: 2rem 2rem
}

.dropdown-menu-auto {
    min-width: auto
}

.dropdown-menu-lg {
    min-width: 24rem
}

.dropdown-menu-xl {
    min-width: 48rem
}

.dropdown-menu-flush {
    padding: 0;
    background-color: transparent;
    box-shadow: none
}

.dropdown-menu-col {
    background-color: #fff;
    border-radius: 1rem 0 1rem 0;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06)
}

.dropdown-menu-body {
    padding: 2rem 2rem
}

.dropdown-menu-footer {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    margin-left: 2rem;
    margin-right: 2rem;
    border-top: 1px solid #eaf2fe
}

.dropdown-item+.dropdown-item,
.dropdown-item+.dropdown-link,
.dropdown-link+.dropdown-item,
.dropdown-link+.dropdown-link {
    margin-top: .75rem
}

.dropdown-header {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 1.25rem;
    font-size: .875rem
}

.dropdown-item+.dropdown-header,
.dropdown-link+.dropdown-header {
    margin-top: 2.5rem
}

.form-control {
    background-clip: border-box
}

.form-control-underline {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    border-radius: 0;
    border-width: 0;
    border-bottom-width: 1px;
    border-bottom-color: #eaf2fe
}

.form-control-underline:focus {
    background-color: transparent;
    border-bottom-color: #6658ea
}

.form-control.is-invalid,
.form-control.is-valid {
    background-size: 1.5rem 1.5rem
}

.custom-select.is-invalid,
.custom-select.is-valid {
    background-size: 14px 8px, 1.5rem 1.5rem
}

.offset-n1 {
    margin-left: -8.33333%
}

.offset-n2 {
    margin-left: -16.66667%
}

.offset-n3 {
    margin-left: -25%
}

.offset-n4 {
    margin-left: -33.33333%
}

.offset-n5 {
    margin-left: -41.66667%
}

.offset-n6 {
    margin-left: -50%
}

.offset-n7 {
    margin-left: -58.33333%
}

.offset-n8 {
    margin-left: -66.66667%
}

.offset-n9 {
    margin-left: -75%
}

.offset-n10 {
    margin-left: -83.33333%
}

.offset-n11 {
    margin-left: -91.66667%
}

.offset-n12 {
    margin-left: -100%
}

@media (min-width:576px) {
    .offset-sm-n1 {
        margin-left: -8.33333%
    }

    .offset-sm-n2 {
        margin-left: -16.66667%
    }

    .offset-sm-n3 {
        margin-left: -25%
    }

    .offset-sm-n4 {
        margin-left: -33.33333%
    }

    .offset-sm-n5 {
        margin-left: -41.66667%
    }

    .offset-sm-n6 {
        margin-left: -50%
    }

    .offset-sm-n7 {
        margin-left: -58.33333%
    }

    .offset-sm-n8 {
        margin-left: -66.66667%
    }

    .offset-sm-n9 {
        margin-left: -75%
    }

    .offset-sm-n10 {
        margin-left: -83.33333%
    }

    .offset-sm-n11 {
        margin-left: -91.66667%
    }

    .offset-sm-n12 {
        margin-left: -100%
    }
}

@media (min-width:768px) {
    .offset-md-n1 {
        margin-left: -8.33333%
    }

    .offset-md-n2 {
        margin-left: -16.66667%
    }

    .offset-md-n3 {
        margin-left: -25%
    }

    .offset-md-n4 {
        margin-left: -33.33333%
    }

    .offset-md-n5 {
        margin-left: -41.66667%
    }

    .offset-md-n6 {
        margin-left: -50%
    }

    .offset-md-n7 {
        margin-left: -58.33333%
    }

    .offset-md-n8 {
        margin-left: -66.66667%
    }

    .offset-md-n9 {
        margin-left: -75%
    }

    .offset-md-n10 {
        margin-left: -83.33333%
    }

    .offset-md-n11 {
        margin-left: -91.66667%
    }

    .offset-md-n12 {
        margin-left: -100%
    }
}

@media (min-width:992px) {
    .offset-lg-n1 {
        margin-left: -8.33333%
    }

    .offset-lg-n2 {
        margin-left: -16.66667%
    }

    .offset-lg-n3 {
        margin-left: -25%
    }

    .offset-lg-n4 {
        margin-left: -33.33333%
    }

    .offset-lg-n5 {
        margin-left: -41.66667%
    }

    .offset-lg-n6 {
        margin-left: -50%
    }

    .offset-lg-n7 {
        margin-left: -58.33333%
    }

    .offset-lg-n8 {
        margin-left: -66.66667%
    }

    .offset-lg-n9 {
        margin-left: -75%
    }

    .offset-lg-n10 {
        margin-left: -83.33333%
    }

    .offset-lg-n11 {
        margin-left: -91.66667%
    }

    .offset-lg-n12 {
        margin-left: -100%
    }
}

@media (min-width:1200px) {
    .offset-xl-n1 {
        margin-left: -8.33333%
    }

    .offset-xl-n2 {
        margin-left: -16.66667%
    }

    .offset-xl-n3 {
        margin-left: -25%
    }

    .offset-xl-n4 {
        margin-left: -33.33333%
    }

    .offset-xl-n5 {
        margin-left: -41.66667%
    }

    .offset-xl-n6 {
        margin-left: -50%
    }

    .offset-xl-n7 {
        margin-left: -58.33333%
    }

    .offset-xl-n8 {
        margin-left: -66.66667%
    }

    .offset-xl-n9 {
        margin-left: -75%
    }

    .offset-xl-n10 {
        margin-left: -83.33333%
    }

    .offset-xl-n11 {
        margin-left: -91.66667%
    }

    .offset-xl-n12 {
        margin-left: -100%
    }
}

@media (min-width:576px) and (max-width:767.98px) {
    .container-lg {
        max-width: 90%
    }
}

@media (min-width:768px) and (max-width:991.98px) {
    .container-lg {
        max-width: 95%
    }
}

.img-fluid>* {
    width: 100%
}

.img-grid {
    display: flex;
    flex-wrap: wrap
}

.img-grid-1 .img-grid-item:nth-child(1) {
    width: 60%;
    margin-left: 20%
}

.img-grid-1 .img-grid-item:nth-child(2) {
    width: 15%;
    margin-top: 15%;
    margin-left: -80%
}

.img-grid-1 .img-grid-item:nth-child(3) {
    width: 20%;
    margin-top: 10%;
    margin-left: 60%
}

.img-grid-1 .img-grid-item:nth-child(4) {
    width: 20%;
    margin-top: 40%;
    margin-left: -85%
}

.img-grid-1 .img-grid-item:nth-child(5) {
    width: 15%;
    margin-top: 40%;
    margin-left: 55%
}

.img-grid-1 .img-grid-item:nth-child(6) {
    width: 15%;
    margin-top: 5%;
    margin-left: 25%
}

.img-grid-1 .img-grid-item:nth-child(7) {
    width: 20%;
    margin-top: -10%;
    margin-left: 10%
}

.img-grid-2 .img-grid-item:nth-child(1) {
    width: 55%;
    margin-top: 0;
    margin-left: 35%;
    z-index: 2
}

.img-grid-2 .img-grid-item:nth-child(2) {
    width: 55%;
    margin-top: -30%;
    margin-left: 0;
    z-index: 1
}

.img-grid-2 .img-grid-item:nth-child(3) {
    width: 60%;
    margin-top: -35%;
    margin-left: 40%;
    z-index: 0
}

.img-grid-3 .img-grid-item:nth-child(1) {
    width: 80%;
    margin-top: 15%;
    margin-left: 10%
}

.img-grid-3 .img-grid-item:nth-child(2) {
    width: 30%;
    margin-left: -20%
}

.img-grid-3 .img-grid-item:nth-child(3) {
    width: 25%;
    margin-top: 55%;
    margin-left: -40%
}

.img-grid-3 .img-grid-item:nth-child(4) {
    width: 100%;
    margin-top: 75%;
    margin-left: -85%
}

@media (min-width:768px) {
    .img-grid-3 .img-grid-item:nth-child(4) {
        width: 40%;
        margin-top: 40%
    }
}

.img-grid-4 .img-grid-item:nth-child(1) {
    width: 70%;
    margin-top: 0;
    margin-left: 30%;
    z-index: 2
}

.img-grid-4 .img-grid-item:nth-child(2) {
    width: 75%;
    margin-top: -15%;
    margin-left: 0;
    z-index: 0
}

.img-grid-4 .img-grid-item:nth-child(3) {
    width: 40%;
    margin-top: -25%;
    margin-left: 50%;
    z-index: 1
}

.input-group .btn {
    box-shadow: none
}

.input-group .input-group-append .btn-white::before {
    content: '';
    position: absolute;
    top: .75rem;
    bottom: .75rem;
    left: 0;
    border-left: 1px solid #dce0f3
}

.input-group-prepend .input-group-text {
    padding-right: 0
}

.input-group-append .input-group-text {
    padding-left: 0
}

.list-group-flush .list-group-item {
    padding-left: 0;
    padding-right: 0;
    border-width: 0
}

.list-group-flush .list-group-item+.list-group-item {
    border-top-width: 1px
}

.list-group-flush .list-group-item.active {
    background-color: transparent
}

.list-group-flush .list-group-item+.list-group-item.active {
    margin-top: 0
}

.list-group-lg .list-group-item {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem
}

.list-group-sm .list-group-item {
    padding-top: 1rem;
    padding-bottom: 1rem
}

.list-group-hover .list-group-item {
    position: relative;
    transition: all .2s ease-in-out;
    transition-property: border-color
}

.list-group-hover .list-group-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: calc(100% + 5rem);
    border-radius: 1rem 0 1rem 0;
    box-shadow: 0 0 24px rgba(27, 22, 66, .04), 0 44px 74px rgba(27, 22, 66, .06);
    transition: all .2s ease-in-out;
    transform: translateX(-50%);
    opacity: 0
}

.list-group-hover .list-group-item:hover,
.list-group-hover .list-group-item:hover+.list-group-item[href] {
    border-top-color: transparent
}

.list-group-hover .list-group-item:hover::before {
    opacity: 1
}

.modal-close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem
}

.navbar {
    transition: all .2s ease-in-out;
    transition-property: box-shadow
}

.navbar::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-width: 0;
    border-top: 4px solid;
    -o-border-image: linear-gradient(to right, #575a7b, #575a7b 20%, #f9655b 20%, #f9655b 40%, #f5c070 40%, #f5c070 60%, #6658ea 60%, #6658ea 80%, #fcc 80%) 1;
       border-image: linear-gradient(to right, #575a7b, #575a7b 20%, #f9655b 20%, #f9655b 40%, #f5c070 40%, #f5c070 60%, #6658ea 60%, #6658ea 80%, #fcc 80%) 1
}

.navbar-brand {
    font-family: "DM Serif Display", serif
}

.navbar-toggler {
    padding-left: 0;
    padding-right: 0
}

.navbar-nav .dropright>.dropdown-toggle {
    display: flex
}

.navbar-nav .dropright>.dropdown-toggle::after {
    margin-left: auto;
    font-family: Feather;
    color: #8d9aae;
    content: "\e930"
}

.navbar-nav .dropdown-positioner {
    position: absolute;
    z-index: 1000
}

.navbar-nav .dropdown-positioner>.dropdown-menu {
    position: static
}

@media (max-width:991.98px) {
    .navbar.fixed-top {
        max-height: 100vh;
        overflow: auto
    }

    .navbar-nav:first-child {
        margin-top: 2rem
    }

    .navbar-nav>.nav-item {
        border-top: 1px solid rgba(27, 22, 66, .065)
    }

    .navbar-nav>.nav-item>.nav-link {
        display: flex;
        padding-top: .75rem;
        padding-bottom: .75rem
    }

    .navbar-nav>.dropdown>.dropdown-toggle::after {
        margin-left: auto;
        font-family: Feather;
        color: #8d9aae;
        content: "\e92e";
        transform-origin: center center
    }

    .navbar-nav>.dropdown.show>.dropdown-toggle::after {
        transform: rotate(180deg)
    }

    .navbar-nav>.dropdown .dropdown-menu,
    .navbar-nav>.dropdown .dropdown-menu-col {
        min-width: 0;
        background-color: transparent;
        box-shadow: none
    }

    .navbar-nav>.dropdown .dropdown-menu {
        padding: .75rem 0 1.5rem
    }

    .navbar-nav>.dropdown .dropdown-menu-col {
        background-color: transparent !important
    }

    .navbar-nav>.dropdown .dropdown-menu-col .text-white {
        color: #1b1642 !important
    }

    .navbar-nav>.dropdown .dropdown-menu-body {
        padding: 0
    }

    .navbar-nav .dropright>.dropdown-toggle::after {
        content: "\e92e";
        transform-origin: center center
    }

    .navbar-nav .dropright.show>.dropdown-toggle::after {
        transform: rotate(180deg)
    }

    .navbar-nav .dropright .dropdown-menu {
        padding: .75rem 0 0 .75rem
    }

    .navbar-nav .dropdown-positioner {
        position: relative !important;
        transform: none !important
    }

    .navbar-nav .text-gray-500 {
        color: #575a7b !important
    }
}

@media (min-width:992px) {
    .navbar-nav .dropright {
        width: auto;
        margin-left: -2rem;
        margin-right: -2rem;
        padding-left: 2rem;
        padding-right: 2rem
    }

    .navbar-nav .dropdown-menu {
        display: none;
        opacity: 0;
        transform: translateY(10px);
        transition: all .2s ease-in-out;
        transition-property: opacity, transform
    }

    .navbar-nav .dropdown-menu.showing {
        display: block
    }

    .navbar-nav .dropdown-menu.show {
        display: block;
        opacity: 1;
        transform: translateY(0)
    }
}

.navbar-reveal {
    transition: all .2s ease-in-out
}

.pagination .page-item:first-child .page-link {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem
}

.pagination .page-item:last-child .page-link {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem
}

html {
    overflow-x: hidden
}

@media (hover:none) {
    body {
        overflow-x: hidden
    }
}

.table-flush tbody td:first-child,
.table-flush tbody th:first-child,
.table-flush tfoot td:first-child,
.table-flush thead th:first-child {
    padding-left: 0
}

.table-flush tbody td:last-child,
.table-flush tbody th:last-child,
.table-flush tfoot td:last-child,
.table-flush thead th:last-child {
    padding-right: 0
}

.table th {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-weight: 400
}

.table thead th {
    border-bottom: 0;
    text-transform: uppercase;
    font-size: .875rem;
    color: #8d9aae
}

.table tfoot td {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
}

.table-clickable [data-href]:hover {
    cursor: pointer
}

.table-clickable [data-href]:hover .table-clickable-hover {
    color: #5546e8
}

@media (max-width:767.98px) {

    .table-features.table-features-alt td:nth-child(2),
    .table-features.table-features-alt th:nth-child(2) {
        display: none
    }

    .table-features:not(.table-features-alt) td:nth-child(3),
    .table-features:not(.table-features-alt) th:nth-child(3) {
        display: none
    }
}

.toast-header {
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-bottom-width: 1px
}

.toast-positioner {
    position: fixed;
    z-index: 1030;
    bottom: 1.5rem;
    width: 100%;
    max-width: 350px
}

.toast-positioner-left {
    left: 1.5rem
}

.toast-positioner-right {
    right: 1.5rem
}

@media (max-width:991.98px) {

    .h1,
    h1 {
        font-size: 1.75rem
    }

    .h2,
    h2 {
        font-size: 1.5rem
    }

    .h3,
    h3 {
        font-size: 1.125rem
    }

    .display-4 {
        font-size: 2.25rem
    }

    .display-3 {
        font-size: 2.5rem
    }

    .display-2 {
        font-size: 3rem
    }

    .display-1 {
        font-size: 3rem
    }

    .lead {
        font-size: 1.25rem
    }
}

.h1,
.h2,
h1,
h2 {
    line-height: 1.32
}

.h3,
h2 {
    line-height: 1.4
}

.h4,
.h5,
.h6,
h4,
h5,
h6 {
    line-height: 1.6
}

.h6.text-uppercase,
.table thead th,
h6.text-uppercase {
    font-family: "DM Sans", sans-serif;
    font-weight: 700;
    letter-spacing: .18em
}

.display-1.font-family-sans-serif,
.display-2.font-family-sans-serif,
.display-3.font-family-sans-serif,
.display-4.font-family-sans-serif {
    letter-spacing: -.02em
}

.h1.font-family-sans-serif,
h1.font-family-sans-serif {
    letter-spacing: -.02em
}

.h2.font-family-sans-serif,
.h3.font-family-sans-serif,
.h4.font-family-sans-serif,
h2.font-family-sans-serif,
h3.font-family-sans-serif,
h4.font-family-sans-serif {
    letter-spacing: -.01em
}

.list-checked {
    padding-left: 0;
    text-align: left;
    list-style-type: none
}

.list-checked>li {
    position: relative;
    padding-left: 2.25rem
}

.list-checked>li+li {
    margin-top: .75rem
}

.list-checked>li::before {
    position: absolute;
    top: 0;
    left: 0;
    font-family: Feather;
    content: "\e92b"
}

.list-checked-primary>li::before {
    color: #6658ea
}

.list-checked-secondary>li::before {
    color: #575a7b
}

.list-checked-success>li::before {
    color: #2cd4a2
}

.list-checked-info>li::before {
    color: #17a2b8
}

.list-checked-warning>li::before {
    color: #f5c070
}

.list-checked-danger>li::before {
    color: #f9655b
}

.list-checked-light>li::before {
    color: #f3f8ff
}

.list-checked-dark>li::before {
    color: #1b1642
}

.list-checked-primary-dark>li::before {
    color: #564caf
}

.list-checked-primary-light>li::before {
    color: #687ded
}

.list-checked-circle>li::before {
    top: calc((1.6 * 1rem - 1.25rem));
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    line-height: 1.25rem;
    text-align: center;
    font-size: .75rem;
    border-radius: 50%
}

.list-checked-circle.list-checked-primary>li::before {
    background-color: #6658ea;
    color: #fff
}

.list-checked-circle.list-checked-secondary>li::before {
    background-color: #575a7b;
    color: #fff
}

.list-checked-circle.list-checked-success>li::before {
    background-color: #2cd4a2;
    color: #fff
}

.list-checked-circle.list-checked-info>li::before {
    background-color: #17a2b8;
    color: #fff
}

.list-checked-circle.list-checked-warning>li::before {
    background-color: #f5c070;
    color: #fff
}

.list-checked-circle.list-checked-danger>li::before {
    background-color: #f9655b;
    color: #fff
}

.list-checked-circle.list-checked-light>li::before {
    background-color: #f3f8ff;
    color: #1b1642
}

.list-checked-circle.list-checked-dark>li::before {
    background-color: #1b1642;
    color: #fff
}

.list-checked-circle.list-checked-primary-dark>li::before {
    background-color: #564caf;
    color: #fff
}

.list-checked-circle.list-checked-primary-light>li::before {
    background-color: #687ded;
    color: #fff
}

.hr-sm {
    max-width: 5rem;
    margin-top: 0;
    border-top-width: 5px
}

strong {
    font-weight: 500
}

ol,
ul {
    margin-bottom: 2rem
}

a,
button {
    outline: 0 !important
}

.bg-dark-50 {
    background-color: rgba(27, 22, 66, .5) !important
}

.bg-gray-900 {
    background-color: #2b265a !important
}

.bg-gray-800 {
    background-color: #342e6c !important
}

.bg-gray-200 {
    background-color: #eaf2fe !important
}

.bg-cover {
    background: no-repeat center center/cover
}

.bg-gradient-light {
    background-image: linear-gradient(to bottom, rgba(243, 248, 255, 0), #f3f8ff)
}

.bg-light-boxed-right {
    background: no-repeat left center linear-gradient(to right, #f3f8ff)
}

@media (min-width:1200px) {
    .bg-light-boxed-right {
        background-size: calc(1140px + (100vw - 1140px)/ 2) 100%
    }
}

.bg-checkered {
    background-repeat: no-repeat, repeat, repeat;
    background-image: radial-gradient(transparent, transparent 50%, #fff), linear-gradient(to right, transparent, transparent 32px, #eaf2fe 32px, #eaf2fe), linear-gradient(to bottom, transparent, transparent 32px, #eaf2fe 32px, #eaf2fe);
    background-size: 100% 100%, calc(32px + 1px) calc(32px + 1px), calc(32px + 1px) calc(32px + 1px)
}

.border-2 {
    border-width: 2px !important
}

.border-top-2 {
    border-top-width: 2px !important
}

.border-right-2 {
    border-right-width: 2px !important
}

.border-bottom-2 {
    border-bottom-width: 2px !important
}

.border-left-2 {
    border-left-width: 2px !important
}

.border-3 {
    border-width: 3px !important
}

.border-top-3 {
    border-top-width: 3px !important
}

.border-right-3 {
    border-right-width: 3px !important
}

.border-bottom-3 {
    border-bottom-width: 3px !important
}

.border-left-3 {
    border-left-width: 3px !important
}

.border-4 {
    border-width: 4px !important
}

.border-top-4 {
    border-top-width: 4px !important
}

.border-right-4 {
    border-right-width: 4px !important
}

.border-bottom-4 {
    border-bottom-width: 4px !important
}

.border-left-4 {
    border-left-width: 4px !important
}

.border-5 {
    border-width: 5px !important
}

.border-top-5 {
    border-top-width: 5px !important
}

.border-right-5 {
    border-right-width: 5px !important
}

.border-bottom-5 {
    border-bottom-width: 5px !important
}

.border-left-5 {
    border-left-width: 5px !important
}

.border-6 {
    border-width: 6px !important
}

.border-top-6 {
    border-top-width: 6px !important
}

.border-right-6 {
    border-right-width: 6px !important
}

.border-bottom-6 {
    border-bottom-width: 6px !important
}

.border-left-6 {
    border-left-width: 6px !important
}

.border-7 {
    border-width: 7px !important
}

.border-top-7 {
    border-top-width: 7px !important
}

.border-right-7 {
    border-right-width: 7px !important
}

.border-bottom-7 {
    border-bottom-width: 7px !important
}

.border-left-7 {
    border-left-width: 7px !important
}

@media (min-width:576px) {
    .border-sm-0 {
        border-width: 0 !important
    }

    .border-top-sm-0 {
        border-top-width: 0 !important
    }

    .border-right-sm-0 {
        border-right-width: 0 !important
    }

    .border-bottom-sm-0 {
        border-bottom-width: 0 !important
    }

    .border-left-sm-0 {
        border-left-width: 0 !important
    }

    .border-sm {
        border: 1px solid #eaf2fe
    }

    .border-top-sm {
        border-top: 1px solid #eaf2fe
    }

    .border-right-sm {
        border-right: 1px solid #eaf2fe
    }

    .border-bottom-sm {
        border-bottom: 1px solid #eaf2fe
    }

    .border-left-sm {
        border-left: 1px solid #eaf2fe
    }

    .border-sm-2 {
        border-width: 2px !important
    }

    .border-top-sm-2 {
        border-top-width: 2px !important
    }

    .border-right-sm-2 {
        border-right-width: 2px !important
    }

    .border-bottom-sm-2 {
        border-bottom-width: 2px !important
    }

    .border-left-sm-2 {
        border-left-width: 2px !important
    }

    .border-sm-3 {
        border-width: 3px !important
    }

    .border-top-sm-3 {
        border-top-width: 3px !important
    }

    .border-right-sm-3 {
        border-right-width: 3px !important
    }

    .border-bottom-sm-3 {
        border-bottom-width: 3px !important
    }

    .border-left-sm-3 {
        border-left-width: 3px !important
    }

    .border-sm-4 {
        border-width: 4px !important
    }

    .border-top-sm-4 {
        border-top-width: 4px !important
    }

    .border-right-sm-4 {
        border-right-width: 4px !important
    }

    .border-bottom-sm-4 {
        border-bottom-width: 4px !important
    }

    .border-left-sm-4 {
        border-left-width: 4px !important
    }

    .border-sm-5 {
        border-width: 5px !important
    }

    .border-top-sm-5 {
        border-top-width: 5px !important
    }

    .border-right-sm-5 {
        border-right-width: 5px !important
    }

    .border-bottom-sm-5 {
        border-bottom-width: 5px !important
    }

    .border-left-sm-5 {
        border-left-width: 5px !important
    }

    .border-sm-6 {
        border-width: 6px !important
    }

    .border-top-sm-6 {
        border-top-width: 6px !important
    }

    .border-right-sm-6 {
        border-right-width: 6px !important
    }

    .border-bottom-sm-6 {
        border-bottom-width: 6px !important
    }

    .border-left-sm-6 {
        border-left-width: 6px !important
    }

    .border-sm-7 {
        border-width: 7px !important
    }

    .border-top-sm-7 {
        border-top-width: 7px !important
    }

    .border-right-sm-7 {
        border-right-width: 7px !important
    }

    .border-bottom-sm-7 {
        border-bottom-width: 7px !important
    }

    .border-left-sm-7 {
        border-left-width: 7px !important
    }
}

@media (min-width:768px) {
    .border-md-0 {
        border-width: 0 !important
    }

    .border-top-md-0 {
        border-top-width: 0 !important
    }

    .border-right-md-0 {
        border-right-width: 0 !important
    }

    .border-bottom-md-0 {
        border-bottom-width: 0 !important
    }

    .border-left-md-0 {
        border-left-width: 0 !important
    }

    .border-md {
        border: 1px solid #eaf2fe
    }

    .border-top-md {
        border-top: 1px solid #eaf2fe
    }

    .border-right-md {
        border-right: 1px solid #eaf2fe
    }

    .border-bottom-md {
        border-bottom: 1px solid #eaf2fe
    }

    .border-left-md {
        border-left: 1px solid #eaf2fe
    }

    .border-md-2 {
        border-width: 2px !important
    }

    .border-top-md-2 {
        border-top-width: 2px !important
    }

    .border-right-md-2 {
        border-right-width: 2px !important
    }

    .border-bottom-md-2 {
        border-bottom-width: 2px !important
    }

    .border-left-md-2 {
        border-left-width: 2px !important
    }

    .border-md-3 {
        border-width: 3px !important
    }

    .border-top-md-3 {
        border-top-width: 3px !important
    }

    .border-right-md-3 {
        border-right-width: 3px !important
    }

    .border-bottom-md-3 {
        border-bottom-width: 3px !important
    }

    .border-left-md-3 {
        border-left-width: 3px !important
    }

    .border-md-4 {
        border-width: 4px !important
    }

    .border-top-md-4 {
        border-top-width: 4px !important
    }

    .border-right-md-4 {
        border-right-width: 4px !important
    }

    .border-bottom-md-4 {
        border-bottom-width: 4px !important
    }

    .border-left-md-4 {
        border-left-width: 4px !important
    }

    .border-md-5 {
        border-width: 5px !important
    }

    .border-top-md-5 {
        border-top-width: 5px !important
    }

    .border-right-md-5 {
        border-right-width: 5px !important
    }

    .border-bottom-md-5 {
        border-bottom-width: 5px !important
    }

    .border-left-md-5 {
        border-left-width: 5px !important
    }

    .border-md-6 {
        border-width: 6px !important
    }

    .border-top-md-6 {
        border-top-width: 6px !important
    }

    .border-right-md-6 {
        border-right-width: 6px !important
    }

    .border-bottom-md-6 {
        border-bottom-width: 6px !important
    }

    .border-left-md-6 {
        border-left-width: 6px !important
    }

    .border-md-7 {
        border-width: 7px !important
    }

    .border-top-md-7 {
        border-top-width: 7px !important
    }

    .border-right-md-7 {
        border-right-width: 7px !important
    }

    .border-bottom-md-7 {
        border-bottom-width: 7px !important
    }

    .border-left-md-7 {
        border-left-width: 7px !important
    }
}

@media (min-width:992px) {
    .border-lg-0 {
        border-width: 0 !important
    }

    .border-top-lg-0 {
        border-top-width: 0 !important
    }

    .border-right-lg-0 {
        border-right-width: 0 !important
    }

    .border-bottom-lg-0 {
        border-bottom-width: 0 !important
    }

    .border-left-lg-0 {
        border-left-width: 0 !important
    }

    .border-lg {
        border: 1px solid #eaf2fe
    }

    .border-top-lg {
        border-top: 1px solid #eaf2fe
    }

    .border-right-lg {
        border-right: 1px solid #eaf2fe
    }

    .border-bottom-lg {
        border-bottom: 1px solid #eaf2fe
    }

    .border-left-lg {
        border-left: 1px solid #eaf2fe
    }

    .border-lg-2 {
        border-width: 2px !important
    }

    .border-top-lg-2 {
        border-top-width: 2px !important
    }

    .border-right-lg-2 {
        border-right-width: 2px !important
    }

    .border-bottom-lg-2 {
        border-bottom-width: 2px !important
    }

    .border-left-lg-2 {
        border-left-width: 2px !important
    }

    .border-lg-3 {
        border-width: 3px !important
    }

    .border-top-lg-3 {
        border-top-width: 3px !important
    }

    .border-right-lg-3 {
        border-right-width: 3px !important
    }

    .border-bottom-lg-3 {
        border-bottom-width: 3px !important
    }

    .border-left-lg-3 {
        border-left-width: 3px !important
    }

    .border-lg-4 {
        border-width: 4px !important
    }

    .border-top-lg-4 {
        border-top-width: 4px !important
    }

    .border-right-lg-4 {
        border-right-width: 4px !important
    }

    .border-bottom-lg-4 {
        border-bottom-width: 4px !important
    }

    .border-left-lg-4 {
        border-left-width: 4px !important
    }

    .border-lg-5 {
        border-width: 5px !important
    }

    .border-top-lg-5 {
        border-top-width: 5px !important
    }

    .border-right-lg-5 {
        border-right-width: 5px !important
    }

    .border-bottom-lg-5 {
        border-bottom-width: 5px !important
    }

    .border-left-lg-5 {
        border-left-width: 5px !important
    }

    .border-lg-6 {
        border-width: 6px !important
    }

    .border-top-lg-6 {
        border-top-width: 6px !important
    }

    .border-right-lg-6 {
        border-right-width: 6px !important
    }

    .border-bottom-lg-6 {
        border-bottom-width: 6px !important
    }

    .border-left-lg-6 {
        border-left-width: 6px !important
    }

    .border-lg-7 {
        border-width: 7px !important
    }

    .border-top-lg-7 {
        border-top-width: 7px !important
    }

    .border-right-lg-7 {
        border-right-width: 7px !important
    }

    .border-bottom-lg-7 {
        border-bottom-width: 7px !important
    }

    .border-left-lg-7 {
        border-left-width: 7px !important
    }
}

@media (min-width:1200px) {
    .border-xl-0 {
        border-width: 0 !important
    }

    .border-top-xl-0 {
        border-top-width: 0 !important
    }

    .border-right-xl-0 {
        border-right-width: 0 !important
    }

    .border-bottom-xl-0 {
        border-bottom-width: 0 !important
    }

    .border-left-xl-0 {
        border-left-width: 0 !important
    }

    .border-xl {
        border: 1px solid #eaf2fe
    }

    .border-top-xl {
        border-top: 1px solid #eaf2fe
    }

    .border-right-xl {
        border-right: 1px solid #eaf2fe
    }

    .border-bottom-xl {
        border-bottom: 1px solid #eaf2fe
    }

    .border-left-xl {
        border-left: 1px solid #eaf2fe
    }

    .border-xl-2 {
        border-width: 2px !important
    }

    .border-top-xl-2 {
        border-top-width: 2px !important
    }

    .border-right-xl-2 {
        border-right-width: 2px !important
    }

    .border-bottom-xl-2 {
        border-bottom-width: 2px !important
    }

    .border-left-xl-2 {
        border-left-width: 2px !important
    }

    .border-xl-3 {
        border-width: 3px !important
    }

    .border-top-xl-3 {
        border-top-width: 3px !important
    }

    .border-right-xl-3 {
        border-right-width: 3px !important
    }

    .border-bottom-xl-3 {
        border-bottom-width: 3px !important
    }

    .border-left-xl-3 {
        border-left-width: 3px !important
    }

    .border-xl-4 {
        border-width: 4px !important
    }

    .border-top-xl-4 {
        border-top-width: 4px !important
    }

    .border-right-xl-4 {
        border-right-width: 4px !important
    }

    .border-bottom-xl-4 {
        border-bottom-width: 4px !important
    }

    .border-left-xl-4 {
        border-left-width: 4px !important
    }

    .border-xl-5 {
        border-width: 5px !important
    }

    .border-top-xl-5 {
        border-top-width: 5px !important
    }

    .border-right-xl-5 {
        border-right-width: 5px !important
    }

    .border-bottom-xl-5 {
        border-bottom-width: 5px !important
    }

    .border-left-xl-5 {
        border-left-width: 5px !important
    }

    .border-xl-6 {
        border-width: 6px !important
    }

    .border-top-xl-6 {
        border-top-width: 6px !important
    }

    .border-right-xl-6 {
        border-right-width: 6px !important
    }

    .border-bottom-xl-6 {
        border-bottom-width: 6px !important
    }

    .border-left-xl-6 {
        border-left-width: 6px !important
    }

    .border-xl-7 {
        border-width: 7px !important
    }

    .border-top-xl-7 {
        border-top-width: 7px !important
    }

    .border-right-xl-7 {
        border-right-width: 7px !important
    }

    .border-bottom-xl-7 {
        border-bottom-width: 7px !important
    }

    .border-left-xl-7 {
        border-left-width: 7px !important
    }
}

.border-transparent {
    border-color: transparent !important
}

.border-white-5 {
    border-color: rgba(255, 255, 255, .05) !important
}

.border-white-10 {
    border-color: rgba(255, 255, 255, .1) !important
}

.border-white-20 {
    border-color: rgba(255, 255, 255, .2) !important
}

.border-gray-300 {
    border-color: #dce0f3 !important
}

.border-gray-900 {
    border-color: #2b265a !important
}

.border-multicolor {
    border-width: 0;
    border-top: 4px solid;
    -o-border-image: linear-gradient(to right, #575a7b, #575a7b 20%, #f9655b 20%, #f9655b 40%, #f5c070 40%, #f5c070 60%, #6658ea 60%, #6658ea 80%, #fcc 80%) 1;
       border-image: linear-gradient(to right, #575a7b, #575a7b 20%, #f9655b 20%, #f9655b 40%, #f5c070 40%, #f5c070 60%, #6658ea 60%, #6658ea 80%, #fcc 80%) 1
}

.rounded-inherit {
    border-radius: inherit !important
}

.rounded-top-right {
    border-top-right-radius: 1rem !important
}

.rounded-bottom-right {
    border-bottom-right-radius: 1rem !important
}

.rounded-bottom-left {
    border-bottom-left-radius: 1rem !important
}

.rounded-top-left {
    border-top-left-radius: 1rem !important
}

.rounded-top-right-lg {
    border-top-right-radius: 2rem !important
}

.rounded-bottom-right-lg {
    border-bottom-right-radius: 2rem !important
}

.rounded-bottom-left-lg {
    border-bottom-left-radius: 2rem !important
}

.rounded-top-left-lg {
    border-top-left-radius: 2rem !important
}

.rounded-top-right-sm {
    border-top-right-radius: .5rem !important
}

.rounded-bottom-right-sm {
    border-bottom-right-radius: .5rem !important
}

.rounded-bottom-left-sm {
    border-bottom-left-radius: .5rem !important
}

.rounded-top-left-sm {
    border-top-left-radius: .5rem !important
}

.rounded-top-right-0 {
    border-top-right-radius: 0 !important
}

.rounded-bottom-right-0 {
    border-bottom-right-radius: 0 !important
}

.rounded-bottom-left-0 {
    border-bottom-left-radius: 0 !important
}

.rounded-top-left-0 {
    border-top-left-radius: 0 !important
}

.border-stripe {
    border-color: #6772e5 !important
}

.border-digital-ocean {
    border-color: #2485fd !important
}

.border-airbnb {
    border-color: #ff385c !important
}

.border-coinbase {
    border-color: #0081c9 !important
}

.border-netflix {
    border-color: #b81d24 !important
}

.border-dribbble {
    border-color: #da5988 !important
}

.border-pinterest {
    border-color: #cb2027 !important
}

.border-instagram {
    border-color: #3f5d87 !important
}

.lift {
    transition: box-shadow .25s ease, transform .25s ease
}

.lift:focus,
.lift:hover {
    box-shadow: 0 1rem 2.5rem rgba(27, 22, 66, .1), 0 .5rem 1rem -.75rem rgba(27, 22, 66, .1) !important;
    transform: translate3d(0, -3px, 0)
}

.lift-lg:focus,
.lift-lg:hover {
    box-shadow: 0 1rem 5rem rgba(27, 22, 66, .1), 0 2rem 3rem -1rem rgba(27, 22, 66, .05) !important;
    transform: translate3d(0, -5px, 0)
}

.top-left {
    top: 0 !important;
    left: 0 !important;
    transform: none !important
}

.top-right {
    top: 0 !important;
    right: 0 !important;
    transform: none !important
}

.top-center {
    top: 0 !important;
    left: 50% !important;
    transform: translateX(-50%) !important
}

.bottom-right {
    right: 0 !important;
    bottom: 0 !important;
    transform: none !important
}

.bottom-left {
    bottom: 0 !important;
    left: 0 !important;
    transform: none !important
}

.bottom-center {
    bottom: 0 !important;
    left: 50% !important;
    transform: translateX(-50%) !important
}

@media (min-width:576px) {
    .top-sm-left {
        top: 0 !important;
        left: 0 !important;
        transform: none !important
    }

    .top-sm-right {
        top: 0 !important;
        right: 0 !important;
        transform: none !important
    }

    .top-sm-center {
        top: 0 !important;
        left: 50% !important;
        transform: translateX(-50%) !important
    }

    .bottom-sm-left {
        bottom: 0 !important;
        left: 0 !important;
        transform: none !important
    }

    .bottom-sm-right {
        bottom: 0 !important;
        right: 0 !important;
        transform: none !important
    }

    .bottom-sm-center {
        bottom: 0 !important;
        left: 50% !important;
        transform: translateX(-50%) !important
    }
}

@media (min-width:768px) {
    .top-md-left {
        top: 0 !important;
        left: 0 !important;
        transform: none !important
    }

    .top-md-right {
        top: 0 !important;
        right: 0 !important;
        transform: none !important
    }

    .top-md-center {
        top: 0 !important;
        left: 50% !important;
        transform: translateX(-50%) !important
    }

    .bottom-md-left {
        bottom: 0 !important;
        left: 0 !important;
        transform: none !important
    }

    .bottom-md-right {
        bottom: 0 !important;
        right: 0 !important;
        transform: none !important
    }

    .bottom-md-center {
        bottom: 0 !important;
        left: 50% !important;
        transform: translateX(-50%) !important
    }
}

@media (min-width:992px) {
    .top-lg-left {
        top: 0 !important;
        left: 0 !important;
        transform: none !important
    }

    .top-lg-right {
        top: 0 !important;
        right: 0 !important;
        transform: none !important
    }

    .top-lg-center {
        top: 0 !important;
        left: 50% !important;
        transform: translateX(-50%) !important
    }

    .bottom-lg-left {
        bottom: 0 !important;
        left: 0 !important;
        transform: none !important
    }

    .bottom-lg-right {
        bottom: 0 !important;
        right: 0 !important;
        transform: none !important
    }

    .bottom-lg-center {
        bottom: 0 !important;
        left: 50% !important;
        transform: translateX(-50%) !important
    }
}

@media (min-width:1200px) {
    .top-xl-left {
        top: 0 !important;
        left: 0 !important;
        transform: none !important
    }

    .top-xl-right {
        top: 0 !important;
        right: 0 !important;
        transform: none !important
    }

    .top-xl-center {
        top: 0 !important;
        left: 50% !important;
        transform: translateX(-50%) !important
    }

    .bottom-xl-left {
        bottom: 0 !important;
        left: 0 !important;
        transform: none !important
    }

    .bottom-xl-right {
        bottom: 0 !important;
        right: 0 !important;
        transform: none !important
    }

    .bottom-xl-center {
        bottom: 0 !important;
        left: 50% !important;
        transform: translateX(-50%) !important
    }
}

.shadow-img {
    box-shadow: 2rem 2rem #eaf2fe !important
}

.mvw-100 {
    max-width: 100vw !important
}

.mw-25 {
    max-width: 25% !important
}

.vh-25 {
    height: 25vh !important
}

.vw-25 {
    width: 25vw !important
}

.mw-50 {
    max-width: 50% !important
}

.vh-50 {
    height: 50vh !important
}

.vw-50 {
    width: 50vw !important
}

.mw-75 {
    max-width: 75% !important
}

.vh-75 {
    height: 75vh !important
}

.vw-75 {
    width: 75vw !important
}

.mw-100 {
    max-width: 100% !important
}

.vh-100 {
    height: 100vh !important
}

.vw-100 {
    width: 100vw !important
}

.mw-auto {
    max-width: auto !important
}

.mw-125 {
    max-width: 125% !important
}

.vh-125 {
    height: 125vh !important
}

.vw-125 {
    width: 125vw !important
}

.mw-150 {
    max-width: 150% !important
}

.vh-150 {
    height: 150vh !important
}

.vw-150 {
    width: 150vw !important
}

@media (min-width:576px) {
    .h-sm-25 {
        height: 25% !important
    }

    .w-sm-25 {
        width: 25% !important
    }

    .mw-sm-25 {
        max-width: 25% !important
    }

    .vh-sm-25 {
        height: 25vh
    }

    .vw-sm-25 {
        width: 25vw
    }

    .h-sm-50 {
        height: 50% !important
    }

    .w-sm-50 {
        width: 50% !important
    }

    .mw-sm-50 {
        max-width: 50% !important
    }

    .vh-sm-50 {
        height: 50vh
    }

    .vw-sm-50 {
        width: 50vw
    }

    .h-sm-75 {
        height: 75% !important
    }

    .w-sm-75 {
        width: 75% !important
    }

    .mw-sm-75 {
        max-width: 75% !important
    }

    .vh-sm-75 {
        height: 75vh
    }

    .vw-sm-75 {
        width: 75vw
    }

    .h-sm-100 {
        height: 100% !important
    }

    .w-sm-100 {
        width: 100% !important
    }

    .mw-sm-100 {
        max-width: 100% !important
    }

    .vh-sm-100 {
        height: 100vh
    }

    .vw-sm-100 {
        width: 100vw
    }

    .h-sm-auto {
        height: auto !important
    }

    .w-sm-auto {
        width: auto !important
    }

    .mw-sm-auto {
        max-width: auto !important
    }

    .h-sm-125 {
        height: 125% !important
    }

    .w-sm-125 {
        width: 125% !important
    }

    .mw-sm-125 {
        max-width: 125% !important
    }

    .vh-sm-125 {
        height: 125vh
    }

    .vw-sm-125 {
        width: 125vw
    }

    .h-sm-150 {
        height: 150% !important
    }

    .w-sm-150 {
        width: 150% !important
    }

    .mw-sm-150 {
        max-width: 150% !important
    }

    .vh-sm-150 {
        height: 150vh
    }

    .vw-sm-150 {
        width: 150vw
    }
}

@media (min-width:768px) {
    .h-md-25 {
        height: 25% !important
    }

    .w-md-25 {
        width: 25% !important
    }

    .mw-md-25 {
        max-width: 25% !important
    }

    .vh-md-25 {
        height: 25vh
    }

    .vw-md-25 {
        width: 25vw
    }

    .h-md-50 {
        height: 50% !important
    }

    .w-md-50 {
        width: 50% !important
    }

    .mw-md-50 {
        max-width: 50% !important
    }

    .vh-md-50 {
        height: 50vh
    }

    .vw-md-50 {
        width: 50vw
    }

    .h-md-75 {
        height: 75% !important
    }

    .w-md-75 {
        width: 75% !important
    }

    .mw-md-75 {
        max-width: 75% !important
    }

    .vh-md-75 {
        height: 75vh
    }

    .vw-md-75 {
        width: 75vw
    }

    .h-md-100 {
        height: 100% !important
    }

    .w-md-100 {
        width: 100% !important
    }

    .mw-md-100 {
        max-width: 100% !important
    }

    .vh-md-100 {
        height: 100vh
    }

    .vw-md-100 {
        width: 100vw
    }

    .h-md-auto {
        height: auto !important
    }

    .w-md-auto {
        width: auto !important
    }

    .mw-md-auto {
        max-width: auto !important
    }

    .h-md-125 {
        height: 125% !important
    }

    .w-md-125 {
        width: 125% !important
    }

    .mw-md-125 {
        max-width: 125% !important
    }

    .vh-md-125 {
        height: 125vh
    }

    .vw-md-125 {
        width: 125vw
    }

    .h-md-150 {
        height: 150% !important
    }

    .w-md-150 {
        width: 150% !important
    }

    .mw-md-150 {
        max-width: 150% !important
    }

    .vh-md-150 {
        height: 150vh
    }

    .vw-md-150 {
        width: 150vw
    }
}

@media (min-width:992px) {
    .h-lg-25 {
        height: 25% !important
    }

    .w-lg-25 {
        width: 25% !important
    }

    .mw-lg-25 {
        max-width: 25% !important
    }

    .vh-lg-25 {
        height: 25vh
    }

    .vw-lg-25 {
        width: 25vw
    }

    .h-lg-50 {
        height: 50% !important
    }

    .w-lg-50 {
        width: 50% !important
    }

    .mw-lg-50 {
        max-width: 50% !important
    }

    .vh-lg-50 {
        height: 50vh
    }

    .vw-lg-50 {
        width: 50vw
    }

    .h-lg-75 {
        height: 75% !important
    }

    .w-lg-75 {
        width: 75% !important
    }

    .mw-lg-75 {
        max-width: 75% !important
    }

    .vh-lg-75 {
        height: 75vh
    }

    .vw-lg-75 {
        width: 75vw
    }

    .h-lg-100 {
        height: 100% !important
    }

    .w-lg-100 {
        width: 100% !important
    }

    .mw-lg-100 {
        max-width: 100% !important
    }

    .vh-lg-100 {
        height: 100vh
    }

    .vw-lg-100 {
        width: 100vw
    }

    .h-lg-auto {
        height: auto !important
    }

    .w-lg-auto {
        width: auto !important
    }

    .mw-lg-auto {
        max-width: auto !important
    }

    .h-lg-125 {
        height: 125% !important
    }

    .w-lg-125 {
        width: 125% !important
    }

    .mw-lg-125 {
        max-width: 125% !important
    }

    .vh-lg-125 {
        height: 125vh
    }

    .vw-lg-125 {
        width: 125vw
    }

    .h-lg-150 {
        height: 150% !important
    }

    .w-lg-150 {
        width: 150% !important
    }

    .mw-lg-150 {
        max-width: 150% !important
    }

    .vh-lg-150 {
        height: 150vh
    }

    .vw-lg-150 {
        width: 150vw
    }
}

@media (min-width:1200px) {
    .h-xl-25 {
        height: 25% !important
    }

    .w-xl-25 {
        width: 25% !important
    }

    .mw-xl-25 {
        max-width: 25% !important
    }

    .vh-xl-25 {
        height: 25vh
    }

    .vw-xl-25 {
        width: 25vw
    }

    .h-xl-50 {
        height: 50% !important
    }

    .w-xl-50 {
        width: 50% !important
    }

    .mw-xl-50 {
        max-width: 50% !important
    }

    .vh-xl-50 {
        height: 50vh
    }

    .vw-xl-50 {
        width: 50vw
    }

    .h-xl-75 {
        height: 75% !important
    }

    .w-xl-75 {
        width: 75% !important
    }

    .mw-xl-75 {
        max-width: 75% !important
    }

    .vh-xl-75 {
        height: 75vh
    }

    .vw-xl-75 {
        width: 75vw
    }

    .h-xl-100 {
        height: 100% !important
    }

    .w-xl-100 {
        width: 100% !important
    }

    .mw-xl-100 {
        max-width: 100% !important
    }

    .vh-xl-100 {
        height: 100vh
    }

    .vw-xl-100 {
        width: 100vw
    }

    .h-xl-auto {
        height: auto !important
    }

    .w-xl-auto {
        width: auto !important
    }

    .mw-xl-auto {
        max-width: auto !important
    }

    .h-xl-125 {
        height: 125% !important
    }

    .w-xl-125 {
        width: 125% !important
    }

    .mw-xl-125 {
        max-width: 125% !important
    }

    .vh-xl-125 {
        height: 125vh
    }

    .vw-xl-125 {
        width: 125vw
    }

    .h-xl-150 {
        height: 150% !important
    }

    .w-xl-150 {
        width: 150% !important
    }

    .mw-xl-150 {
        max-width: 150% !important
    }

    .vh-xl-150 {
        height: 150vh
    }

    .vw-xl-150 {
        width: 150vw
    }
}

.font-family-sans-serif {
    font-family: "DM Sans", sans-serif !important
}

.font-family-serif {
    font-family: "DM Serif Display", serif !important
}

.font-size-h2 {
    font-size: 1.75rem !important
}

.font-size-h3 {
    font-size: 1.25rem !important
}

.font-size-lg {
    font-size: 1.25rem !important
}

.font-size-sm {
    font-size: 1rem !important
}

.font-size-xs {
    font-size: .875rem !important
}

.font-size-xxs {
    font-size: .75rem !important
}

.text-white-20 {
    color: rgba(255, 255, 255, .2) !important
}

.text-white-60 {
    color: rgba(255, 255, 255, .6) !important
}

a.text-white-60:hover {
    color: rgba(255, 255, 255, .8) !important
}

.text-white-80 {
    color: rgba(255, 255, 255, .8) !important
}

.text-white-50 {
    color: rgba(255, 255, 255, .5) !important
}

.text-gray-300 {
    color: #dce0f3 !important
}

.text-gray-500 {
    color: #8d9aae !important
}

.text-gray-600 {
    color: #575a7b !important
}

.text-gray-800 {
    color: #342e6c !important
}

[class*=text-underline-] {
    background: repeat-x left 1em/1em .15em
}

.text-underline-primary {
    background-image: linear-gradient(to right, rgba(102, 88, 234, .2))
}

.text-underline-secondary {
    background-image: linear-gradient(to right, rgba(87, 90, 123, .2))
}

.text-underline-success {
    background-image: linear-gradient(to right, rgba(44, 212, 162, .2))
}

.text-underline-info {
    background-image: linear-gradient(to right, rgba(23, 162, 184, .2))
}

.text-underline-warning {
    background-image: linear-gradient(to right, rgba(245, 192, 112, .4))
}

.text-underline-danger {
    background-image: linear-gradient(to right, rgba(249, 101, 91, .2))
}

.text-underline-light {
    background-image: linear-gradient(to right, rgba(243, 248, 255, .2))
}

.text-underline-dark {
    background-image: linear-gradient(to right, rgba(27, 22, 66, .2))
}

.text-underline-primary-dark {
    background-image: linear-gradient(to right, rgba(86, 76, 175, .2))
}

.text-underline-primary-light {
    background-image: linear-gradient(to right, rgba(104, 125, 237, .2))
}

.text-stripe {
    color: #6772e5 !important
}

.text-digital-ocean {
    color: #2485fd !important
}

.text-airbnb {
    color: #ff385c !important
}

.text-coinbase {
    color: #0081c9 !important
}

.text-netflix {
    color: #b81d24 !important
}

.text-dribbble {
    color: #da5988 !important
}

.text-pinterest {
    color: #cb2027 !important
}

.text-instagram {
    color: #3f5d87 !important
}

.line-height-reset {
    line-height: 1
}

#text-aa-sans-serif,
#text-aa-serif {
    transition: all .2s ease-in-out
}

#text-aa-serif {
    color: #fff
}

#text-aa-sans-serif {
    color: rgba(255, 255, 255, .2)
}

.avatar {
    position: relative;
    display: inline-block;
    width: 4rem;
    height: 4rem;
    font-size: 1.33333rem
}



.avatar-img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.avatar-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #8d9aae;
    color: #fff
}

.avatar-offline::before,
.avatar-online::before {
    content: '';
    position: absolute;
    bottom: 5%;
    right: 5%;
    width: 20%;
    height: 20%;
    border-radius: 50%
}

.avatar-online::before {
    background-color: #2cd4a2
}

.avatar-offline::before {
    background-color: #8d9aae
}

.avatar-xs {
    width: 2.5rem;
    height: 2.5rem;
    font-size: .83333rem
}

.avatar-sm {
    width: 3rem;
    height: 3rem;
    font-size: 1rem
}

.avatar-lg {
    width: 5rem;
    height: 5rem;
    font-size: 1.66667rem
}

.avatar-xl {
    width: 6rem;
    height: 6rem;
    font-size: 2rem
}

.avatar-xxl {
    width: 6rem;
    height: 6rem;
    font-size: 2rem
}

@media (min-width:768px) {
    .avatar-xxl {
        width: 7rem;
        height: 7rem;
        font-size: 2.33333rem
    }
}

.avatar.avatar-4by3 {
    width: 5.33333rem
}

.avatar-xs.avatar-4by3 {
    width: 3.33333rem
}

.avatar-sm.avatar-4by3 {
    width: 4rem
}

.avatar-lg.avatar-4by3 {
    width: 6.66667rem
}

.avatar-xl.avatar-4by3 {
    width: 8rem
}

.avatar-xxl.avatar-4by3 {
    width: 9.33333rem
}

.avatar-group {
    display: inline-flex
}

.avatar-group .avatar+.avatar {
    margin-left: -1rem
}

.avatar-group .avatar-xs+.avatar-xs {
    margin-left: -.625rem
}

.avatar-group .avatar-sm+.avatar-sm {
    margin-left: -.75rem
}

.avatar-group .avatar-lg+.avatar-lg {
    margin-left: -1.25rem
}

.avatar-group .avatar-xl+.avatar-xl {
    margin-left: -1.5rem
}

.avatar-group .avatar-xxl+.avatar-xxl {
    margin-left: -1.75rem
}



.avatar-group .avatar:hover {
    -webkit-mask-image: none;
    mask-image: none;
    z-index: 1
}




.docs-body {
    padding-top: 109px
}

.docs-breadcrumb {
    padding-top: .25rem;
    padding-bottom: .25rem;
    background-color: #1b1642
}

@media (min-width:992px) {
    .docs-breadcrumb {
        display: none
    }
}

.docs-sidenav {
    overflow-y: auto
}

@media (min-width:992px) {
    .docs-sidenav {
        position: fixed;
        top: 109px;
        max-height: calc(100vh - 109px)
    }
}

.docs-sidenav-right {
    right: 0
}

.docs-card {
    border-radius: .5rem;
    box-shadow: none
}

.docs-card>:first-child {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem
}

.docs-card>:last-child {
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem
}

.docs-card>.card-body {
    border: 1px solid #eaf2fe
}

.docs-card>.card-footer {
    font-size: .875rem;
    background-color: #1b1642
}

.icon>svg {
    width: 3rem;
    height: 3rem
}

.icon>svg [fill]:not([fill=none]) {
    fill: currentColor !important
}

.icon-lg>svg {
    width: 4.5rem;
    height: 4.5rem
}

.icon-sm>svg {
    width: 1.5rem;
    height: 1.5rem
}

.icon-auto>svg {
    width: 1em;
    height: 1em
}

.shape {
    position: absolute;
    pointer-events: none;
    background-color: currentColor
}

.shape-img {
    position: inherit;
    overflow: hidden
}

.shape-img>svg {
    position: inherit;
    width: 100%;
    height: 100%;
    transform: scale(2)
}

.shape-fluid-x {
    width: 100%
}

.shape-fluid-x .shape-img {
    width: inherit
}

.shape-top {
    bottom: 100%
}

.shape-top .shape-img {
    bottom: inherit
}

.shape-top .shape-img>svg {
    bottom: 0;
    transform-origin: top center
}

.shape-bottom {
    top: 100%
}

.shape-bottom .shape-img {
    top: inherit
}

.shape-bottom .shape-img>svg {
    top: 0;
    transform-origin: bottom center
}

.timeline {
    padding-left: 0;
    list-style-type: none
}

.timeline-item {
    position: relative;
    display: block;
    text-align: center;
    counter-increment: timeline-counter
}

.timeline-item+.timeline-item {
    margin-top: 2rem
}

.timeline-item::before {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    margin: 0 auto 1rem;
    font-size: 2.25rem;
    font-family: "DM Serif Display", serif;
    border-radius: 50%;
    content: counter(timeline-counter)
}

.timeline-item::after {
    position: absolute;
    top: 2rem;
    left: 0;
    right: 0;
    display: none;
    border-top: 2px solid;
    content: ''
}

.timeline-item:first-child::after {
    left: 50%;
    width: 50%
}

.timeline-item:last-child::after {
    width: 50%
}

.timeline-primary .timeline-item::before {
    background-color: #bcb6f6;
    color: #fff
}

.timeline-primary .timeline-item.active::before {
    background-color: #6658ea
}

.timeline-primary .timeline-item::after {
    border-color: #bcb6f6
}

.timeline-primary .timeline-item.active::after {
    border-color: #6658ea
}

.timeline-secondary .timeline-item::before {
    background-color: #b5b6c5;
    color: #fff
}

.timeline-secondary .timeline-item.active::before {
    background-color: #575a7b
}

.timeline-secondary .timeline-item::after {
    border-color: #b5b6c5
}

.timeline-secondary .timeline-item.active::after {
    border-color: #575a7b
}

.timeline-success .timeline-item::before {
    background-color: #a2ecd6;
    color: #fff
}

.timeline-success .timeline-item.active::before {
    background-color: #2cd4a2
}

.timeline-success .timeline-item::after {
    border-color: #a2ecd6
}

.timeline-success .timeline-item.active::after {
    border-color: #2cd4a2
}

.timeline-info .timeline-item::before {
    background-color: #99d6e0;
    color: #fff
}

.timeline-info .timeline-item.active::before {
    background-color: #17a2b8
}

.timeline-info .timeline-item::after {
    border-color: #99d6e0
}

.timeline-info .timeline-item.active::after {
    border-color: #17a2b8
}

.timeline-warning .timeline-item::before {
    background-color: #fbe3c0;
    color: #fff
}

.timeline-warning .timeline-item.active::before {
    background-color: #f5c070
}

.timeline-warning .timeline-item::after {
    border-color: #fbe3c0
}

.timeline-warning .timeline-item.active::after {
    border-color: #f5c070
}

.timeline-danger .timeline-item::before {
    background-color: #fcbbb7;
    color: #fff
}

.timeline-danger .timeline-item.active::before {
    background-color: #f9655b
}

.timeline-danger .timeline-item::after {
    border-color: #fcbbb7
}

.timeline-danger .timeline-item.active::after {
    border-color: #f9655b
}

.timeline-light .timeline-item::before {
    background-color: #fafcff;
    color: #1b1642
}

.timeline-light .timeline-item.active::before {
    background-color: #f3f8ff
}

.timeline-light .timeline-item::after {
    border-color: #fafcff
}

.timeline-light .timeline-item.active::after {
    border-color: #f3f8ff
}

.timeline-dark .timeline-item::before {
    background-color: #9b98ac;
    color: #fff
}

.timeline-dark .timeline-item.active::before {
    background-color: #1b1642
}

.timeline-dark .timeline-item::after {
    border-color: #9b98ac
}

.timeline-dark .timeline-item.active::after {
    border-color: #1b1642
}

.timeline-primary-dark .timeline-item::before {
    background-color: #b5b0dc;
    color: #fff
}

.timeline-primary-dark .timeline-item.active::before {
    background-color: #564caf
}

.timeline-primary-dark .timeline-item::after {
    border-color: #b5b0dc
}

.timeline-primary-dark .timeline-item.active::after {
    border-color: #564caf
}

.timeline-primary-light .timeline-item::before {
    background-color: #bdc6f7;
    color: #fff
}

.timeline-primary-light .timeline-item.active::before {
    background-color: #687ded
}

.timeline-primary-light .timeline-item::after {
    border-color: #bdc6f7
}

.timeline-primary-light .timeline-item.active::after {
    border-color: #687ded
}

.timeline-expand-xs {
    display: flex
}

.timeline-expand-xs .timeline-item {
    flex: 1
}

.timeline-expand-xs .timeline-item::after {
    display: block
}

.timeline-expand-xs .timeline-item+.timeline-item {
    margin-top: 0
}

@media (min-width:576px) {
    .timeline-expand-sm {
        display: flex
    }

    .timeline-expand-sm .timeline-item {
        flex: 1
    }

    .timeline-expand-sm .timeline-item::after {
        display: block
    }

    .timeline-expand-sm .timeline-item+.timeline-item {
        margin-top: 0
    }
}

@media (min-width:768px) {
    .timeline-expand-md {
        display: flex
    }

    .timeline-expand-md .timeline-item {
        flex: 1
    }

    .timeline-expand-md .timeline-item::after {
        display: block
    }

    .timeline-expand-md .timeline-item+.timeline-item {
        margin-top: 0
    }
}

@media (min-width:992px) {
    .timeline-expand-lg {
        display: flex
    }

    .timeline-expand-lg .timeline-item {
        flex: 1
    }

    .timeline-expand-lg .timeline-item::after {
        display: block
    }

    .timeline-expand-lg .timeline-item+.timeline-item {
        margin-top: 0
    }
}

@media (min-width:1200px) {
    .timeline-expand-xl {
        display: flex
    }

    .timeline-expand-xl .timeline-item {
        flex: 1
    }

    .timeline-expand-xl .timeline-item::after {
        display: block
    }

    .timeline-expand-xl .timeline-item+.timeline-item {
        margin-top: 0
    }
}

[data-aos=wipe-left],
[data-aos=wipe-right] {
    transition: .3s ease all
}

[data-aos=wipe-left].aos-animate,
[data-aos=wipe-right].aos-animate {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%)
}

[data-aos=wipe-left] {
    -webkit-clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%)
}

[data-aos=wipe-right] {
    -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%)
}

body.compensate-for-scrollbar {
    margin-right: 0 !important
}

.fancybox-container .fancybox-bg {
    background-color: #1b1642
}

[data-flickity]:not(.flickity-enabled) {
    display: none
}

.flickity-button {
    z-index: 1;
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    color: #fff;
    background-color: #6658ea;
    border-color: #6658ea;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06)
}

.flickity-button:hover {
    color: #fff;
    background-color: #4736e6;
    border-color: #3c2be4
}

.flickity-button.focus,
.flickity-button:focus {
    color: #fff;
    background-color: #4736e6;
    border-color: #3c2be4;
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(125, 113, 237, .5)
}

.flickity-button.disabled,
.flickity-button:disabled {
    color: #fff;
    background-color: #6658ea;
    border-color: #6658ea
}

.flickity-button:not(:disabled):not(.disabled).active,
.flickity-button:not(:disabled):not(.disabled):active,
.show>.flickity-button.dropdown-toggle {
    color: #fff;
    background-color: #3c2be4;
    border-color: #321fe3
}

.flickity-button:not(:disabled):not(.disabled).active:focus,
.flickity-button:not(:disabled):not(.disabled):active:focus,
.show>.flickity-button.dropdown-toggle:focus {
    box-shadow: 0 0 6px rgba(27, 22, 66, .03), 0 14px 24px rgba(27, 22, 66, .06), 0 0 0 0 rgba(125, 113, 237, .5)
}

.flickity-button:active,
.flickity-button:focus,
.flickity-button:hover {
    opacity: 1;
    background-color: #5546e8
}

.flickity-button::before {
    font-family: Feather
}

.flickity-button.previous {
    left: 0
}

.flickity-button.previous::before {
    content: "\e910"
}

.flickity-button.next {
    right: 0
}

.flickity-button.next::before {
    content: "\e912"
}

.flickity-button-icon {
    display: none
}

@media (max-width:767.98px) {
    .flickity-buttons-overlap .flickity-button {
        top: auto;
        bottom: 0;
        transform: translate(-.5rem, 1.5rem)
    }

    .flickity-buttons-overlap .flickity-button.next {
        left: 50%;
        transform: translate(0, 1.5rem)
    }

    .flickity-buttons-overlap .flickity-button.next::after {
        content: "";
        position: absolute;
        top: 30%;
        left: 0;
        height: 40%;
        border-left: 1px solid rgba(255, 255, 255, .2)
    }

    .flickity-buttons-overlap .flickity-button.previous {
        left: 50%;
        transform: translate(-100%, 1.5rem)
    }
}

@media (min-width:768px) {
    .flickity-buttons-overlap .flickity-button.previous {
        transform: translate(-50%, -50%)
    }

    .flickity-buttons-overlap .flickity-button.next {
        transform: translate(50%, -50%)
    }
}

.flickity-buttons-bottom-left .flickity-button {
    top: auto;
    bottom: 0;
    transform: translate(-1.5rem, 1.5rem)
}

.flickity-buttons-bottom-left .flickity-button.next {
    left: 3.75rem
}

.flickity-buttons-bottom-left .flickity-button.previous {
    left: 0
}

@media (max-width:767.98px) {
    .flickity-buttons-bottom-left .flickity-button.next {
        left: 50%;
        transform: translate(0, 1.5rem)
    }

    .flickity-buttons-bottom-left .flickity-button.previous {
        left: 50%;
        transform: translate(-100%, 1.5rem)
    }
}

.flickity-buttons-bottom-right .flickity-button {
    top: auto;
    bottom: 0;
    transform: translate(1.5rem, 1.5rem)
}

.flickity-buttons-bottom-right .flickity-button.next {
    right: 0
}

.flickity-buttons-bottom-right .flickity-button.previous {
    left: auto;
    right: 3.75rem
}

@media (max-width:767.98px) {
    .flickity-buttons-bottom-right .flickity-button.next {
        left: 50%;
        transform: translate(0, 1.5rem)
    }

    .flickity-buttons-bottom-right .flickity-button.previous {
        left: 50%;
        transform: translate(-100%, 1.5rem)
    }
}

.flickity-buttons-bottom-center .flickity-button {
    top: auto;
    bottom: 0;
    transform: translate(1.5rem, 1.5rem)
}

.flickity-buttons-bottom-center .flickity-button.next {
    left: 50%;
    transform: translate(0, 1.5rem)
}

.flickity-buttons-bottom-center .flickity-button.previous {
    left: 50%;
    transform: translate(-100%, 1.5rem)
}

.flickity-buttons-adjacent .flickity-button.next::after {
    content: "";
    position: absolute;
    top: 30%;
    left: 0;
    height: 40%;
    border-left: 1px solid rgba(255, 255, 255, .2)
}

.flickity-buttons-rounded .flickity-button.next {
    border-bottom-right-radius: 1rem
}

.flickity-buttons-rounded .flickity-button.previous {
    border-top-left-radius: 1rem
}

.flickity-buttons-responsive .flickity-button {
    display: none
}

@media (min-width:768px) {
    .flickity-buttons-responsive .flickity-button {
        display: block
    }

    .flickity-buttons-responsive .flickity-page-dots {
        display: none
    }
}

.flickity-viewport-visible .flickity-viewport {
    overflow: visible
}

.flickity-items-fade .flickity-slider>* {
    transition: all .2s ease-in-out;
    transition-property: opacity, transform;
    transform-origin: center center
}

.flickity-items-fade .flickity-hidden {
    transition: all .2s ease-in-out;
    transition-property: opacity
}

.flickity-items-fade .flickity-slider>:not(.is-selected) {
    opacity: .1;
    transform: scale(.8)
}

.flickity-items-fade .flickity-slider>:not(.is-selected) .flickity-hidden {
    opacity: 0
}

.flickity-page-dots {
    position: relative
}

.flickity-page-dots .dot {
    width: .5rem;
    height: .5rem;
    margin: 0;
    background-color: #dce0f3;
    opacity: 1
}

.flickity-page-dots .dot+.dot {
    margin-left: .5rem
}

.flickity-page-dots .dot.is-selected {
    background-color: #6658ea
}

.flickity-soft-edges .flickity-viewport {
    border-radius: inherit
}

.flickity-soft-edges .flickity-viewport::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0) 16px), linear-gradient(to left, #fff, rgba(255, 255, 255, 0) 16px)
}

.flickity-soft-edges-primary .flickity-viewport::after {
    background-image: linear-gradient(to right, #6658ea, rgba(102, 88, 234, 0) 16px), linear-gradient(to left, #6658ea, rgba(102, 88, 234, 0) 16px)
}

.flickity-soft-edges-secondary .flickity-viewport::after {
    background-image: linear-gradient(to right, #575a7b, rgba(87, 90, 123, 0) 16px), linear-gradient(to left, #575a7b, rgba(87, 90, 123, 0) 16px)
}

.flickity-soft-edges-success .flickity-viewport::after {
    background-image: linear-gradient(to right, #2cd4a2, rgba(44, 212, 162, 0) 16px), linear-gradient(to left, #2cd4a2, rgba(44, 212, 162, 0) 16px)
}

.flickity-soft-edges-info .flickity-viewport::after {
    background-image: linear-gradient(to right, #17a2b8, rgba(23, 162, 184, 0) 16px), linear-gradient(to left, #17a2b8, rgba(23, 162, 184, 0) 16px)
}

.flickity-soft-edges-warning .flickity-viewport::after {
    background-image: linear-gradient(to right, #f5c070, rgba(245, 192, 112, 0) 16px), linear-gradient(to left, #f5c070, rgba(245, 192, 112, 0) 16px)
}

.flickity-soft-edges-danger .flickity-viewport::after {
    background-image: linear-gradient(to right, #f9655b, rgba(249, 101, 91, 0) 16px), linear-gradient(to left, #f9655b, rgba(249, 101, 91, 0) 16px)
}

.flickity-soft-edges-light .flickity-viewport::after {
    background-image: linear-gradient(to right, #f3f8ff, rgba(243, 248, 255, 0) 16px), linear-gradient(to left, #f3f8ff, rgba(243, 248, 255, 0) 16px)
}

.flickity-soft-edges-dark .flickity-viewport::after {
    background-image: linear-gradient(to right, #1b1642, rgba(27, 22, 66, 0) 16px), linear-gradient(to left, #1b1642, rgba(27, 22, 66, 0) 16px)
}

.flickity-soft-edges-primary-dark .flickity-viewport::after {
    background-image: linear-gradient(to right, #564caf, rgba(86, 76, 175, 0) 16px), linear-gradient(to left, #564caf, rgba(86, 76, 175, 0) 16px)
}

.flickity-soft-edges-primary-light .flickity-viewport::after {
    background-image: linear-gradient(to right, #687ded, rgba(104, 125, 237, 0) 16px), linear-gradient(to left, #687ded, rgba(104, 125, 237, 0) 16px)
}

.hljs {
    padding: 0;
    background-color: transparent
}