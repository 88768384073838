.display-1,
.display-2,
.display-3,
.display-4 {
    letter-spacing: -.02em
}

.display-1 {
    font-size: 2.8125rem !important
}

.display-2 {
    font-size: 2.8125rem !important
}

.display-3 {
    font-size: 2.34375rem !important
}

.display-4 {
    font-size: 2.10938rem !important
}

@media (min-width:768px) {
    .display-1 {
        font-size: 4.80469rem !important
    }

    .display-2 {
        font-size: 4.21875rem !important
    }

    .display-3 {
        font-size: 3.75rem !important
    }

    .display-4 {
        font-size: 2.8125rem !important
    }
}


.h1,
h1 {
    letter-spacing: -.02em
}

.h2,
.h3,
.h4,
h2,
h3,
h4 {
    letter-spacing: -.01em
}

#text-aa-serif {
    color: rgba(255, 255, 255, .2) !important
}

#text-aa-sans-serif {
    color: #fff !important
}